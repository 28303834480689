enum CouponStatus {
    ISSUED = 'ISSUED',
    USED = 'USED',
    EXPIRED = 'EXPIRED'
}

function getCouponStatusText(status: CouponStatus) {
    switch (status) {
        case CouponStatus.ISSUED:
            return '발행';
        case CouponStatus.USED:
            return '사용';
        case CouponStatus.EXPIRED:
            return '만료';
        default:
            return '';
    }
}

class Coupon {
    // 제휴사코드 / 쿠폰명 / 쿠폰코드 / 회원카드번호 / 등록일시 / 등록포인트 / 발급일 /
    id: number;
    company_code: string;
    name: string;
    code: string;
    status: CouponStatus;
    amount: number;
    membership_card_number: string;
    publisehd_at: string;
    used_at: string;
    created_at: string;
    updated_at: string;
    disabled_at: string;
    constructor(
        id: number,
        company_code: string,
        name: string,
        code: string,
        status: CouponStatus,
        amount: number,
        membership_card_number: string,
        publisehd_at: string,
        used_at: string,
        created_at: string,
        updated_at: string,
        disabled_at: string
    ) {
        this.id = id;
        this.company_code = company_code;
        this.name = name;
        this.code = code;
        this.status = status;
        this.amount = amount;
        this.membership_card_number = membership_card_number;
        this.publisehd_at = publisehd_at;
        this.used_at = used_at;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.disabled_at = disabled_at;

    }
    static fromJson(json: Object): Coupon {
        var couponData: any = {};
        for (const [key, value] of Object.entries(json)) {
            couponData[key] = value;
        }
        return new Coupon(
            couponData['id'],
            couponData['company_code'],
            couponData['name'],
            couponData['code'],
            couponData['status'],
            couponData['amount'],
            couponData['membership_card_number'],
            couponData['publisehd_at'],
            couponData['used_at'],
            couponData['created_at'],
            couponData['updated_at'],
            couponData['disabled_at']

        );
    }
}

export default Coupon;
export { getCouponStatusText, CouponStatus };