import GradeInfo from "./gradeInfo";

class Membership {
    id: string;
    cloudCode: string;
    calculateTy: string;
    nm: string;
    refreshPd: number;
    refreshDay: number;
    refreshHour: number;
    lastRefreshedAt: Date | null;
    countingPd: number;
    applyAt: Date | null;
    applyTy: string;
    regDt: Date | null;
    updatedAt: Date | null;
    delDt: Date | null;
    gradeInfoList:Array<GradeInfo> | null;

    constructor(
        id: string,
        cloudCode: string,
        calculateTy: string,
        nm: string,
        refreshPd: number,
        refreshDay: number,
        refreshHour: number,
        lastRefreshedAt: Date | null,
        countingPd: number,
        applyAt: Date | null,
        applyTy: string,
        regDt: Date | null,
        updatedAt: Date | null,
        delDt: Date | null,
        gradeInfoList:Array<GradeInfo> | null
    ) {
        this.id = id;
        this.cloudCode = cloudCode;
        this.calculateTy = calculateTy;
        this.nm = nm;
        this.refreshPd = refreshPd;
        this.refreshDay = refreshDay;
        this.refreshHour = refreshHour;
        this.lastRefreshedAt = lastRefreshedAt;
        this.countingPd = countingPd;
        this.applyAt = applyAt;
        this.applyTy = applyTy;
        this.regDt = regDt;
        this.updatedAt = updatedAt;
        this.delDt = delDt;
        this.gradeInfoList = gradeInfoList;
    }

    static fromJson(json: any): Membership {
        return new Membership(
            json.id,
            json.cloudCode,
            json.calculateTy,
            json.nm,
            json.refreshPd,
            json.refreshDay,
            json.refreshHour,
            json.lastRefreshedAt ? new Date(json.lastRefreshedAt) : null,
            json.countingPd,
            json.applyAt ? new Date(json.applyAt) : null,
            json.applyTy,
            json.regDt ? new Date(json.regDt) : null,
            json.updatedAt ? new Date(json.updatedAt) : null,
            json.delDt ? new Date(json.delDt) : null,
            json.gradeInfoList ? json.gradeInfoList.map((item:any)=>GradeInfo.fromJson(item)) : null
        );
    }
}

export default Membership;
