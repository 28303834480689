import React from "react";
import styled from "styled-components";
import { Color, PagingHeight, TextBold } from "../constants";

const Paging = (currentPage: number, lastPage: number
    , handlePage: React.MouseEventHandler<HTMLButtonElement>
) => {
    const prev: string = '<';
    const next: string = '>';
    let hasPrevNext = false;
    if (lastPage > 10) {
        //page 다음 버튼 존재
        hasPrevNext = true;
    }

    let pageList: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    for (var i = 0; i < pageList.length; i++) {
        pageList[i] += Math.floor((currentPage - 1) / 10) * 10;
    }

    return (
        <React.Fragment>
            <PagingTile>
                {hasPrevNext
                    ? <PageButton
                        key={prev}
                        value={pageList[0] - 10}
                        style={{
                            color: Math.floor(currentPage / 10) === 0 ? Color.textFirst : Color.primary,
                            fontWeight: Math.floor(currentPage / 10) === 0 ? TextBold.normal : TextBold.heavy
                        }}

                        disabled={Math.floor(currentPage / 10) === 0}
                        onClick={handlePage}

                    >
                        {prev}
                    </PageButton>
                    : null}
                {

                    pageList.map((value) => {
                        if (value <= lastPage) {
                            return <PageButton
                                key={value}
                                value={value}
                                style={{
                                    color: currentPage !== value ? Color.textFirst : Color.primary,
                                    fontWeight: currentPage !== value ? TextBold.normal : TextBold.heavy
                                }}
                                disabled={currentPage === value}

                                onClick={handlePage}
                            >
                                {value}
                            </PageButton>;


                        } else {

                            return null;
                        }
                    })

                }
                {hasPrevNext
                    ? <PageButton
                        key={next}
                        value={pageList[pageList.length - 1] + 1}
                        style={{
                            color: Math.floor(lastPage / 10) <= Math.floor(currentPage / 10) ? Color.textFirst : Color.primary,
                            fontWeight: Math.floor(lastPage / 10) <= Math.floor(currentPage / 10) ? TextBold.normal : TextBold.heavy
                        }}
                        disabled={Math.floor(lastPage / 10) <= Math.floor(currentPage / 10)}

                        onClick={handlePage}
                    >
                        {next}
                    </PageButton>
                    : null}

            </PagingTile>
        </React.Fragment>
    );
}
const PagingTile = styled.div`
    align-items:center;
    justify-content: center;
    display:flex;
    flex-direction:row;
    width:100%;
    height:${PagingHeight - 10}px;
    // background-color:${Color.white}
`;
const PageButton = styled.button`
    margin:0px 2px;
    background-color:transparent;
    border:none;
    cursor:pointer;
`;

export default Paging;