import React from "react";
import styled from "styled-components";
import { TextSize, TextBold, Color } from "../constants";
import CouponGroup, { CouponGroupStatus, getCouponGroupStatusText } from "../models/couponGroup";
import { RESULT } from "../models/response";
import { getCouponGroupKeys } from "../repository/couponRepo";

import { getCompanyCode, getLocaleDateString, getPriceString, getRole } from "../utils/utils";
import EvBadge from "./evBadge";
import EvButton from "./evButton";
import TileLayout from "./styled/tileLayout";

const CouponGroupTile = (
  couponGroup: CouponGroup,
  publishedCoupon: Function,
  checkList: Array<number>,
  onCheck: React.MouseEventHandler<HTMLDivElement>
) => {
  let color = "";
  switch (couponGroup.cg_status) {
    case CouponGroupStatus.DISABLED:
    case CouponGroupStatus.EXPIRED:
      color = Color.red;
      break;

    case CouponGroupStatus.PUBLISHED:
      color = Color.primary;
      break;
    case CouponGroupStatus.CREATED:
    default:
      color = Color.secondary;
      break;
  }

  async function getCouponDataExcel(id: number) {
    let queryData: any = {};
    queryData.coupon_group_id = id;
    if (getRole() !== "Admin") {
      queryData.company_code = getCompanyCode()!;
    }

    const result = await getCouponGroupKeys(queryData);
    if (result.result === RESULT.FAIL) {
      alert("쿠폰 키 다운로드에 실패하였습니다.");
    } else {
      const aTag = window.document.createElement("a");

      const url = window.URL.createObjectURL(new Blob([result.data]));
      aTag.href = url;

      aTag.download = `coupon_codes_${getLocaleDateString(new Date().toString())}.xlsx`;
      aTag.target = "blank";
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      window.URL.revokeObjectURL(url);
      alert("엑셀 다운로드 성공.");
    }
  }
  return (
    <React.Fragment key={couponGroup.id}>
      <TileLayout>
        {/* 상태+유/무료 / 제휴사 / 쿠폰명 // 포인트 / 사용기간 / 사용/발급수량 / 키확인버튼 / 발행버튼  */}
        <CouponGroupLayout>
          <div>
            <input type={"checkbox"} readOnly onClick={onCheck} checked={checkList.includes(couponGroup.id)} />
          </div>
          <div>
            <EvBadge text={getCouponGroupStatusText(couponGroup.cg_status)} textColor={color} borderColor={color} />
            <EvBadge
              text={couponGroup.free ? "무료" : "유료"}
              textColor={couponGroup.free ? Color.secondary : Color.red}
              borderColor={couponGroup.free ? Color.secondary : Color.red}
              marginTop={true}
            />
          </div>
          <div style={{ color: Color.primary, width: "50px", textAlign: "start" }}>{couponGroup.company_code}</div>
          <div>{couponGroup.name}</div>
        </CouponGroupLayout>
        <CouponGroupLayout style={{ flex: 2, justifyContent: "space-around" }}>
          <div>P {getPriceString(couponGroup.amount)}</div>

          <div>
            {couponGroup.current_count}/{couponGroup.max_count}
          </div>
          <div>
            <DateStyle>{getLocaleDateString(couponGroup.valid_from_dt)}</DateStyle>
            <DateStyle>~ {getLocaleDateString(couponGroup.valid_until_dt)}</DateStyle>
          </div>
        </CouponGroupLayout>
        <TailLayout>
          <EvButton
            text={"다운로드"}
            padding="7px 5px 5px 5px"
            width={"5em"}
            fontSize={TextSize.small}
            color={Color.white}
            fontColor={Color.black}
            isOutline={true}
            margin={"5px 5px 0px 0px"}
            disable={couponGroup.cg_status !== CouponGroupStatus.PUBLISHED}
            onPressed={() => getCouponDataExcel(couponGroup.id)}
          />
          <EvButton
            text={"발행하기"}
            padding="7px 5px 5px 5px"
            width={"5em"}
            fontSize={TextSize.small}
            color={Color.white}
            fontColor={Color.black}
            isOutline={true}
            margin={"5px 0px 0px 0px"}
            disable={couponGroup.cg_status !== CouponGroupStatus.CREATED}
            onPressed={() => publishedCoupon(couponGroup.id, couponGroup.code_equal_yn === "Y")}
          />
        </TailLayout>
        {/* <div onClick={() => getCouponDataExcel(couponGroup.id)}>test</div> */}
      </TileLayout>
    </React.Fragment>
  );
};
const CouponGroupLayout = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    // min-width:60px;
  }
`;

const DateStyle = styled.div`
  color: ${Color.textSecondary};
  font-size: ${TextSize.small};
`;
const TailLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: auto;
  justify-content: end;
  min-width: 200px;
  text-align: start;
  align-items: center;
`;
export default CouponGroupTile;
