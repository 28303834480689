import { useEffect, useState } from "react";
import styled from "styled-components";
import EvLoading from "../../components/evLoading";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import { ColumnTitle, InfoCardLayout, ListTitle, RowTitle, SearchContentsCalendar, SearchContentsColumn, SearchContentsRow, SearchContentsRowGap } from "./style";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvRadio from "../../components/evRadio";
import Tooltip from "../../components/tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EvButton from "../../components/evButton";
import EvFileInput from "../../components/evFileInput";
import { getCompanyCode, getLocaleDateString, toJsonObject, useAddAlert, useModal } from "../../utils/utils";
import { applyMemberShip, deleteMemberShip, getMembershipDetail, membershipCreateRepo, putMemberShip } from "../../repository/membershipRepo";
import { useRecoilState } from "recoil";
import { loadingModalState, memberShipCopyState } from "../../recoil/atoms";
import Membership from "../../models/memberShip";
import GradeInfo from "../../models/gradeInfo";
import { RESULT } from "../../models/response";
import EvBadge from "../../components/evBadge";

function MembershipDetailPage({ }) {
    const location = useLocation();
    var memberShipId = location.pathname.split('/')[3]
    var isCreate = memberShipId == "create"

    const classificationGrades = ['충전량', '충전 금액'];
    const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState);

    const [copyData, setCopyData] = useRecoilState(memberShipCopyState);
    const { showModal } = useModal();
    const { addAlert } = useAddAlert();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [image, setImage] = useState<any>([]);
    const [showPointInfo, setShowPointInfo] = useState(false);
    const [showPointInfo2, setShowPointInfo2] = useState(false);
    const [showPointInfo3, setShowPointInfo3] = useState(false);
    const [showPointInfo4, setShowPointInfo4] = useState(false);
    var onMouseUpToolTip = () => setShowPointInfo(true);
    var onMouseLeaveToolTip = () => setShowPointInfo(false);
    var onMouseUpToolTip2 = () => setShowPointInfo2(true);
    var onMouseLeaveToolTip2 = () => setShowPointInfo2(false);
    var onMouseUpToolTip3 = () => setShowPointInfo3(true);
    var onMouseLeaveToolTip3 = () => setShowPointInfo3(false);
    var onMouseUpToolTip4 = () => setShowPointInfo4(true);
    var onMouseLeaveToolTip4 = () => setShowPointInfo4(false);

    // 멤버십 디테일 담아둘 state
    const [detailData, setDetailData] = useState<any>();

    // 멤버십 등급 생성용 state
    const initForm = {
        cloudCode: getCompanyCode(),
        calculateTy: '',
        nm: '',
        refreshPd: 3,
        refreshDay: 1,
        refreshHour: 1,
        lastRefreshedAt: null,
        countingPd: 3,
        applyTy: 'SCHEDULE',
        applyAt: '',
        gradeInfo: [
            {
                calculateMinValue: 0,
                calculateMaxValue: 0,
                mileageValue: 0,
                nm: '',
                gradePriority: 0,
                imgNm: '',
            },
        ]
    }
    const [createData, setCreateData] = useState<any>(initForm);

    // 리스트 추가 기능 (+)
    const addListForm = (idx: number) => {
        setCreateData((prevState: { gradeInfo: any; }) => ({
            ...prevState,
            gradeInfo: [
                ...prevState.gradeInfo,
                {
                    calculateMinValue: 0,
                    calculateMaxValue: 0,
                    mileageValue: 0,
                    nm: '',
                    gradePriority: idx + 1,
                    imgNm: '',
                }
            ]
        }))
    }

    // 리스트 삭제 기능 (-)
    const removeListForm = (idx: number) => {
        setImage(
            (prevTodos: any[]) => prevTodos.filter((image: File, index: number) => image.name !== createData.gradeInfo[idx].imgNm)
        );
        setCreateData((prevState: { gradeInfo: any[]; }) => ({
            ...prevState,
            gradeInfo: prevState.gradeInfo.filter((_, index) => index !== idx)
        }));
    }

    // 산정 기준 set
    const onChangeCalculateType = (e: any) => {
        const { value } = e.target;
        if (value === '충전량') {
            setCreateData({
                ...createData,
                calculateTy: 'QUANTITY'
            });
        } else if (value === '충전 금액') {
            setCreateData({
                ...createData,
                calculateTy: 'PRICE'
            });
        } else {
            setCreateData({
                ...createData,
                calculateTy: value
            });
        }
    }

    const onFileClickSave = (file: File, idx: number) => {
        setImage([...image, file]);
        let file_name = file.name
        setCreateData((prevState: { gradeInfo: any[]; }) => ({
            ...prevState,
            gradeInfo: prevState.gradeInfo.map((item, index) => {
                if (index === idx) {
                    return {
                        ...item,
                        imgNm: file_name
                    };
                }
                return item;
            })
        }));
    }

    // 리스트 내부 gradeInfo 변화 감지 후 setCreateData
    const onChangeListInput = (e: React.ChangeEvent<HTMLInputElement>, idx: number, fieldName: string) => {
        const { value, files } = e.target;
        if (files) {
            let file_name = files[0].name
            let testfile = files[0];
            setImage([...image, testfile]);
            setCreateData((prevState: { gradeInfo: any[]; }) => ({
                ...prevState,
                gradeInfo: prevState.gradeInfo.map((item, index) => {
                    if (fieldName === 'imgNm' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: file_name
                        };
                    } else if (fieldName === 'calculateMinValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: parseInt(value)
                        };
                    } else if (fieldName === 'calculateMaxValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: parseInt(value)
                        };
                    } else if (fieldName === 'mileageValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: Number(value)
                        };
                    } else if (index === idx) {
                        return {
                            ...item,
                            [fieldName]: value
                        }
                    }
                    return item;
                })
            }));
        } else {
            setCreateData((prevState: { gradeInfo: any[]; }) => ({
                ...prevState,
                gradeInfo: prevState.gradeInfo.map((item, index) => {
                    if (fieldName === 'calculateMinValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: Number(value)
                        };
                    } else if (fieldName === 'calculateMaxValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: parseInt(value)
                        };
                    } else if (fieldName === 'mileageValue' && index === idx) {
                        return {
                            ...item,
                            [fieldName]: Number(value)
                        };
                    } else if (index === idx) {
                        return {
                            ...item,
                            [fieldName]: value
                        };
                    }
                    return item;
                })
            }));
        }
    };

    // 등급 갱신 주기 set
    const onChangeSelectHandler = (event: any) => {
        setCreateData({
            ...createData,
            refreshPd: Number(event.target.value),
            countingPd: Number(event.target.value)
        });
    }

    // 등급 명 set
    const handleSearchInput = (event: any) => {
        setCreateData({
            ...createData,
            nm: event.target.value
        });
    }

    // DatePicker 일자 선택 조건
    const filterDate = (date: any) => {
        const day = date.getDate();
        const now = Date.now();

        if (now > date) return false;
        if (day == 30) return false;
        if (day == 1) return true;
        return day % 5 == 0;
    }

    // DatePicker 시간 선택 조건
    const filterTime = (date: any) => {
        const day = date.getDate();
        const hour = date.getHours();
        var now = Date.now();
        if (now > date) return false;
        if ((date.getMinutes() > 0 || day % 5 !== 0 || day === 30) && (date.getMinutes() > 0 || day !== 1)) return false;
        if (day === 1) return hour >= 9;
        return true
    }

    // 시작 일시 선택시 set
    useEffect(() => {
        var h = selectedStartDate?.getHours();
        if (h != null && h < 9) {
            if (selectedStartDate?.getDate() == 1) {
                var newStartDate = new Date(selectedStartDate ?? ""); // 선택한 시작 날짜를 복제하여 새로운 객체 생성
                newStartDate.setHours(9);
                setSelectedStartDate(newStartDate)
            }
        }

        setCreateData({
            ...createData,
            applyAt: selectedStartDate,
            refreshDay: selectedStartDate?.getDate(),
            refreshHour: h && h < 9 ? 9 : h,
        });
    }, [selectedStartDate]);

    // 저장 시
    const handleSubmit = async () => {
        if (!(createData as Membership).applyAt) {
            addAlert('시작 일시를 입력해주세요.')

            return;
        }

        if (!(createData as Membership).refreshPd) {
            addAlert('등급 갱신 주기를 입력해주세요.')
            return;
        }

        if (!(createData as Membership).nm) {
            addAlert('등급 명을 입력해주세요.')
            return;
        }

        if (!(createData as Membership).calculateTy) {
            addAlert('등급 산정 기준을 선택해주세요.')
            return;
        }

        var isPass = true;
        createData.gradeInfo.forEach((item: any) => {
            delete item.gradeId;
            delete item.id;
            if (!(item as GradeInfo).nm) {
                addAlert('세부 등급명을 입력해주세요.')
                isPass = false;
                return;
            }
            if ((item as GradeInfo).calculateMinValue == null || (item as GradeInfo).calculateMinValue == undefined) {
                addAlert('최솟값을 입력해주세요.')
                isPass = false;
                return;
            }
            if ((item as GradeInfo).mileageValue == null || (item as GradeInfo).mileageValue == undefined) {
                addAlert('마일리지 적립 비율을 입력해주세요.')
                isPass = false;
                return;
            }
            if (!(item as GradeInfo).imgNm) {
                addAlert('이미지를 기입해주세요.')
                isPass = false;
                return;
            }
        })

        if (!isPass) {
            return;
        }

        if (createData.hasOwnProperty('gradeInfoList')) {
            delete createData.gradeInfoList;
        }
        if (createData.hasOwnProperty('id')) {
            delete createData.id;
        }
        if (createData.hasOwnProperty('regDt')) {
            delete createData.regDt;
        }
        if (createData.hasOwnProperty('updatedAt')) {
            delete createData.updatedAt;
        }
        if (createData.hasOwnProperty('delDt')) {
            delete createData.delDt;
        }

        createData.counting_pd = createData.refresh_pd
        showModal(
            {
                action: '저장',
                body: '멤버십 등급을 생성합니다.',
                onActionClick: async () => {
                    setLoadingModal({ isOpen: true });
                    var res = await membershipCreateRepo({ data: JSON.stringify(createData), image: image })
                    if (res.result == RESULT.OK) {
                        setCreateData(initForm)
                        navigate('/membershipManage');
                    } else {
                        addAlert(res.msg);
                    }
                    setLoadingModal({ isOpen: false });
                }
            }
        )

    }
    const handleDetailApply = async () => {
        showModal(
            {
                action: '적용',
                body: '멤버십 등급을 즉시 적용합니다.',
                onActionClick: async () => {
                    setLoadingModal({ isOpen: true });
                    if (detailData) {
                        var res = await applyMemberShip(detailData.id);
                        if (res.result == RESULT.OK) {
                            navigate('/membershipManage');
                        } else {
                            addAlert(res.msg);
                        }
                    }
                    setLoadingModal({ isOpen: false });
                }
            }
        )
    }
    const handleApply = async () => {
        if (!(createData as Membership).applyAt) {
            addAlert('시작 일시를 입력해주세요.')

            return;
        }

        if (!(createData as Membership).refreshPd) {
            addAlert('등급 갱신 주기를 입력해주세요.')
            return;
        }

        if (!(createData as Membership).nm) {
            addAlert('등급 명을 입력해주세요.')
            return;
        }

        if (!(createData as Membership).calculateTy) {
            addAlert('등급 산정 기준을 입력해주세요.')
            return;
        }

        var isPass = true;
        createData.gradeInfo.forEach((item: any) => {
            delete item.gradeId;
            delete item.id;
            if (!(item as GradeInfo).nm) {
                addAlert('세부 등급명을 입력해주세요.')
                isPass = false;
                return;
            }

            if ((item as GradeInfo).calculateMinValue == null || (item as GradeInfo).calculateMinValue == undefined) {
                addAlert('최솟값을 입력해주세요.')
                isPass = false;
                return;
            }

            if ((item as GradeInfo).mileageValue == null || (item as GradeInfo).mileageValue == undefined) {
                addAlert('마일리지 적립 비율을 입력해주세요.')
                isPass = false;
                return;
            }

        })

        if (!isPass) {
            return;
        }

        if (createData.hasOwnProperty('gradeInfoList')) {
            delete createData.gradeInfoList;
        }
        if (createData.hasOwnProperty('id')) {
            delete createData.id;
        }
        if (createData.hasOwnProperty('regDt')) {
            delete createData.regDt;
        }
        if (createData.hasOwnProperty('updatedAt')) {
            delete createData.updatedAt;
        }
        if (createData.hasOwnProperty('delDt')) {
            delete createData.delDt;
        }
        createData.counting_pd = createData.refresh_pd

        showModal(
            {
                action: '적용',
                body: '멤버십 등급을 즉시 적용합니다.',
                onActionClick: async () => {
                    setLoadingModal({ isOpen: true });
                    var res = await membershipCreateRepo({ data: JSON.stringify(createData), image: image })
                    if (res.result == RESULT.OK) {
                        var res1 = await applyMemberShip(res.data);
                        if (res1.result == RESULT.OK) {
                            navigate('/membershipManage');
                        } else {
                            addAlert(res1.msg);
                        }
                    }
                    setLoadingModal({ isOpen: false });
                }
            }
        )
    }

    // 취소 시
    const handleCancel = () => {
        showModal({
            body: "페이지를 벗어나면 작성하신\n모든 내용이 사라집니다.",
            action: '나가기',
            onActionClick: () => {
                setCreateData(initForm)
                setCopyData('');
                navigate('/membershipManage')
            }
        })
    }

    useEffect(() => {
        if (!isCreate) {
            setLoading(true);
            getMembershipDetailAsync(memberShipId);
            setLoading(false);
        }
    }, [])

    // 상세 데이터 응답함수
    const getMembershipDetailAsync = async (id: string) => {
        var res = await getMembershipDetail(id);
        setDetailData(res.data);
    }

    const copyHandler = () => {
        showModal(
            {
                body: '작성하신 내용을 복사해 신규 멤버십 등급을\n생성하시겠습니까?',
                action: '생성하기',
                onActionClick: () => {
                    var data = JSON.parse(JSON.stringify(detailData))
                    data.applyTy = 'SCHEDULE';
                    setCopyData(data);
                    navigate('/membershipManage/detail/create');
                }
            }
        )
    }
    useEffect(() => {
        const isEmpty = Object.keys(copyData).length === 0 && copyData.constructor === Object;
        const deepCopy = JSON.parse(JSON.stringify(copyData));

        if (!isEmpty) {
            // TODO
            setCreateData({
                ...copyData, gradeInfo: deepCopy.gradeInfoList.map((e: any) => {
                    e.imgNm = "";
                    return e;
                })
            })
        } else {
            setCreateData(initForm);
        }
    }, [copyData]);

    // 등급 산정 기준 케이스
    const getCalculateStatusText = (calculateTy: any) => {
        switch (calculateTy) {
            case 'QUANTITY':
                return '충전량';
            case 'PRICE':
                return '충전 금액';
            default:
                return '';
        }
    }

    async function checkImageResolution(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const image = new Image();
                image.src = event.target?.result as string;
                image.onload = function () {
                    const imgWidth = image.width;
                    const imgHeight = image.height;
                    if (Math.abs((imgWidth - imgHeight)) >= 10) {
                        resolve(false);
                    }
                    if (imgWidth >= 300) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
            };

            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }
    // 등급 상태 케이스
    const state = detailData?.applyTy == "DELETE" ? "삭제" : detailData?.applyTy == "APPLY" ? "진행" : "예정"
    const color = state == '삭제' ? Color.red : state == '진행' ? Color.secondary : Color.borderColor

    return (
        <ContentLayout>
            <DefaultContentsPadding>
                <StyledTitle>{isCreate ? '멤버십 등급 생성' : '멤버십 등급 정보 상세보기'}</StyledTitle>
                {loading ? (
                    <ElementContents>
                        <EvLoading />
                    </ElementContents>
                ) : (
                    <ElementContents>
                        <MemberShipDetailLayout>
                            <InfoCardLayout>
                                <SearchContentsRow>
                                    <RowTitle>
                                        등급 상태 :
                                    </RowTitle>
                                    {isCreate ? <EvBadge text={'예정'} textColor={Color.borderColor} borderColor={Color.borderColor} />
                                        : detailData?.delDt == null ? <EvBadge text={state} textColor={color} borderColor={color} /> : <>
                                            <EvBadge text={state} textColor={color} borderColor={color} />
                                            <span>{getLocaleDateString(detailData?.delDt)}</span>
                                        </>}
                                </SearchContentsRow>
                                <SearchContentsCalendar>
                                    <RowTitle>
                                        시작 일시<span style={{ color: Color.red }}>*</span>
                                        <QToolTipDiv onMouseEnter={onMouseUpToolTip} onMouseLeave={onMouseLeaveToolTip}>
                                            ?
                                            <Tooltip show={showPointInfo} width="330px" body={"* 매월 1일의 경우 오전 9시 이후부터만 시간 설정 가능합니다.\n(업데이트 시간으로 인해 오전 9시 미만으로는 설정 불가)\n\n* 2일부터는 00시부터 시간 설정 가능합니다."} top={'-90px'} ></Tooltip>
                                        </QToolTipDiv>
                                    </RowTitle>
                                    {isCreate ? EvCalendar(
                                        setSelectedStartDate,
                                        selectedStartDate,
                                        undefined,
                                        filterDate,
                                        filterTime
                                    ) : EvCalendar(
                                        () => { return false },
                                        detailData?.applyAt,
                                        !isCreate,
                                    )}
                                </SearchContentsCalendar>
                                <SearchContentsRow>
                                    <RowTitle>
                                        등급 갱신 주기<span style={{ color: Color.red }}>*</span>
                                        <QToolTipDiv onMouseEnter={onMouseUpToolTip2} onMouseLeave={onMouseLeaveToolTip2}>
                                            ?
                                            <Tooltip show={showPointInfo2} width="200px" body={"입력한 시작 예정일시를 기준으로 선택한 주기마다 등급 갱신"} top={'-55px'} ></Tooltip>
                                        </QToolTipDiv>
                                    </RowTitle>
                                    {
                                        isCreate ? <select defaultValue={3} onChange={onChangeSelectHandler} style={{ flex: 1 }} disabled={!isCreate}>
                                            <option value={1}>1개월</option>
                                            <option value={3}>3개월</option>
                                            <option value={6}>6개월</option>
                                            <option value={12}>1년</option>
                                        </select> : <select defaultValue={detailData?.refreshPd} style={{ flex: 1 }} disabled={!isCreate}>
                                            <option value={detailData?.refreshPd}>{detailData?.refreshPd}</option>
                                        </select>
                                    }

                                    <div style={{ flex: 2, display: 'flex' }}>
                                        &nbsp;개월 주기마다 반복
                                    </div>
                                </SearchContentsRow>
                            </InfoCardLayout>
                            <InfoCardLayout>
                                <SearchContentsColumn>
                                    <ColumnTitle>등급 명<span style={{ color: Color.red }}>*</span></ColumnTitle>
                                    <EvInput
                                        name={"search_text"}
                                        placeHolder={"등급명"}
                                        type={Type.TEXT}
                                        onChanged={isCreate ? handleSearchInput : null}
                                        value={isCreate ? createData.nm : detailData?.nm || ''}
                                        readonly={!isCreate}
                                    />
                                </SearchContentsColumn>
                                <SearchContentsColumn>
                                    <ColumnTitle>등급 산정 기준<span style={{ color: Color.red }}>*</span></ColumnTitle>
                                    <EvRadio
                                        onChanged={(e: any) => onChangeCalculateType(e)}
                                        classification={classificationGrades}
                                        fixString={
                                            isCreate ? undefined : getCalculateStatusText(detailData?.calculateTy)
                                        }
                                    />
                                </SearchContentsColumn>
                            </InfoCardLayout>
                            <InfoCardLayout>
                                <SearchContentsRowGap>
                                    <div style={{ width: '30px' }}></div>
                                    <ListTitle>세부 등급명<span style={{ color: Color.red }}>*</span></ListTitle>
                                    <ListTitle>
                                        최솟값(이상)<span style={{ color: Color.red }}>*</span>
                                        <QToolTipDiv onMouseEnter={onMouseUpToolTip3} onMouseLeave={onMouseLeaveToolTip3}>
                                            ?
                                            <Tooltip show={showPointInfo3} width="200px" body={"각 세부 등급이 책정되는 최솟값\n0 이상의 숫자만 입력 가능"} top={'-58px'} ></Tooltip>
                                        </QToolTipDiv>
                                    </ListTitle>
                                    <span>~</span>
                                    <ListTitle>
                                        최댓값(미만)
                                        <QToolTipDiv onMouseEnter={onMouseUpToolTip4} onMouseLeave={onMouseLeaveToolTip4}>
                                            ?
                                            <Tooltip show={showPointInfo4} width="200px" body={"각 세부 등급이 책정되는 최댓값\n0으로 입력 시 무제한으로 반영"} top={'-58px'} ></Tooltip>
                                        </QToolTipDiv>
                                    </ListTitle>
                                    <ListTitle>마일리지 적립 비율(%)<span style={{ color: Color.red }}>*</span></ListTitle>
                                    {isCreate ? <div style={{ width: '30px' }}></div> : null}
                                </SearchContentsRowGap>
                                {
                                    isCreate ? createData.gradeInfo?.map((data: any, idx: number) => {
                                        return (
                                            <SearchContentsRowGap key={idx}>
                                                <EvFileInput value={data.imgNm} idx={idx}
                                                    onChanged={(e: any) => onChangeListInput(e, idx, "imgNm")}
                                                    onClickSave={(file: any) => onFileClickSave(file, idx)}
                                                    fileVaildator={(file: File) => checkImageResolution(file)}
                                                />
                                                <EvInput
                                                    flex={1}
                                                    name={"detail_grade_text"}
                                                    placeHolder={"세부 등급명"}
                                                    type={Type.TEXT}
                                                    onChanged={(e) => onChangeListInput(e, idx, "nm")}
                                                    value={data.nm}
                                                    readonly={!isCreate}
                                                />
                                                <EvInput
                                                    flex={1}
                                                    name={"minimum_text"}
                                                    placeHolder={""}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "calculateMinValue")}
                                                    value={Number(data.calculateMinValue)}
                                                    readonly={!isCreate}
                                                />
                                                <span>~</span>
                                                <EvInput
                                                    flex={1}
                                                    name={"maximum_text"}
                                                    placeHolder={""}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "calculateMaxValue")}
                                                    value={Number(data.calculateMaxValue)}
                                                    readonly={!isCreate || idx == 0}
                                                />
                                                <EvInput
                                                    flex={1}
                                                    name={"percentage_text"}
                                                    placeHolder={"0"}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "mileageValue")}
                                                    value={data.mileageValue}
                                                    readonly={!isCreate}
                                                />
                                                {
                                                    idx === createData.gradeInfo.length - 1 ? (
                                                        <button style={{ width: '30px', height: '30px' }}
                                                            onClick={() => addListForm(idx)}
                                                        >+</button>
                                                    ) : (
                                                        <button style={{ width: '30px', height: '30px' }}
                                                            onClick={() => removeListForm(idx)}
                                                        >-</button>
                                                    )
                                                }

                                            </SearchContentsRowGap>
                                        )
                                    }) : detailData?.gradeInfoList?.map((data: any, idx: number) => {
                                        return (
                                            <SearchContentsRowGap key={idx}>
                                                <div
                                                    onClick={() => {
                                                        window.open(`${process.env.REACT_APP_MEMBERSHIP_API_HOST}/api/v2/grade/img/TD/${data.imgNm}`, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600');

                                                    }}
                                                >
                                                    <EvFileInput value={data.imgNm} idx={idx}
                                                        onChanged={(e: any) => onChangeListInput(e, idx, "imgNm")}
                                                        onClickSave={(file: any) => onFileClickSave(file, idx)}
                                                        disable={true}
                                                    />
                                                </div>
                                                <EvInput
                                                    flex={1}
                                                    name={"detail_grade_text"}
                                                    placeHolder={"등급명"}
                                                    type={Type.TEXT}
                                                    onChanged={(e) => onChangeListInput(e, idx, "nm")}
                                                    value={data.nm}
                                                    readonly={!isCreate}
                                                />
                                                <EvInput
                                                    flex={1}
                                                    name={"minimum_text"}
                                                    placeHolder={"0"}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "calculateMinValue")}
                                                    value={Number(data.calculateMinValue)}
                                                    readonly={!isCreate}
                                                />
                                                <span>~</span>
                                                <EvInput
                                                    flex={1}
                                                    name={"maximum_text"}
                                                    placeHolder={""}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "calculateMaxValue")}
                                                    value={Number(data.calculateMaxValue)}
                                                    readonly={!isCreate}
                                                />
                                                <EvInput
                                                    flex={1}
                                                    name={"percentage_text"}
                                                    placeHolder={"0"}
                                                    type={Type.NUMBER}
                                                    onChanged={(e) => onChangeListInput(e, idx, "mileageValue")}
                                                    value={data.mileageValue}
                                                    readonly={!isCreate}
                                                />

                                            </SearchContentsRowGap>
                                        )
                                    })
                                }
                            </InfoCardLayout>
                        </MemberShipDetailLayout>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {isCreate ?
                                <>
                                    <EvButton
                                        text={"즉시적용"}
                                        fontSize={TextSize.normal}
                                        width="100px"
                                        color={Color.white}
                                        fontColor={Color.black}
                                        isOutline={true}
                                        onPressed={handleApply}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <EvButton
                                            text={"취소"}
                                            fontSize={TextSize.normal}
                                            width="100px"
                                            color={Color.white}
                                            fontColor={Color.black}
                                            onPressed={() => handleCancel()}
                                            isOutline={true} />
                                        <div style={{ marginLeft: '12px' }}></div>
                                        <EvButton
                                            text={"저장"}
                                            width="100px"
                                            fontSize={TextSize.normal}
                                            color={Color.primary}
                                            fontColor={Color.white}
                                            onPressed={() => handleSubmit()}
                                            isOutline={true} />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                                        {
                                            detailData?.applyTy !== "DELETE" &&
                                            <EvButton
                                                text={"삭제"}
                                                fontSize={TextSize.normal}
                                                width="100px"
                                                color={Color.white}
                                                fontColor={Color.black}
                                                isOutline={true}
                                                onPressed={() => {
                                                    showModal(
                                                        {
                                                            body: '멤버십 등급이 삭제됩니다.',
                                                            action: '삭제',
                                                            onActionClick: async () => {
                                                                setLoadingModal({ isOpen: true })
                                                                try {
                                                                    var res = await putMemberShip(
                                                                        memberShipId
                                                                    );
                                                                    if (res.result == RESULT.OK) {
                                                                        navigate('/membershipManage')
                                                                    } else {
                                                                        addAlert(res.msg)
                                                                    }
                                                                } catch {

                                                                }
                                                                setLoadingModal({ isOpen: false })
                                                            }
                                                        }
                                                    )
                                                }}
                                            />
                                        }
                                        {
                                            detailData?.applyTy === "SCHEDULE" &&
                                            <>
                                                <div style={{ marginLeft: '12px' }}></div>
                                                <EvButton
                                                    text={"즉시적용"}
                                                    fontSize={TextSize.normal}
                                                    width="100px"
                                                    color={Color.white}
                                                    fontColor={Color.black}
                                                    isOutline={true}
                                                    onPressed={handleDetailApply}
                                                />
                                            </>
                                        }
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <Link to='/membershipManage'>
                                            <EvButton
                                                text={"나가기"}
                                                fontSize={TextSize.normal}
                                                width="100px"
                                                color={Color.white}
                                                fontColor={Color.black}
                                                isOutline={true} />
                                        </Link>
                                        <div style={{ marginLeft: '12px' }}></div>
                                        <EvButton
                                            onPressed={copyHandler}
                                            text={"복사하기"}
                                            fontSize={TextSize.normal}
                                            color={Color.primary}
                                            width="100px"
                                            fontColor={Color.white}
                                            isOutline={true} />
                                    </div>
                                </>
                            }
                        </div>
                    </ElementContents>
                )}

            </DefaultContentsPadding>
        </ContentLayout>

    );
}

const ButtonHeight = '43.5px';

const MemberShipDetailLayout = styled.div`
    height: calc(100% - ${ButtonHeight}); 
    width: 100%;
    margin: 12px 0;
    padding: 12px;
    background-color: ${Color.white};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
    input {
        margin: 8px 0;
        padding: 8px 8px;
        width: 100%;
        box-sizing: border-box;
    }
`

const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
`;
const StyledTitle = styled.h2`
    font-size: ${TextSize.sLarge};
    font-weight: ${TextBold.bold};
    color: ${Color.textFirst};
    margin-bottom: 10px;
`

const QToolTipDiv = styled.div`
    position: relative;
    right: -4px;
    top: 4px;
    font-size: 8px;
    border: 1px solid;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export default MembershipDetailPage;
