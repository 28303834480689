enum RESULT {
    OK = 'OK',
    FAIL = 'FAIL'
}

class Response {

    result: RESULT;
    msg: string;
    data: any;
    // static fromJson: any;
    // static error: any;



    constructor(
        result: RESULT,
        msg: string,
        data: any,
    ) {
        this.result = result;
        this.msg = msg;
        this.data = data;

    }
    static error(msg: string, data: any = null) {
        return new Response(RESULT.FAIL, msg, data);
    }
    static fromJson(json: JSON, converter: Function | null): Response {
        var tempJson = Object.entries(json);
        var result: RESULT | null = null;
        var msg: string = '';
        var data: any = null;
        for (const [key, value] of tempJson) {
            switch (key) {
                case 'result':
                    result = value === RESULT.OK ? RESULT.OK : RESULT.FAIL;
                    break;
                case 'msg':
                    msg = value;
                    break;
                case 'data':
                    if (value == null) {
                        data = null;
                    } else {
                        data = converter == null ? value : converter(value);
                    }
                    break;
                default:

                    break;
            }

        }
        if (result === null) {
            result = RESULT.FAIL;
        }
        return new Response(result, msg, data);
    }
}





export default Response;
export { RESULT };