import styled from "styled-components";
import { AppBarHeight, Color, MobileBreakWidth } from "../../constants";




export const ContentLayout = styled.div`
    
    display:flex;
    flex-direction: column;
    background-color:${Color.backGroundGrey};
    width:calc(100vw - 200px);
    height:calc(100vh - ${AppBarHeight}px);
    @media all and (max-width:${MobileBreakWidth}px){
        width:100vw;
    }
`;




