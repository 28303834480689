enum MemberCardStatus {
    ACCEPT = 'ACCEPT',
    STOP = 'STOP',
    DELETE = 'DELETE'
}
function getMemberCardStatusText(status: MemberCardStatus) {
    switch (status) {
        case MemberCardStatus.ACCEPT:
            return '허용';
        case MemberCardStatus.STOP:
            return '정지';
        case MemberCardStatus.DELETE:
            return '삭제';
        default:
            return '';
    }
}
class MemberCard {
    status: MemberCardStatus;
    company_code: string;
    membership_card_id: number;
    membership_card_number: string;
    created_at: string;
    updated_at: string;
    total_point: number;
    constructor(
        status: MemberCardStatus,
        company_code: string,
        membership_card_id: number,
        membership_card_number: string,
        created_at: string,
        updated_at: string,
        total_point: number,
    ) {
        this.status = status;
        this.company_code = company_code;
        this.membership_card_id = membership_card_id;
        this.membership_card_number = membership_card_number;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.total_point = total_point;
    }

    static fromJson(json: Object): MemberCard {
        var memberCardData: any = {};
        for (const [key, value] of Object.entries(json)) {
            memberCardData[key] = value;
        }
        return new MemberCard(
            memberCardData['status'],
            memberCardData['company_code'],
            memberCardData['membership_card_id'],
            memberCardData['membership_card_number'],
            memberCardData['created_at'],
            memberCardData['updated_at'],
            memberCardData['total_point']
        );
    }
}


export default MemberCard;

export { MemberCardStatus, getMemberCardStatusText };