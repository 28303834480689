import { useEffect, useState } from "react";
import styled from "styled-components";
import EvLoading from "../../components/evLoading";
import EvTab from "../../components/evTab";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inMembershipManage, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import { ColumnTitle, InfoCardLayout, RowTitle, SearchContentsCalendar, SearchContentsColumn, SearchContentsRow } from "./style";
import EvCalendar from "../../components/evCalendar";
import EvSelectBox from "../../components/evSelectBox";
import EvInput, { Type } from "../../components/evInput";

function MembershipCreatePage() {
    const element = EvTab(inMembershipManage);
    const [loading, setLoading] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [cycle, setCycle] = useState({
        '1개월': '1개월',
        '3개월': '3개월',
        '6개월': '6개월',
        '1년': '1년',
    })
    const [searchData, setSearchData] = useState<any>({
        start_time: '',
        cycle_time: '1개월',
        name: '',
        test1: '',
        test2: '',
        expire_dte: ''
    });
    const handleSelect = (event: any) => {
        if (event.target.value !== null) {
            setSearchData({
                ...searchData,
                cycle_time: event.target.value
            });
        } else {
            setSearchData({
                ...searchData,
                cycle_time: ''
            });
        }
    }
    const handleSearchInput = (event: any) => {
        setSearchData({
            ...searchData,
            name: event.target.value
        });
    }

    return (
        <ContentLayout>
            <DefaultContentsPadding>
                <StyledTitle>멤버십 등급 정보 상세보기</StyledTitle>
                {loading ? (
                    <ElementContents>
                        <EvLoading />
                    </ElementContents>
                ) : (
                    <ElementContents>
                        <InfoCardLayout>
                            <SearchContentsRow>
                                <RowTitle>
                                    등급 상태 :
                                </RowTitle>
                                예정
                            </SearchContentsRow>
                            <SearchContentsCalendar>
                                <RowTitle>시작 일시 :</RowTitle>
                                {EvCalendar(
                                    setSelectedStartDate,
                                    selectedStartDate,
                                    false,
                                    (date: any) => {
                                        const day = date.getDate();
                                        if (day == 30) {
                                            return false;
                                        }

                                        if (day == 1) {
                                            return true;
                                        }
                                        return day % 5 == 0;
                                    },
                                    (date: any) => {
                                        const day = date.getDate();
                                        const hour = date.getHours();

                                        if (day === 1) {
                                            return hour >= 9;
                                        }
                                        return true
                                    }

                                )}
                            </SearchContentsCalendar>
                            <SearchContentsRow>
                                <RowTitle>
                                    등급 갱신 주기
                                </RowTitle>
                                {EvSelectBox(cycle, handleSelect, 1, searchData.cycle_time)}
                                <div style={{ flex: 2 }}>주기마다 반복</div>
                            </SearchContentsRow>
                        </InfoCardLayout>
                        <InfoCardLayout>
                            <SearchContentsColumn>
                                <ColumnTitle>등급 명</ColumnTitle>
                                <EvInput
                                    name={"search_text"}
                                    placeHolder={"등급명"}
                                    type={Type.TEXT}
                                    onChanged={handleSearchInput}
                                    value={searchData.membership_card_number}
                                />
                            </SearchContentsColumn>
                            <SearchContentsColumn>
                                <ColumnTitle>등급 명</ColumnTitle>
                                {/* <EvRadio /> */}
                            </SearchContentsColumn>
                        </InfoCardLayout>
                    </ElementContents>
                )}
            </DefaultContentsPadding>
        </ContentLayout>
    );
}

const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
`;
const StyledTitle = styled.h2`
    font-size: ${TextSize.sLarge};
    font-weight: ${TextBold.bold};
    color: ${Color.textFirst};
    margin-bottom: 10px;
`
export default MembershipCreatePage;
