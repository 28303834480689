

import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import EvButton from "./evButton";
import { useRecoilState } from "recoil";
import { alertState } from "../recoil/atoms";
import { Close } from "../utils/svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useState } from "react";


function EvAlert({ }) {
  const [alert, setAlert] = useRecoilState(alertState);

  return (
    <AlertBackgroundLayout >
      <TransitionGroup component="ul">
        {alert.alerts.map((message, idx) => (
          message.split(':::')[0] != "" ?
            <CSSTransition key={message} timeout={10000} classNames="item">
              <AlertLayout>
                {message.split(':::')[0]}
                <CloseDiv
                  onClick={() => {
                    setAlert(prev => ({
                      ...prev,
                      alerts: prev.alerts.filter((_, index) => index !== idx)
                    }));
                  }}
                >
                  <Close></Close>
                </CloseDiv>
              </AlertLayout>
            </CSSTransition>
            : null
        ))}
      </TransitionGroup>
    </AlertBackgroundLayout>
  );
}

const ButtonHeight = '43.5px';

const AlertBackgroundLayout = styled.div`
  position: absolute;
  z-index: 9999;
  max-height: calc(100% - ${ButtonHeight}); 
  right: 0;
  top : 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  pointer-events: none; 
  
`;

const AlertLayout = styled.li`

  display:flex;
  justify-content:space-between;
  align-items:center;
  background-color: white;
  padding: 10px 10px 10px 10px;
  border-radius: 12px;
  min-width: 450px;
  border : 1px solid ${Color.borderColor};
  margin-right: 40px;
  margin-bottom: 12px;
  /* background-color: rgb(255, 224, 201); */
  background-color: ${Color.selectedMenu};
  font-size: 14px;
  pointer-events: auto; 
`

const CloseDiv = styled.div`
  :hover{
    cursor: pointer;
  }
`

export default EvAlert;

