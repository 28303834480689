import styled from "styled-components";
import { Color, SearchContentsHeight } from "../../constants";

export const InfoCardLayout = styled.div`
    padding:10px 10px;
    background-color:${Color.white};
    border-radius:5px;
    margin-bottom: 10px;
`;
export const SearchContentsCalendar = styled.div`
    margin-right:10px;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:center;
   
    @media all and (max-width:840px){
        height:${SearchContentsHeight * 2}px;
    }
    @media all and (max-width:650px){
        height:${SearchContentsHeight}px;
    }
    @media all and (max-width:620px){
        height:${SearchContentsHeight * 2}px;
    }
    
`;
export const SearchContentsRow = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:center;
    input {
        height: calc(100% - 10px);
        min-width:70px;
    }
    select {
        height: calc(100% - 5px);
    }
    button {
        height: calc(100% - 5px);
    }
`;
export const SearchContentsRowGap = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:center;
    gap: 10px;
    input {
        height: calc(100% - 10px);
        min-width:70px;
    }
    select {
        height: calc(100% - 5px);
    }
    button {
        height: calc(100% - 5px);
    }
`;
export const SearchContentsColumn = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:start;
    input {
        box-sizing: border-box;
        height: 30px;
    }
    select {
        box-sizing: border-box;
        height: 30px;
    }
    button {
        box-sizing: border-box;
        height: 30px;
    }
`;

export const RowContents = styled.div`
    padding: 5px 0;
    display: flex;
    flex-flow:row nowrap;
    align-items: center;
`

export const ColumnContents = styled.div`
    padding: 5px 0;
    display: flex;
    flex-flow:column nowrap;
    align-items: start;
`

export const RowTitle = styled.h2`
    width:150px;
    position: relative;
    display: flex;
`

export const ColumnTitle = styled.h2`
    width:100%;
    margin-bottom:5px;
    `
export const ListTitle = styled.h2`
display: flex;
justify-content: center;
    text-align: center;
    flex:1;
`