import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Color, TextBold } from "../constants";

type TabTileData = {
    tabText: string,
    pathText: string,
};

const EvTabTile = ({ tabText, pathText }: TabTileData) => {
    const location = useLocation();
    const isSelect: boolean = pathText === location.pathname;
    const navigate = useNavigate();
    const handleTap = (event: any) => {
        event.preventDefault();
        if (!isSelect) {
            navigate(pathText);
        }
    }
    return (
        <React.Fragment>
            <Tab onClick={handleTap}
                style={{
                    fontWeight: isSelect ? TextBold.bold : TextBold.normal,
                    color: isSelect ? Color.primary : Color.textFirst,
                    border: isSelect ? `1px solid ${Color.primary}` : `0.5px solid ${Color.borderColor}`,
                    cursor: isSelect ? 'cursor' : 'pointer',

                }}
            >
                {tabText}
            </Tab>
        </React.Fragment>
    );
}

const Tab = styled.div`
margin: 5px 0px 0px 0px;
padding: 5px 10px;
min-width:125px;
text-align: center;
background-color:${Color.white};
`;

export default EvTabTile;