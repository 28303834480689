import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CouponGroupTile from "../../components/couponGroupTile";
import EvButton from "../../components/evButton";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvLabel from "../../components/evLabel";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import EvDialog from "../../components/styled/dialogLayout";
import ElementContents from "../../components/styled/elementContents";
import { Color, inCouponsTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import CouponGroup, { CouponGroupStatus } from "../../models/couponGroup";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { addCouponGroup, getCouponGroups, getExcelCouponGroups, publishedCouponGroup } from "../../repository/couponGroupRepo";
import { getCompanyCode, getLocaleDateString, getLocaleDateStringWithTZ, getRole } from "../../utils/utils";

function CouponsPage() {
    const tabElement = EvTab(inCouponsTabDatas);
    const navigate = useNavigate();
    let isUseEffectHandle: boolean = false;

    const feeData = { '유/무료 전체': '', '유료': 'false', '무료': 'true' };
    let statusData = { '상태 전체': '', '생성': CouponGroupStatus.CREATED, '발행': CouponGroupStatus.PUBLISHED, '중지': CouponGroupStatus.DISABLED, '만료': CouponGroupStatus.EXPIRED, };
    const pointData = { '포인트 전체': '0', '1000p': '1000', '2000p': '2000', '3000p': '3000', '5000p': '5000', '10000p': '10000', '20000p': '20000', '30000p': '30000', '50000p': '50000', '100000p': '100000' };
    const addPointData = { '1000p': '1000', '2000p': '2000', '3000p': '3000', '5000p': '5000', '10000p': '10000', '20000p': '20000', '30000p': '30000', '50000p': '50000', '100000p': '100000' };
    // add coupon group
    type AddCouponGroupForm = {
        company_code: string,
        name: string,
        description?: string,
        type: string,
        amount: string,
        max_count: string,
        free: boolean,
        price: string,
        valid_period_day?: string,
        valid_from_dt: string,
        valid_until_dt: string,
        code_equal_yn: string //Y / N
    };
    const [isAddDialog, setAddDialog] = useState<boolean>(false);
    const [addCouponGroupForm, setAddCouponGroupForm] = useState<AddCouponGroupForm>({
        company_code: '',
        name: '',
        description: '',
        type: '100',
        amount: '',
        max_count: '0',
        free: true,
        price: '0',
        valid_period_day: '',
        valid_from_dt: '',
        valid_until_dt: '',
        code_equal_yn: 'N'
    });
    const handleAddCouponGroup = () => {
        //init
        setAddCouponGroupForm({
            company_code: getRole() !== 'Admin' ? getCompanyCode()! : '',
            name: '',
            description: '',
            type: '100',
            amount: '',
            max_count: '0',
            free: true,
            price: '0',
            valid_period_day: '',
            valid_from_dt: getLocaleDateStringWithTZ(new Date().toString(), true),
            valid_until_dt: '',
            code_equal_yn: 'N'
        });
        setSelectedDate(new Date());

        setAddDialog(!isAddDialog);
    }
    const onChangeAddCouponGroupForm = (event: { target: { name: string; value: string; } }) => {

        setAddCouponGroupForm({
            ...addCouponGroupForm,
            [event.target.name]: event.target.value
        });
    }
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    function checkAddCouponGroup(): boolean {
        if (
            addCouponGroupForm.company_code === '' ||
            addCouponGroupForm.name === '' ||
            addCouponGroupForm.amount === '0' ||
            addCouponGroupForm.max_count === '0' ||
            (addCouponGroupForm.free === false && addCouponGroupForm.price === '0') ||
            addCouponGroupForm.valid_from_dt === '' ||
            addCouponGroupForm.valid_until_dt === ''

        ) {
            return true;
        }
        return false;
    }
    function getCouponGroupSendData() {
        let returnData: any = addCouponGroupForm;
        if (returnData.valid_period_day === '') {
            delete returnData.valid_period_day;
        } else {
            returnData.valid_period_day = Number.parseInt(returnData.valid_period_day);
        }
        if (returnData.description === '') {
            delete returnData.description;
        }
        returnData.type = Number.parseInt(returnData.type);
        returnData.amount = Number.parseInt(returnData.amount);
        returnData.max_count = Number.parseInt(returnData.max_count);
        returnData.price = Number.parseInt(returnData.price);
        return returnData;
    }
    async function addCouponGroupSubmit() {
        const result = await addCouponGroup(getCouponGroupSendData());
        if (result.result === RESULT.FAIL) {
            alert(result.msg);
        } else {
            alert('등록 되었습니다.');
            handleAddCouponGroup();
            await handleCouponGroups();

        }
    }

    // checkBox data
    const [checkList, setCheckList] = useState<Array<number>>([]);
    const [allCheck, setAllCheck] = useState<boolean>(false);
    function handleAllCheck() {
        if (allCheck) {

            setAllCheck(false);
        } else {
            setCheckList([]);
            setAllCheck(true);
        }
    }
    //useEffect data
    type CouponGroupQuery = {
        // published_s:string,
        // published_e:string,
        company_code?: string,
        free?: string,
        point?: string,
        status?: string,
        page: number
    }
    const [queryData, setQueryData] = useState<CouponGroupQuery>({
        company_code: '',
        free: '',
        status: '',
        point: '0',
        page: 1
    });

    function getSendQuery() {
        let sendData = queryData;
        if (getRole() !== 'Admin') {
            sendData.company_code = getCompanyCode()!;
        } else {

            if (sendData.company_code === '') {
                delete sendData.company_code;
            }
        }
        if (sendData.free === '') {
            delete sendData.free;
        }
        if (sendData.status === '') {
            delete sendData.status;
        }
        if (sendData.point === '0') {
            delete sendData.point;
        }
        return sendData;
    }

    //search contents state
    type SearchContents = {
        // published_s:string,
        // published_e:string,
        company_code: string,
        free: string,
        point: string,
        status: string
    };
    const [totalPage, setTotalPage] = useState<number | null>(null);
    const [searchData, setSearchData] = useState<SearchContents>({
        company_code: '',
        free: '',
        point: '0',
        status: ''
    });
    //select company data
    const [companies, setCompanies] = useState<Object>({});
    async function handleComapanies() {
        const result = await getCompanies();
        if (result.result === RESULT.FAIL) {
            alert('조회 실패');
            navigate(Pages[PageName.Main].key, { replace: true });
        } else {
            const companiesResult: Array<Company> = result.data;
            let companySelect: any = {
                "제휴사 전체": ''
            };
            for (var i = 0; i < companiesResult.length; i++) {
                companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
            }
            setCompanies(companySelect);
        }
    }
    //coupongroup list data
    const [couponGroups, setCouponGroups] = useState<Array<CouponGroup> | null>(null);
    async function handleCouponGroups() {
        //check init
        setAllCheck(false);
        setCheckList([]);

        setCouponGroups(null);
        const sendData = getSendQuery();
        const result = await getCouponGroups(sendData);
        if (result.result === RESULT.FAIL) {
            alert('쿠폰그룹 목록조회 실패');
            navigate(Pages[PageName.Main].key, { replace: true });
        } else {
            const resultPageData: ResponseList = result.data;
            const resultData: Array<CouponGroup> = resultPageData.items;
            setCouponGroups(resultData);
            setTotalPage(resultPageData.last_page);
        }
    }
    useEffect(() => {
        // company_code?: string,
        // free?: boolean | null,
        // status?: CouponGroupStatus | null,
        // point?: number,
        // page: number
        //query
        const params = new URLSearchParams(window.location.search);

        if (window.location.search === '') {
            isUseEffectHandle = true;
            return navigate(getQueryString({ page: 1 })!, { replace: true });


        } else if (
            (params.get('page') !== queryData.page.toString()) ||
            (params.get('company_code') !== queryData.company_code) ||
            (params.get('free') !== queryData.free) ||
            (params.get('status') !== queryData.status) ||
            (params.get('point') !== queryData.point)
        ) {
            isUseEffectHandle = true;
            setSearchData({
                company_code: params.get('company_code') ?? '',
                free: params.get('free') ?? '',
                status: params.get('status') ?? '',
                point: params.get('point') ?? '0'
            });

            return setQueryData({
                ...queryData,
                company_code: params.get('company_code') ?? '',
                free: params.get('free') ?? '',
                status: params.get('status') as CouponGroupStatus ?? '',
                point: params.get('point') ?? '0',
                page: Number.parseInt(params.get('page') ?? '1')
            });
        }
        // eslint-disable-next-line
        isUseEffectHandle = false;

    }, [window.location.search]);
    const handleSelect = (e: { target: { name: string, value: string } }) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value
        })

    }
    async function callHandle() {
        if (getRole() === 'Admin') {
            await handleComapanies();
        }
        await handleCouponGroups();
    }
    useEffect(() => {
        if (isUseEffectHandle) return;
        callHandle();

        // eslint-disable-next-line
    }, [queryData, window.location.search]);


    const handlePage = async (e: any) => {


        const sendData = getSendQuery();
        sendData.page = Number.parseInt(e.target.value);


        navigate(getQueryString(sendData)!);
    }
    // publish 진행시 쿠폰 그룹의 code_equal_yn이 Y일 경우 코드 입력 받기 필요
    // dialog for code_equal_yn = Y
    const [isCodeDialog, setIsCodeDialog] = useState<boolean>(false);
    type PublishData = {
        id: number | null,
        code: string | undefined
    }
    const [publishData, setPublishData] = useState<PublishData>({ id: null, code: undefined });
    async function publishedCoupon(id: number, isCodeDialog: boolean, code?: string) {
        if (isCodeDialog) {
            setPublishData({ id: id, code: '' });
            setIsCodeDialog(true);
        } else {

            const result = await publishedCouponGroup(id, code);
            if (result.result === RESULT.FAIL) {
                alert(result.msg);
            } else {
                alert('발행 되었습니다.');
                await handleCouponGroups();

            }
        }
    }

    async function downloadExcel() {
        setLoading(true);
        let sendData: any = {};
        if (allCheck) {

            sendData = getExcelData();

        } else {
            sendData = { coupon_group_id: checkList.join(',') };
            if (getRole() !== 'Admin') {
                sendData.company_code = getCompanyCode()!;
            }
        }
        const result = await getExcelCouponGroups(sendData);
        if (result.result === RESULT.FAIL) {
            alert('쿠폰그룹 엑셀 다운로드에 실패하였습니다.');
        } else {
            const aTag = window.document.createElement('a');

            const url = window.URL.createObjectURL(new Blob([result.data]));
            aTag.href = url;


            aTag.download = `CouponGroup_${getLocaleDateString(new Date().toString())}.xlsx`;
            aTag.target = 'blank';
            document.body.appendChild(aTag);
            aTag.click();
            document.body.removeChild(aTag);
            window.URL.revokeObjectURL(url);
            alert('엑셀 다운로드 성공.');

        }
        setLoading(false);
    }
    function getExcelData() {
        let data: any = {};

        if (getRole() !== 'Admin') {
            data.company_code = getCompanyCode()!;
        } else {

            if (data.company_code === '') {
                delete data.company_code;
            }
        }
        if (queryData.free && queryData.free !== '') {
            data.free = queryData.free;
        }
        if (queryData.status && queryData.status !== '') {
            data.status = queryData.status;
        }
        if (queryData.point && queryData.point !== '0') {
            data.point = queryData.point;
        }
        return data;
    }
    const [isLoading, setLoading] = useState<boolean>(false); //excel을 위한 로딩
    return EvLayOut(
        <ContentLayout >
            <DefaultContentsPadding>

                {tabElement}
                {
                    couponGroups === null || isLoading
                        ? <ElementContents>
                            <EvLoading />
                        </ElementContents>
                        : <ElementContents>
                            {/* search contents */}
                            <SearchContents>
                                {
                                    getRole() === 'Admin'
                                        ? EvSelectBox(companies, handleSelect, 1, searchData.company_code, 'company_code', '5px')
                                        : null
                                }
                                {EvSelectBox(feeData, handleSelect, 1, searchData.free, 'free', '5px')}
                                {EvSelectBox(statusData, handleSelect, 1, searchData.status, 'status', '5px')}
                                {EvSelectBox(pointData, handleSelect, 1, searchData.point, 'point', '5px')}
                                <EvButton
                                    margin="5px 0px 0px 5px"
                                    padding="7px 5px 5px 5px"
                                    text={'검색'}
                                    width={'5rem'}
                                    fontSize={TextSize.small}
                                    color={Color.black}
                                    fontWeight={TextBold.bold}
                                    onPressed={() => {

                                        navigate(getQueryString({
                                            company_code: searchData.company_code === '' ? null : searchData.company_code,
                                            free: searchData.free === '' ? null : searchData.free,
                                            status: searchData.status,
                                            point: searchData.point === '0' ? null : searchData.point,
                                            page: 1
                                        })!);
                                    }}
                                // fontColor={Color.black}

                                />
                                <EvButton
                                    margin="5px 0px 0px 5px"
                                    padding="7px 5px 5px 5px"
                                    text={'엑셀 다운로드'}
                                    width={'6rem'}
                                    fontSize={TextSize.small}
                                    color={Color.green}
                                    fontWeight={TextBold.bold}
                                    onPressed={
                                        !allCheck && checkList.length === 0
                                            ? () => alert('다운로드할 쿠폰을 선택해주세요.')
                                            : downloadExcel}

                                // fontColor={Color.black}

                                />
                                <EvButton
                                    margin="5px 0px 0px 5px"
                                    padding="7px 5px 5px 5px"
                                    text={'그룹 추가'}
                                    width={'6rem'}
                                    fontSize={TextSize.small}
                                    color={Color.primary}
                                    fontWeight={TextBold.bold}
                                    onPressed={handleAddCouponGroup}
                                // fontColor={Color.black}

                                />

                            </SearchContents>
                            <CheckAll >
                                <input type={'checkbox'} readOnly onClick={handleAllCheck} checked={allCheck} />
                                <div>모두선택</div>

                            </CheckAll>
                            <ListContents
                                style={couponGroups.length === 0
                                    ? {
                                        backgroundColor: Color.white,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: Color.textSecondary
                                    }
                                    : {}}
                            >
                                {
                                    couponGroups.length === 0
                                        ? '검색된 쿠폰그룹이 존재하지 않습니다.'
                                        : couponGroups.map((value, index) => {
                                            return CouponGroupTile(
                                                value,
                                                publishedCoupon,
                                                checkList,
                                                (event: any) => {
                                                    event.stopPropagation();


                                                    if (checkList.includes(value.id)) {
                                                        setCheckList(
                                                            checkList.filter(a => a !== value.id)
                                                        );

                                                    } else {
                                                        setAllCheck(false);
                                                        setCheckList([
                                                            ...checkList,
                                                            value.id
                                                        ]);
                                                    }
                                                }

                                            );
                                        })
                                }
                            </ListContents>
                            {/* page contents need */}
                            {Paging(queryData!.page,
                                totalPage!,

                                (e: any) => {
                                    e.stopPropagation();
                                    handlePage(e);

                                })}
                        </ElementContents>
                }

            </DefaultContentsPadding>

            {/* coupongroup 추가 */}
            {
                isAddDialog &&
                <EvDialog
                    title="쿠폰그룹 등록"
                    contents={
                        <DetailDialogLayout>

                            {/* 제휴사 / 쿠폰명 / 설명 / 코드 / 포인트 / 유/무료 / 가격 / 발행수 / 유효기간 */}
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="제휴사"
                                    isNeccessary={true}
                                />
                                {
                                    getRole() === 'Admin'
                                        ? EvSelectBox(companies, (e: any) => setAddCouponGroupForm({ ...addCouponGroupForm, company_code: e.target.value }), 3, addCouponGroupForm.company_code)
                                        : <EvInput
                                            name={"company_code"}
                                            placeHolder={null}
                                            type={Type.TEXT}
                                            onChanged={null}
                                            readonly={true}
                                            value={addCouponGroupForm.company_code}
                                        />
                                }
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text={"쿠폰명"}
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={"name"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAddCouponGroupForm}
                                    value={addCouponGroupForm.name}
                                />
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text={"설명"}
                                    isNeccessary={false}
                                />
                                <EvInput
                                    name={"description"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAddCouponGroupForm}
                                    value={addCouponGroupForm.description}
                                />
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv >
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'start' }}>

                                    <input style={{ flex: 0, marginRight: '10px' }} type={'checkbox'} readOnly checked={addCouponGroupForm.code_equal_yn === 'N'} onClick={() => setAddCouponGroupForm({ ...addCouponGroupForm, code_equal_yn: 'N' })} />
                                    <div style={{ padding: "0px 0px 3px 0px" }}>

                                        자동생성
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'start' }}>

                                    <input style={{ flex: 0, marginRight: '10px' }} type={'checkbox'} readOnly checked={addCouponGroupForm.code_equal_yn === 'Y'} onClick={() => setAddCouponGroupForm({ ...addCouponGroupForm, code_equal_yn: 'Y' })} />
                                    <div style={{ padding: "0px 0px 3px 0px" }}>
                                        직접입력
                                    </div>
                                </div>
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv >
                                <div style={{ display: 'flex', marginRight: '10px', width: '50%' }}>
                                    <EvLabel
                                        text={"포인트"}
                                        isNeccessary={true}
                                    />
                                    <EvInput
                                        name={"description"}
                                        placeHolder={null}
                                        type={Type.NUMBER}
                                        onChanged={(e) => setAddCouponGroupForm({ ...addCouponGroupForm, amount: e.target.value })}
                                        value={addCouponGroupForm.amount}
                                    />

                                </div>
                                <div style={{ display: 'flex', width: '50%' }}>
                                    <EvLabel
                                        text={"유/무료"}
                                        isNeccessary={true}
                                    />
                                    {EvSelectBox({ '유료': 'false', '무료': 'true' }, (e: any) => setAddCouponGroupForm({ ...addCouponGroupForm, free: e.target.value === 'true' }), 3, addCouponGroupForm.free.toString())}
                                </div>
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', marginRight: '10px' }}>
                                    <EvLabel
                                        text={"가격"}
                                        isNeccessary={!addCouponGroupForm.free}
                                    />
                                    <EvInput
                                        name={"price"}
                                        placeHolder={null}
                                        type={Type.NUMBER}
                                        readonly={addCouponGroupForm.free}
                                        onChanged={onChangeAddCouponGroupForm}
                                        value={addCouponGroupForm.price}
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <EvLabel
                                        text="발행수"
                                        isNeccessary={true}
                                    />
                                    <EvInput
                                        name={"max_count"}
                                        placeHolder={null}
                                        type={Type.NUMBER}

                                        onChanged={onChangeAddCouponGroupForm}
                                        value={addCouponGroupForm.max_count}
                                    />
                                </div>
                            </DetailDialogContentsDiv>

                            <EvLabel

                                text="유효기간"
                                isNeccessary={true}
                            />

                            <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ width: '30%' }}>

                                    {EvCalendar((value: any) => {

                                        setAddCouponGroupForm({
                                            ...addCouponGroupForm,
                                            valid_from_dt: getLocaleDateStringWithTZ(value, true)
                                        });

                                        setSelectedDate(value);

                                    }, selectedDate)}
                                </div>
                                <div>를 시작으로</div>

                                <div style={{ display: 'flex', height: '100%', margin: "0px 10px" }}>
                                    <EvInput

                                        name={''}
                                        placeHolder={null}
                                        onChanged={(event: any) => {

                                            if (event.target.value) {
                                                const tempDate = new Date(selectedDate);
                                                const month = tempDate.getMonth();
                                                const untilDate = new Date(tempDate.setMonth(month + Number.parseInt(event.target.value)));

                                                setAddCouponGroupForm({
                                                    ...addCouponGroupForm,
                                                    valid_until_dt: getLocaleDateStringWithTZ(untilDate.toString(), false)
                                                });
                                            }
                                        }}
                                        type={Type.NUMBER}



                                    />
                                </div>


                                <div>개월 후 까지 </div>

                            </div>
                            <div>
                                <EvButton
                                    text={"등록"}
                                    padding="7px 5px 5px 5px"
                                    margin="15px 0px 0px 0px"
                                    color={Color.primary
                                    }
                                    disable={checkAddCouponGroup()}

                                    width={"100%"}
                                    onPressed={() => addCouponGroupSubmit()}
                                />
                            </div>





                        </DetailDialogLayout>


                    }
                    onClose={handleAddCouponGroup}
                />
            }
            {/* 쿠폰 코드 직접 입력 - isCodeDialog */}
            {
                isCodeDialog &&
                <EvDialog
                    title="쿠폰 코드 입력"
                    contents={
                        <DetailDialogLayout>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="발행 코드"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={"coupon_code"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={(event: any) => setPublishData({ ...publishData, code: event.target.value })}
                                    value={publishData.code}
                                />
                            </DetailDialogContentsDiv>
                            <div>
                                <EvButton
                                    text={"발행"}
                                    padding="7px 5px 5px 5px"
                                    margin="15px 0px 0px 0px"
                                    color={Color.primary
                                    }
                                    disable={publishData.code === undefined || publishData.code === ''}

                                    width={"100%"}
                                    onPressed={() => {
                                        setIsCodeDialog(false);
                                        publishedCoupon(publishData.id!, false, publishData.code);
                                    }}
                                />
                            </div>
                        </DetailDialogLayout>
                    }
                />
            }
        </ContentLayout>
    );
}







const SearchContents = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    @media all and (max-width:950px){
        height:${SearchContentsHeight + 35}px;
    }
    @media all and (max-width:655px){
        height:${SearchContentsHeight + 70}px;
    }
    @media all and (max-width:650px){
        height:${SearchContentsHeight + 35}px;
    }
    @media all and (max-width:435px){
        height:${SearchContentsHeight + 70}px;
    }
    
`;
const CheckAll = styled.div`
    width:100%;
    height:25px;
    display:flex;
    flex-direction:row;
    flex-wrap:no-wrap;
    padding:5px 0px;
    font-size:${TextSize.small};
    div{
        align-items:center;
        display:flex;
        padding:0px 5px 3px 5px;
    }
`;
const ListContents = styled.div`
    overflow-y:auto;
    height:calc(100% - ${PagingHeight + SearchContentsHeight + 35}px);
    width:100%;
    min-width:300px;
    border-radius:5px;
    // background-color:${Color.primary}
    @media all and (max-width:950px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 70}px);
    }
    @media all and (max-width:655px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 105}px);
    }

    @media all and (max-width:650px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 70}px);
    }
    @media all and (max-width:435px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 105}px);
    }
`;
const DetailDialogLayout = styled.div`
    display: flex;
    flex-direction: column; 
    min-width: 300px;
    width:100%;
`;
const DetailDialogContentsDiv = styled.div`
    padding:5px 0px;
    display: flex; 
    flex-direction: row;
    align-items:center;
    flex-wrap: nowrap;
    height:100%;
    margin-bottom:5px;
    select {
        height:100%;
    }
    input {
        // height:100%;

        height:25px;
        flex:3;
    }
    button{
        padding:0px;
        // height:calc(100% - 10px);
        flex:3;
    }
    div{
        align-items:center;
    }
`;

export default CouponsPage;