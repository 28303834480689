import Station from "./station";
import User from "./user";

class UserGroup {
    id: number;
    name: number;
    benefitType: string;
    benefitValue: number;
    mileageTy: string;
    regDt: Date;
    mdfDt: Date | null;
    delDt: Date | null;

    memberList: Array<User>;
    stationList: Array<Station>;
    memberCount: number;
    stationCount: number;


    constructor(
        id: number,
        name: number,
        benefitType: string,
        benefitValue: number,
        mileageTy: string,
        regDt: Date,
        mdfDt: Date | null,
        delDt: Date | null,
        memberList: Array<User>,
        stationList: Array<Station>,
        memberCount: number,
        stationCount: number,
    ) {
        this.id = id;
        this.name = name;
        this.benefitType = benefitType;
        this.benefitValue = benefitValue;
        this.mileageTy = mileageTy;
        this.regDt = regDt;
        this.mdfDt = mdfDt;
        this.delDt = delDt;
        this.memberList = memberList;
        this.stationList = stationList;
        this.memberCount = memberCount;
        this.stationCount = stationCount;

    }

    static fromJson(json: any): UserGroup {
        return new UserGroup(
            json.id,
            json.name,
            json.benefitType,
            json.benefitValue,
            json.mileageTy,
            json.regDt,
            json.mdfDt,
            json.delDt,
            json.memberList ? json.memberList.map((e: any) => User.fromJson(e)) : [],
            json.stationList ? json.stationList.map((e: any) => Station.fromJson(e)) : [],
            json.memberCount,
            json.stationCount,
        );
    }
}

export default UserGroup;
