import React from "react";
import styled from "styled-components";
import { Color, TextSize } from "../constants";

type LabelData = {
    isNeccessary?: boolean,
    text: string,
    flex?: number
}

const EvLabel = ({ text, isNeccessary = false, flex = 1 }: LabelData
) => {
    const element: JSX.Element[] = [];
    element.push(<div key={"text"}>{text}</div>);
    if (isNeccessary) {

        element.push(<div key={"star"} style={{ color: Color.primary, marginRight: "3px" }}>*</div>)
    }
    return (
        <React.Fragment>
            <LabelLayout style={{ flex: flex }}>
                {element}
            </LabelLayout>
        </React.Fragment>
    );
}

const LabelLayout = styled.div`
    font-size:${TextSize.normal};
    min-width:90px;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:start;
    
    flex:1;
    margin-right:10px;
`;

export default EvLabel;