import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppBarHeight, Color, MobileBreakWidth } from "../constants";
import styled from "styled-components";
import { hasAuth, removeAuth } from "../repository/authRepo";
import { Menu } from "../utils/svg";

import EvNav from "./evNav";
import AppBarLogo from "../assets/image/logo.png";
import logout from "../assets/image/logout.png";
import EvNavModal from "./evNavModal";

const EvLayOut = (contents: JSX.Element) => {
    const [isNavShow, setNavShow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleNaivegateMain = (event: any) => {
        event.preventDefault();
        if (location.pathname !== "/main") {
            navigate('/main');
        }
    }
    const handleLogout = (event: any) => {
        event.preventDefault();
        // 로그인관련 데이터 추가 필요
        removeAuth();
        navigate('/');
    }
    const handleMenu = () => {
        setNavShow(!isNavShow);
    }
    const isMobile = window.matchMedia(`all and (max-width:${MobileBreakWidth}px)`);

    function handleIsMobile(e: any) {
        if (!e.matches) {

            setNavShow(false);
        }
    }
    isMobile.addEventListener("change", handleIsMobile);
    const isAuth = hasAuth();

    if (isAuth) {
        return (

            <React.Fragment>

                <DefaultLayOut>
                    <AppBarLayOut>

                        <MenuDiv onClick={handleMenu} >
                            <Menu width={24} height={24} fill={Color.hamburgMenu} />
                        </MenuDiv>
                        <img src={AppBarLogo} alt="AppBarLogo" height={"40px"} onClick={handleNaivegateMain} />


                        <LogOutBox onClick={handleLogout} >

                            <img src={logout} alt="test" width={"20px"} height={"20px"} color={Color.black} />
                        </LogOutBox>



                    </AppBarLayOut>
                    <MainContentsLayOut>

                        <EvNav isShow={null} ></EvNav>

                        {contents}

                    </MainContentsLayOut>
                    {isNavShow && <div onClick={handleMenu}>

                        <EvNavModal isShow={isNavShow} />
                    </div>
                    }
                </DefaultLayOut>


            </React.Fragment>
        );
    } else {
        return (<Navigate to={'/'} replace={true} />);
    }
}

export const MainContentsLayOut = styled.div`
    display : flex;
    flex-direction: row;
    height: calc(100vh - ${AppBarHeight}px);
    width: 100vw;
`;
const MenuDiv = styled.div`

display:none;
@media all and (max-width:${MobileBreakWidth}px){
    display:block;
}
`;


const AppBarLayOut = styled.div`
    z-index:999;
    padding: 0px 10px;    
    align-items: center;
    display: flex;
    justify-content: space-between;
    height : ${AppBarHeight}px;
    width : calc(100vw - 20px);
    box-shadow: 0 5px 5px -2px ${Color.shadowColor};
    img {
        cursor:pointer;
    }
`;

const LogOutBox = styled.div`
    border: 1px solid ${Color.borderColor};
    padding: 5px 2px 2px 5px;
    border-radius: 5px;
    cursor:pointer;
`;
export const DefaultLayOut = styled.div`
    
display : flex;
flex-direction: column;
height: 100vh;
width: 100vw;
`;



export default EvLayOut;