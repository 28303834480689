class User {
    id: string;
    lgnId: string;
    nm: string;
    memberId: string;
    cardNo: string;
    loginId: string;
    email: string;
    phone: string;

    constructor(
        id: string,
        nm: string,
        lgnId: string,
        memberId: string,
        cardNo: string,
        loginId: string,
        email: string,
        phone: string,
    ) {
        this.id = id;
        this.nm = nm;
        this.lgnId = lgnId;
        this.cardNo = cardNo;
        this.memberId = memberId;
        this.loginId = loginId;

        if (loginId) {
            this.lgnId = loginId
        } else {
            this.loginId = lgnId
        }

        if (memberId) {
            this.id = memberId
        } else {
            this.memberId = id
        }
        this.email = email;
        if (email && email.includes('@')) {
            let em = email.split('@')
            this.email = `**@${em[em.length - 1]}`
        }
        this.phone = phone;
    }

    static fromJson(json: any): User {
        return new User(
            json.id,
            json.nm,
            json.lgnId,
            json.memberId,
            json.cardNo,
            json.loginId,
            json.email,
            json.phone,
        );
    }
}

export default User;