// import { buttonBlack, white } from "../constants";
import styled from "styled-components";
import React from "react";
import { Color, TextBold, TextSize } from "../constants";

type ButtonData = {
    text: string | null;
    color?: string | null;
    isOutline?: boolean;
    width?: string;
    fontSize?: string;
    padding?: string;
    fontColor?: string;
    margin?: string;
    onPressed?: React.MouseEventHandler<HTMLButtonElement> | null;
    disable?: boolean;
    value?: any;
    fontWeight?: string;
};

// default color is black
const EvButton = ({ text, margin = '0px', padding = '12px 0px 10px 0px', color = null, fontSize = TextSize.button, isOutline = false, width = '330px', fontColor = Color.white, onPressed = null, disable = false, value, fontWeight }: ButtonData) => {
    var buttonColor: string = '';
    // if (color == null) {
    //     // buttonColor = isOutline ? white : buttonBlack;
    //     buttonColor = isOutline ? Color.white : Color.buttonBlack;
    // } else {
    //     // buttonColor = isOutline ? white : color;
    //     buttonColor = isOutline ? Color.white : color;
    // }
    buttonColor = color == null ? Color.buttonBlack : color

    let style = {
        fontWeight: fontWeight ? fontWeight : TextBold.normal,
        margin: margin,
        color: fontColor,
        padding: padding,
        fontSize: fontSize,
        backgroundColor: disable ? Color.backGroundGrey : buttonColor,
        width: width,
        border: isOutline ? `1px solid ${Color.borderColor}` : 'none',
        cursor: disable ? 'default' : 'pointer'
    };

    const evBtn = (
        <React.Fragment>
            <EvBtn
                value={value}
                style={style}
                type={onPressed == null ? "submit" : "button"} onClick={onPressed != null && !disable ? onPressed : undefined}
            >
                {text ?? '확인'}
            </EvBtn>
        </React.Fragment >
    );


    return evBtn;
}
const EvBtn = styled.button`
        border-radius: 5px;
        onClick:"";
        cursor:pointer;
    `;





export default EvButton;