enum PointType {
    PURCHASE_PAY = 'PURCHASE_PAY',
    PURCHASE_COUPON = 'PURCHASE_COUPON',
    REWARD = 'REWARD',
    USE = 'USE',
    EXPIRE = 'EXPIRE',
    PURCHASECANCEL = 'PURCHASECANCEL',
    REWARDCANCLE = 'REWARDCANCLE',
    ADMINPLUS = 'ADMINPLUS',
    ADMINMINUS = 'ADMINMINUS'
}

function getPointTypeText(status: PointType) {
    switch (status) {
        case PointType.PURCHASE_PAY:
            return '포인트 충전';
        case PointType.PURCHASE_COUPON:
            return '쿠폰 충전';

        case PointType.REWARD:
            return '포인트 적립';
        case PointType.USE:
            return '포인트 사용';
        case PointType.EXPIRE:
            return '포인트 만료';
        case PointType.PURCHASECANCEL:
            return '충전 취소';
        case PointType.REWARDCANCLE:
            return '적립 취소';
        case PointType.ADMINPLUS:
            return '관리자 적립';
        case PointType.ADMINMINUS:
            return '관리자 차감';
        default:
            return '';
    }
}
class PointHistory {
    id: number;
    accept_key: string;
    company_code: string;
    membership_card_number: string;
    point_type: PointType;
    point: number;
    description: string;
    roaming_yn: string;
    expire_dt: string;
    created_at: string;
    constructor(
        id: number,
        accept_key: string,
        company_code: string,
        membership_card_number: string,
        point_type: PointType,
        point: number,
        description: string,
        roaming_yn: string,
        expire_dt: string,
        created_at: string
    ) {
        this.id = id;
        this.accept_key = accept_key;
        this.company_code = company_code;
        this.membership_card_number = membership_card_number;
        this.point_type = point_type;
        this.point = point;
        this.description = description;
        this.roaming_yn = roaming_yn;
        this.expire_dt = expire_dt;
        this.created_at = created_at;
    }

    static fromJson(json: Object): PointHistory {
        var pointHistoryData: any = {};
        for (const [key, value] of Object.entries(json)) {
            pointHistoryData[key] = value;
        }
        return new PointHistory(
            pointHistoryData['id'],
            pointHistoryData['accept_key'],
            pointHistoryData['company_code'],
            pointHistoryData['membership_card_number'],
            pointHistoryData['point_type'],
            pointHistoryData['point'],
            pointHistoryData['description'],
            pointHistoryData['roaming_yn'],
            pointHistoryData['expire_dt'],
            pointHistoryData['created_at'],
        );
    }
}
export { PointType, getPointTypeText, PointHistory }