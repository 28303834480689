import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvInput, { Type } from "../../components/evInput";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import MemberCardTile from "../../components/membercardTile";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inUserCardsTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import MemberCard from "../../models/memberCard";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getMemberCards } from "../../repository/memberCardRepo";
import { getCompanyCode, getRole } from "../../utils/utils";

function UserCardsPage() {
  let isUseEffectHandle: boolean = false;
  type MemberCardQuery = {
    company_code?: string;
    membership_card_number?: string;
    page: number;
  };
  type SearchData = {
    company_code: string;
    membership_card_number: string;
  };
  const navigate = useNavigate();
  const element = EvTab(inUserCardsTabDatas);

  const [queryData, setQueryData] = useState<MemberCardQuery>({
    company_code: "",
    membership_card_number: "",
    page: 1,
  });
  const [totalPage, setTotalPage] = useState<number | null>(null);
  const [searchData, setSearchData] = useState<SearchData>({
    company_code: "",
    membership_card_number: "",
  });
  const [memberCards, setMemberCards] = useState<Array<MemberCard> | null>(null);
  const [companies, setCompanies] = useState<Object>({});

  function getSendData() {
    let returnData = queryData!;
    if (getRole() !== "Admin") {
      returnData.company_code = getCompanyCode()!;
    } else {
      if (returnData.company_code === "") {
        delete returnData.company_code;
      }
    }
    if (returnData.membership_card_number === "") {
      delete returnData.membership_card_number;
    }

    // delete returnData.total_page;
    return returnData;
  }

  async function handleMemberCards() {
    setMemberCards(null);
    const sendData = getSendData();

    const result = await getMemberCards(sendData);
    if (result.result === RESULT.FAIL) {
      alert("회원카드 목록조회 실패");
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      // success
      const resultPageData: ResponseList = result.data;
      const resultData: Array<MemberCard> = resultPageData.items;

      setMemberCards(resultData);

      setTotalPage(resultPageData.last_page);
    }
  }
  async function handleComapanies() {
    const result = await getCompanies();
    if (result.result === RESULT.FAIL) {
      alert("조회 실패");
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const companiesResult: Array<Company> = result.data;
      let companySelect: any = {
        "제휴사 선택": "",
      };
      for (var i = 0; i < companiesResult.length; i++) {
        companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
      }
      setCompanies(companySelect);
    }
  }
  const handleSearchInput = (e: { target: { value: string } }) => {
    setSearchData({
      ...searchData,
      membership_card_number: e.target.value.replaceAll(/[^0-9]/g, ""),
    });
  };
  async function callHandle() {
    if (getRole() === "Admin") {
      await handleComapanies();
    }

    await handleMemberCards();
  }
  //쿼리
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (window.location.search === "") {
      isUseEffectHandle = true;
      return navigate(getQueryString({ page: 1 })!, { replace: true });
    } else if (
      params.get("page") !== queryData.page.toString() ||
      // params.get('company_code') !== null &&
      params.get("company_code") !== queryData.company_code ||
      // params.get('membership_card_number') !== null &&
      params.get("membership_card_number") !== queryData.membership_card_number
    ) {
      isUseEffectHandle = true;
      setSearchData({
        ...searchData,
        company_code: params.get("company_code") ?? "",
        membership_card_number: params.get("membership_card_number") ?? "",
      });
      return setQueryData({
        ...queryData,
        page: Number.parseInt(params.get("page") ?? "1"),
        company_code: params.get("company_code") ?? "",
        membership_card_number: params.get("membership_card_number") ?? "",
      });
    }
    // eslint-disable-next-line
    isUseEffectHandle = false;
  }, [window.location.search]);

  useEffect(() => {
    if (isUseEffectHandle) return;

    callHandle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, window.location.search]);

  const handleSelect = (event: any) => {
    if (event.target.value !== null) {
      setSearchData({
        ...searchData,
        company_code: event.target.value,
      });
    } else {
      setSearchData({
        ...searchData,
        company_code: "",
      });
    }
  };

  const handlePage = async (e: any) => {
    const sendData = getSendData();
    sendData.page = Number.parseInt(e.target.value);
    navigate(getQueryString(sendData)!);
  };

  return EvLayOut(
    <ContentLayout>
      <DefaultContentsPadding>
        {element}
        {memberCards === null ? (
          <ElementContents>
            <EvLoading />
          </ElementContents>
        ) : (
          <ElementContents>
            {/* search contents need */}
            <SearchContents>
              {getRole() === "Admin" ? EvSelectBox(companies, handleSelect, 1, searchData.company_code) : null}
              <EvInput
                flex={3}
                name={"search_text"}
                placeHolder={"회원카드 번호 검색"}
                type={Type.TEXT}
                onChanged={handleSearchInput}
                value={searchData.membership_card_number}
                onSubmit={() =>
                  navigate(
                    getQueryString({
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      page: 1,
                    })!
                  )
                }
              />
              <EvButton
                margin="0px 0px 0px 5px"
                padding="7px 5px 5px 5px"
                text={"검색"}
                width={"5rem"}
                fontSize={TextSize.small}
                color={Color.black}
                fontWeight={TextBold.bold}
                onPressed={() =>
                  navigate(
                    getQueryString({
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      page: 1,
                    })!
                  )
                }
                // fontColor={Color.black}
              />
            </SearchContents>
            <ListContents
              style={
                memberCards.length === 0
                  ? {
                      backgroundColor: Color.white,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      color: Color.textSecondary,
                    }
                  : {}
              }
            >
              {memberCards.length === 0
                ? "검색된 회원카드가 존재하지 않습니다."
                : memberCards.map((value, index) => {
                    return MemberCardTile(value);
                  })}
            </ListContents>
            {/* page contents need */}
            {Paging(
              queryData!.page,
              totalPage!,

              (e: any) => {
                e.stopPropagation();
                handlePage(e);
              }
            )}
          </ElementContents>
        )}
      </DefaultContentsPadding>
    </ContentLayout>
  );
}
const SearchContents = styled.div`
  width: 100%;
  height: ${SearchContentsHeight}px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 10px 0px;
`;
const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
  // background-color:${Color.primary}
`;
export default UserCardsPage;
