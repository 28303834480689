import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvLoading from "../../components/evLoading";
import EvTab from "../../components/evTab";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inMembershipManage, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import MembershipTile from "./MembershipTile";
import { getCompanyCode, useAddAlert, useModal } from "../../utils/utils";
import { deleteMemberShip, getMembershipList, putMemberShip } from "../../repository/membershipRepo";
import Membership from "../../models/memberShip";
import Paging from "../../components/paging";
import { useRecoilState } from "recoil";
import { loadingModalState, memberShipCopyState, memberShipTileCheckState } from "../../recoil/atoms";

function MembershipListPage() {
    const element = EvTab(inMembershipManage);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState);
    const { showModal } = useModal();
    const { addAlert } = useAddAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const [queryParams, setQueryParams] = useState({});
    const [memeberShipList, setMemberShipList] = useState<Array<Membership> | null>(null);
    const [memberShipTileCheck, setMemberShipTileCheck] = useRecoilState(memberShipTileCheckState);
    const [copyData, setCopyData] = useRecoilState(memberShipCopyState);

    const getMembershipListAsync = async () => {
        setLoading(true)
        var res = await getMembershipList({
            ...queryParams,
            size: 20,
            cloudCode: getCompanyCode()
        });
        setMemberShipList(res.data.items);
        setMemberShipTileCheck([])
        setTotalPage(res.data.last_page)
        setLoading(false)
    }
    useEffect(() => {
        var searches = location.search.substring(1).split('&')
        var b = searches.map((element) => {
            if (element.split('=')[0] == 'page') {
                setCurrentPage(Number(element.split('=')[1]))
            }
            return {
                [element.split('=')[0]]: element.split('=')[0] == 'page' ? Number(element.split('=')[1]) - 1 : element.split('=')[1]
            }
        })
        setQueryParams(Object.assign({}, ...b))
    }, []);
    useEffect(() => {
        getMembershipListAsync();
    }, [
        queryParams
    ])

    const onClickDelete = async () => {
        var promisList: any[] = []

        memberShipTileCheck.forEach((element) => {
            promisList.push(putMemberShip(element.id))
        })
        try {
            setLoadingModal({ isOpen: true });

            await Promise.all(promisList);

            setQueryParams(
                { page: currentPage - 1 }
            )
        } catch (error) {
            console.error("프로미스 중 하나 이상이 reject되었습니다:", error);
        } finally {
            setLoadingModal({ isOpen: false });
        }
    }


    return (
        <ContentLayout>
            <DefaultContentsPadding>
                {element}
                {loading ? (
                    <ElementContents>
                        <EvLoading />
                    </ElementContents>
                ) : (
                    <ElementContents>
                        {/* search contents need */}
                        <ButtonLayout>
                            <EvButton
                                margin="0 5px 12px 0"
                                padding="7px 5px 5px 5px"
                                text={"멤버십 등급 생성"}
                                width={"7rem"}
                                fontSize={TextSize.small}
                                color={Color.primary}
                                fontWeight={TextBold.bold}
                                onPressed={() => {
                                    navigate('/membershipManage/detail/create')
                                    setCopyData({})
                                }
                                }
                            />
                            <EvButton
                                padding="7px 5px 5px 5px"
                                margin="0 0 12px 0"
                                text={"멤버십 등급 삭제"}
                                width={"7rem"}
                                fontSize={TextSize.small}
                                color={Color.red}
                                fontWeight={TextBold.bold}
                                onPressed={() => {
                                    if (memberShipTileCheck.length < 1) {
                                        addAlert('삭제할 항목을 선택해주세요.')
                                        return;
                                    }
                                    showModal(
                                        {
                                            body: '멤버십 등급이 삭제됩니다.',
                                            action: '삭제',
                                            onActionClick: onClickDelete
                                        }
                                    )
                                }
                                }
                            />
                        </ButtonLayout>
                        <ListContents>
                            {
                                memeberShipList == null ?
                                    null
                                    :
                                    memeberShipList.map((e, key) => {
                                        return <MembershipTile memberShip={e} key={key} />
                                    })
                            }
                        </ListContents>
                        {Paging(
                            currentPage,
                            totalPage
                            ,
                            (e: any) => {
                                e.stopPropagation();
                                setCurrentPage(Number(e.target.value))
                                setQueryParams(
                                    { page: e.target.value - 1 }
                                )
                                navigate(`/membershipManage?page=${e.target.value}`);
                            }
                        )}
                    </ElementContents>
                )}
            </DefaultContentsPadding>
        </ContentLayout>
    );
}

const ButtonLayout = styled.div`
  display: flex;
  justify-content: end;
`
const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
  // background-color:${Color.primary}
`;
export default MembershipListPage;
