import React, { useEffect, useState } from "react";
import mainLogo from "../../assets/image/logo.jpeg";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import { Color, TextBold, TextSize } from "../../constants";
import { SessionKey, getSession, hasAuth, login, removeAuth, setAccessTokens } from "../../repository/authRepo";
import { RESULT } from "../../models/response";
import Auth from "../../models/auth";
import { useNavigate } from "react-router-dom";
import EvInput, { Type } from "../../components/evInput";
import { validateEmpty } from "../../utils/validate";
import { setAuthData } from "../../repository/authRepo";

function LoginPage() {
    const navigate = useNavigate();
    const isAuth = hasAuth();


    const [loginData, setLoginData] = useState({
        lgnId: '',
        lgnPassword: ''
    });
    const onChangeLoginData = (e: { target: { name: string; value: string; }; }) => {

        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        if (isAuth) {
            removeAuth();
        }
    });



    const handleSubmit =
        async (event: any) => {
            event.preventDefault();
            const idResult: string | null = validateEmpty(loginData.lgnId, "아이디");
            if (idResult != null) {
                alert(idResult);
                return;
            }
            const pwResult: string | null = validateEmpty(loginData.lgnPassword, "비밀번호");
            if (pwResult != null) {
                alert(pwResult);
                return;
            }
            const lgnId = loginData.lgnId;
            const lgnPassword = loginData.lgnPassword;
            var result = await login(loginData.lgnId, loginData.lgnPassword);
            if (result.result === RESULT.FAIL) {
                // 아이디 혹은 비밀번호 어떤 정보가 틀렸는지 알수 없어야 한다.
                // alert(result.msg);
                alert('입력하신 아이디/비밀번호를 확인해주세요.');
            } else {
                var authData: Auth = result.data;
                if (authData.access_token == null || authData.refresh_token == null) {
                    //login logic에서는 값이 진행 플로우상 무조건 서버로 부터 전달받으나 서버 쪽 오류로 인해 null일경우 방어코드
                    alert('잠시 후 다시 시도해 주세요.');
                }

                authData.login_id = lgnId;
                authData.password = lgnPassword;
                await setAuthData(authData);
                await setAccessTokens();
                navigate('/main');
            }
        };

    return (
        // DOM에  별도의 노드를 추가하지 않고 child를 그룹화 키가 존재하지 않으면 <></>로 선언가능
        <React.Fragment>

            <LoginContainer>

                <img src={mainLogo} alt="mainLogo" />
                <div>
                    Point-Coupon Management System
                </div>
                <form onSubmit={handleSubmit}>


                    <EvInput name="lgnId" placeHolder="아이디" type={Type.TEXT} onChanged={onChangeLoginData} isFull={false} />
                    <EvInput name="lgnPassword" placeHolder="비밀번호" type={Type.PASSWORD} onChanged={onChangeLoginData} isFull={false} />


                    <EvButton text='로그인' color={null} onPressed={null} />
                </form>

            </LoginContainer>


        </React.Fragment>
    );

}
const LoginContainer = styled.div`
    width : 100vw;
    height : 100vh;
    display : flex;
    // padding-left : 10px;
    // padding-right : 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    form {
        display : flex;
        padding-left : 10px;
        padding-right : 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img {
        margin-bottom : 15px;
        
        width: 330px;
    }
    div {
        margin-bottom : 15px;
        font-size : ${TextSize.normal};
        font-weight: ${TextBold.bold};
        color: ${Color.textFirst};
    }
    input {
        margin-bottom : 15px;
        padding: 10px 15px;
        
        width: 300px;
        border-radius: 5px;
        font-size: ${TextSize.input};
        color: ${Color.textFirst};
        border : solid 1px ${Color.borderColor};
        
    }
    input:focus { 
        outline:none;
        border-color: ${Color.borderFocusColor};
    }
    
    
`;

export default LoginPage



