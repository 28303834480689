

import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import EvButton from "./evButton";
import { useRecoilState } from "recoil";
import { fileInputModalState, modalState } from "../recoil/atoms";
import { Close } from "../utils/svg";
import { useState } from "react";
import { useAddAlert } from "../utils/utils";

function EvFileInputModal({ }) {
  const [modal, setModal] = useRecoilState(fileInputModalState);
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { addAlert } = useAddAlert();
  const MB = 1024 * 1024;

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFileChange = async (file: any) => {
    var fileNameSplitList = file.name.split('.')
    var extension = `.${fileNameSplitList[fileNameSplitList.length - 1]}`
    var isPass = true;
    if (!modal.extension.includes(extension)) {
      addAlert('파일 확장자를 확인해주세요.')
      isPass = false;
    }

    if (modal.maxSize * MB <= file.size) {
      addAlert('파일 용량를 확인해주세요.')
      isPass = false;
    }

    if (!(await modal.fileVaildator(file))) {
      addAlert('파일 형식을 확인해주세요.')
      isPass = false;
    }
    if (isPass) {
      setFile(file)
    } else {
      setFile(null)
    }
  }

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    const inputFile = files[0];
    onFileChange(inputFile);
  };


  return (
    <ModalBackgroundLayout>
      <ModalLayout>
        <Modal>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "50px", width: "400px" }}>
            <div style={{ textAlign: 'center' }} >{modal.fileType} 업로드</div>
            <Close onClick={(e) => {
              setModal({
                fileType: "",
                extension: [],
                maxSize: 0,
                format: "",
                fileFormatDownloadUrl: "",
                isOpen: false,
                onClickSave: () => { },
                fileVaildator: (file: File) => { return true }
              });
            }}></Close>
          </div>
          <hr style={{ marginTop: '0' }}></hr>
          <div style={{ fontSize: '14px' }}>
            ❄ {modal.fileType} 규격
            <ul style={{ listStyle: "none", paddingLeft: "16px" }}>
              <li>- 형식 : {modal.format}</li>
              <li>- 확장자 : {modal.extension.join(', ')}</li>
              <li>- 최대 용량 : {modal.maxSize}MB</li>
            </ul>
          </div>
          <br></br>
          <hr></hr>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
            {
              modal.fileFormatDownloadUrl != "" ? <>
                <div style={{ marginRight: '12px' }}>❄ 파일 양식</div>
                <EvButton
                  text={'Download'}
                  padding="10px 5px"
                  width={'6rem'}
                  fontSize={TextSize.small}
                  color={Color.white}
                  fontColor={Color.black}
                  isOutline={true}
                  onPressed={async () => {
                  }}

                />
              </> : null
            }
          </div>
          <hr></hr>
          <div style={{ fontSize: '14px' }}>
            ❄ 업로드
          </div>
          <hr style={{ border: '0' }}></hr>
          <label htmlFor={`modalFileInput`}>
            <div
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{ fontSize: '12px', backgroundColor: Color.backGroundGrey, height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              {file == null ?
                <>
                  <div>파일을 이곳에 드래그 하시거나,</div>
                  <div><span style={{ color: Color.secondary }}>여기를</span> 클릭하여 업로드할 파일을 선택하세요.</div>
                </>
                :
                <>
                  <div style={{ color: Color.secondary }}>{file.name}</div>
                </>
              }



              <input type="file" name="file" id={`modalFileInput`} hidden
                accept={modal.extension.join(', ')}
                onChange={(e: any) => {
                  if (e.target.files?.length > 0) {
                    var inputFile = e.target.files[0];
                    onFileChange(inputFile);
                  }
                }}
              />
            </div>
          </label>
          <hr style={{ border: '0' }}></hr>
          <div style={{ textAlign: 'right' }}>
            <EvButton
              text={'저장'}
              padding="10px 5px"
              width={'6rem'}
              fontSize={TextSize.small}
              color={Color.primary}
              fontColor={Color.white}
              isOutline={true}
              onPressed={async () => {
                if (file == null) {
                  addAlert('입력된 파일이 없습니다.')
                  return;
                }
                await modal.onClickSave(file)
                setModal({
                  fileType: "",
                  extension: [],
                  maxSize: 0,
                  format: "",
                  fileFormatDownloadUrl: "",
                  isOpen: false,
                  onClickSave: () => { },
                  fileVaildator: (file: File) => { return true }
                });
              }}
            />
          </div>
        </Modal>
      </ModalLayout>
    </ModalBackgroundLayout>
  );
}
const ModalBackgroundLayout = styled.div`
  width:100vw;
  height:100vh;
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  z-index: 1001;
`;
const ModalLayout = styled.div`
  display:flex;
  height:100%;
  justify-content:center;
  align-items:center;
`

const Modal = styled.div`
  justify-content:center;
  align-items:center;
  background-color: white;
  padding: 0 16px 16px 16px;
  border-radius: 12px;

`

export default EvFileInputModal;

