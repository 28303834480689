import React from "react";
import styled from "styled-components";
import { Color, TextSize } from "../constants";
import Coupon from "../models/coupon";
import { getCardNumberWithDash, getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";

const CouponTile = (coupon: Coupon) => {
  return (
    <React.Fragment key={coupon.id}>
      <TileLayout style={{ minHeight: "35px", justifyContent: "space-between" }}>
        {/* companycode / name / code / membercardN / amount / used dt */}
        <CouponContents>
          <EvBadge text={coupon.company_code} textColor={Color.primary} borderColor={Color.primary} />
          <div>{coupon.name}</div>
          <div style={{ fontSize: TextSize.small }}>({coupon.code})</div>
        </CouponContents>
        <CouponContents style={{ justifyContent: "space-between" }}>
          <div>{coupon.membership_card_number.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}</div>
          <div>{getPriceString(coupon.amount)}</div>
        </CouponContents>
        <div>{getLocaleDateString(coupon.used_at)}</div>
      </TileLayout>
    </React.Fragment>
  );
};

const CouponContents = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
`;

export default CouponTile;
