

import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import EvButton from "./evButton";
import { useRecoilState } from "recoil";
import { loadingModalState, modalState } from "../recoil/atoms";
import EvLoading from "./evLoading";

function EvLoadingModal({ }) {

  return (
    <ModalBackgroundLayout>
      <ModalLayout>
        <EvLoading />
      </ModalLayout>
    </ModalBackgroundLayout>
  );
}
const ModalBackgroundLayout = styled.div`
  width:100vw;
  height:100vh;
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  z-index: 99999;
`;
const ModalLayout = styled.div`
  display:flex;
  height:100%;
  justify-content:center;
  align-items:center;
`

const Modal = styled.div`
  justify-content:center;
  align-items:center;
  background-color: white;
  padding: 0 16px 16px 16px;
  border-radius: 12px;

`

export default EvLoadingModal;

