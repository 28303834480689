import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvInput, { Type } from "../../components/evInput";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import MemberCardTile from "../../components/membercardTile";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inUserGroupManage, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import MemberCard from "../../models/memberCard";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getMemberCards } from "../../repository/memberCardRepo";
import { useAddAlert, getCompanyCode, getRole, useModal } from "../../utils/utils";
import UserGroupTile from "../../components/userGroupTile";
import { randomInt } from "crypto";
import UserGroup from "../../models/userGroup";
import { deleteGroup, getGroups } from "../../repository/groupRepo";
import { useRecoilState } from "recoil";
import { createGroupState, loadingModalState, otherGroupUserIdListState, userGroupTileCheckState } from "../../recoil/atoms";

function UserGroupList() {
  const element = EvTab(inUserGroupManage);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState);
  const { addAlert } = useAddAlert();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [queryParams, setQueryParams] = useState({});
  const [userGroups, setUserGroups] = useState<Array<UserGroup> | null>(null);
  const [userGroupCheckList, setUserGroupCheckList] = useRecoilState(userGroupTileCheckState);
  const [createGroup, setCreateGroup] = useRecoilState(createGroupState);
  const [otherGroupUserIdList, setOtherGroupUserIdList] = useRecoilState(otherGroupUserIdListState);

  const getGroupListAsync = async () => {
    setLoading(true)
    var res = await getGroups({
      ...queryParams,
      size: 20,
      cloudCode: getCompanyCode()
    });
    setUserGroups(res.data.items);
    setUserGroupCheckList([])
    setTotalPage(res.data.pagingData.totalPage);
    setLoading(false);
  }

  useEffect(() => {
    var searches = location.search.substring(1).split('&')
    var b = searches.map((element) => {
      if (element.split('=')[0] == 'page') {
        setCurrentPage(Number(element.split('=')[1]))
      }
      return {
        [element.split('=')[0]]: element.split('=')[0] == 'page' ? Number(element.split('=')[1]) - 1 : element.split('=')[1]
      }
    })
    setQueryParams(Object.assign({}, ...b))
  }, []);

  useEffect(() => {
    getGroupListAsync();
  }, [
    queryParams
  ])

  const onClickDelete = async () => {
    var promisList: any[] = []
    userGroupCheckList.forEach((element: any) => {
      promisList.push(deleteGroup(element.id))
    })
    try {
      setLoadingModal({ isOpen: true });
      await Promise.all(promisList);
      setQueryParams(
        { page: currentPage - 1 }
      )
    } catch (error) {
      console.error("프로미스 중 하나 이상이 reject되었습니다:", error);
    } finally {
      setLoadingModal({ isOpen: false });
    }
  }

  return <>
    {element}
    {userGroups === null ? (
      <ElementContents>
        <EvLoading />
      </ElementContents>
    ) : (
      <ElementContents>
        <ButtonLayout>
          <EvButton
            margin="0 5px 12px 0"
            padding="7px 5px 5px 5px"
            text={"회원 그룹 생성"}
            width={"7rem"}
            fontSize={TextSize.small}
            color={Color.primary}
            fontWeight={TextBold.bold}
            onPressed={() => {
              setCreateGroup({
                name: "",
                benefitValue: 0,
                stationList: [] as any,
                userList: [] as any,
                detail: false,
              })
              navigate('/userGroup/create')
            }
            }
          />
          <EvButton
            padding="7px 5px 5px 5px"
            margin="0 0 12px 0"
            text={"회원 그룹 삭제"}
            width={"7rem"}
            fontSize={TextSize.small}
            color={Color.red}
            fontWeight={TextBold.bold}
            onPressed={() => {
              if (userGroupCheckList.length == 0) {
                addAlert('선택된 항목이 없습니다.')
                return;
              }
              showModal(
                {
                  body: '회원 그룹이 삭제됩니다.',
                  action: '삭제',
                  onActionClick: async () => {
                    await onClickDelete()
                  }
                }
              )
            }
            }
          />
        </ButtonLayout>
        <ListContents
          style={
            userGroups.length === 0
              ? {
                backgroundColor: Color.white,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: Color.textSecondary,
              }
              : {}
          }
        >
          {userGroups.length === 0
            ? "검색된 회원카드가 존재하지 않습니다."
            : userGroups.map((value, index) => {
              return UserGroupTile(index, value, userGroupCheckList, setUserGroupCheckList, setCreateGroup, setOtherGroupUserIdList);
            })}
        </ListContents>
        {/* page contents need */}
        {Paging(
          currentPage,
          totalPage,
          (e: any) => {
            e.stopPropagation();
            setCurrentPage(Number(e.target.value))
            setQueryParams(
              { page: e.target.value - 1 }
            )
            navigate(`/userGroup?page=${e.target.value}`);
          }
        )}
      </ElementContents>
    )}
  </>;
}
const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: end;
`
export default UserGroupList;
