import React from "react";
import styled from "styled-components";
import { AppBarHeight, MobileBreakWidth } from "../constants";
import EvNav from "./evNav";

type EvNavModalData = {
    isShow: boolean;
};


const EvNavModal = ({ isShow }: EvNavModalData) => {
    return (
        <React.Fragment>
            <EvNavModalStyled >
                <EvNav isShow={isShow}></EvNav>
            </EvNavModalStyled>
        </React.Fragment>
    );

}

const EvNavModalStyled = styled.div`
    width:100vw;
    height: calc(100vh - ${AppBarHeight}px);
    z-index:800;
    position: absolute;
    top:${AppBarHeight}px;
    background-color:transparent;
    // @media all and (min-width:${MobileBreakWidth}px){
    //     display:block;
    // }
`;

export default EvNavModal;