import Station from "../models/station";
import UserGroup from "../models/userGroup";
import { toJsonObject } from "../utils/utils";
import { API, getList, getResponseList, httpDelete, httpGet, httpPost, httpPut } from "./axios";

async function getDeletedGroups(queryData: any) {
    return await httpGet(API.MEMBERSHIP_DELETE_GROUP_LIST, '', queryData, null);
}

async function getGroupDetail(id: string) {
    const converter = UserGroup.fromJson
    return await httpGet(API.MEMBERSHIP_GROUP_DETAIL, id, null, converter);
}

async function getDeletedGroupDetail(id: string) {
    const converter = UserGroup.fromJson
    return await httpGet(API.MEMBERSHIP_DELETED_GROUP_DETAIL, id, null, converter);
}

async function deleteGroup(id: string) {
    const converter = UserGroup.fromJson
    return await httpDelete(API.MEMBERSHIP_GROUP_DETAIL, id, null, null);
}

async function postGroup(data: any) {
    const converter = UserGroup.fromJson
    return await httpPost(API.MEMBERSHIP_GROUP_CREATE, "", null, data, converter);
}


async function putGroup({ id, data }: { id: string, data: any }) {
    const converter = UserGroup.fromJson
    return await httpPut(API.MEMBERSHIP_GROUP_DETAIL, id, null, data, converter);
}

async function getGroups(queryData: any) {
    return await httpGet(API.MEMBERSHIP_GROUP_CREATE, '', queryData, null);
}



export { getDeletedGroups, getGroupDetail, postGroup, getGroups, deleteGroup, putGroup, getDeletedGroupDetail };