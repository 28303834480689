import styled from "styled-components"
import { Color, TextSize } from "../../constants";
import EvInput, { Type } from "../../components/evInput";
import EvButton from "../../components/evButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackSvg, NextSvg } from "../../utils/svg";
import EvSelectBox from "../../components/evSelectBox";
import { useEffect, useRef, useState } from "react";
import { getStations } from "../../repository/stationRepo";
import Station from "../../models/station";
import User from "../../models/user";
import ResponseList from "../../models/responseList";
import { useRecoilState } from "recoil";
import { createGroupState, loadingModalState, otherGroupUserIdListState } from "../../recoil/atoms";
import { getDeletedGroupDetail, getDeletedGroups, getGroupDetail } from "../../repository/groupRepo";
import Response, { RESULT } from "../../models/response";
import UserGroup from "../../models/userGroup";
import { useAddAlert, useModal } from "../../utils/utils";
import { getUsers } from "../../repository/userRepo";
import { memberGroupDuplicateCheck } from "../../repository/membershipRepo";
import { group } from "console";

export const MatchingDetail = () => {
    const location = useLocation();
    // staiton or user
    var locationName = location.pathname.split('/').at(location.pathname.split('/').length - 1);
    var locationKor = locationName == 'station' ? '충전소' : '회원'
    var groupId = location.pathname.split('/')[2];
    var isCreate = groupId == 'create';
    const navigate = useNavigate();
    const { showModal } = useModal();
    const { addAlert } = useAddAlert();
    const [createGroup, setCreateGroup] = useRecoilState(createGroupState);
    const [otherGroupUserIdList, setOtherGroupUserIdList] = useRecoilState(otherGroupUserIdListState);

    // 왼쪽 리스트
    var [selectableList, setSelectableList] = useState<any[]>([]);
    var [checkedSelectableList, setCheckedSelectableList] = useState<any[]>([]);

    // 오른쪽 리스트
    var [selectedList, setSelectedList] = useState<any[]>(
        locationName == "station"
            ? createGroup.stationList
            : createGroup.userList
    );
    var [checkedSelectedList, setCheckedSelectedList] = useState<any[]>([]);

    // 충전소 & 유저 리스트 관련 state
    const [page, setPage] = useState<number>(1);
    const [load, setLoading] = useState(true);
    const [isLast, setIsLast] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [queryParams, setQueryParams] = useState({});
    const [deletedUserGroupList, setDeletedUserGroupList] = useState([])
    const [selectableSearchOption, setSelectableSearchOption] = useState("");
    const [selectableSearchOptionValue, setSelectableSearchOptionValue] = useState("");

    const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState);

    const [intervalId, setIntervalId] = useState<any>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const [selectedListSearch, setSelectedListSearch] = useState('');
    const [selectedSearchOption, setSelectedSearchOption] = useState('구분');

    const [selectedDeletedUserGroupId, setSelectedDeletedUserGroupId] = useState("");

    const getIdList = () => {
        var idList = selectedList.map((e) => { return e.id.toString() })
        var idList2 = selectableList.map((e) => { return e.id.toString() })
        return [...idList, ...idList2]
    }

    const getSelectableList = async () => {
        if (isFetching || isLast) return;
        var cPage = page;
        setIsFetching(true);
        if (locationName == 'station') {
            var res = await getStations({
                currentPage: cPage,
                perPage: 100,
                delSttusYn: "N",
                ...queryParams
            });

            if (res.data?.items < 1) {
                setIsLast(true)
            }
            var li = getIdList()
            setSelectableList(prev => ([...prev, ...(res.data.items as []).filter(
                (element, idx) => {
                    return !li.includes(((element as User).id).toString());
                }
            )
            ]));

            setLoading(false);
            setPage(cPage);
        }
        else if (locationName == 'user') {
            try {
                var res = await getUsers({
                    currentPage: cPage,
                    perPage: 100,
                    userDeleteTy: '02',
                    ...queryParams
                });
                if (res.data.items < 1) {
                    setIsLast(true)
                }

                var li = getIdList()
                setSelectableList(prev => ([...prev, ...(res.data.items as []).filter(
                    (element, idx) => {
                        return (element as User).cardNo
                    }
                ).filter(
                    (element, idx) => {
                        return !li.includes((element as User).id.toString());
                    }
                )
                ]));
            } catch {

            }
            setLoading(false);
            setPage(cPage);
        }
        setIsFetching(false); // 작업이 완료되었음을 표시
        setLoadingModal({ isOpen: false })
    };

    const getDeletedGroupList = async () => {
        var res = await getDeletedGroups({});
        if (res.data.length > 0) {
            setDeletedUserGroupList(res.data)
        }
    };

    const getDeletedGroupDetailasync = async () => {
        var res = await getDeletedGroupDetail(selectedDeletedUserGroupId);
        var userGroup = res.data as UserGroup
        if (locationName == 'station') {
            var ids = userGroup.stationList.map((e) => e.id.toString());
            setSelectableList((prev) => [...(prev.filter(
                (e) => {
                    return !ids.includes(e.id.toString());
                }
            )), ...selectedList]);
            setSelectedList(userGroup.stationList)
        } else {
            var ids = userGroup.memberList.map((e) => e.id.toString());
            setSelectableList((prev) => [...(prev.filter(
                (e) => {
                    return !ids.includes(e.id.toString());
                }
            )), ...selectedList]);
            setSelectedList(userGroup.memberList)
        }
        setCheckedSelectedList([]);

    };


    useEffect(() => {
        if (load) {
            getSelectableList();
        }
    }, [page, load])

    useEffect(() => {
        setLoadingModal({ isOpen: true })
        getDeletedGroupList();

        if (locationName == 'station') {
            if (createGroup.stationList.length > 0) {
                setSelectedList(createGroup.stationList)
            }
        } else {
            if (createGroup.userList.length > 0) {
                setSelectedList(createGroup.userList)
                setCheckedSelectedList(createGroup.userList.filter((ele: any) => {
                    return otherGroupUserIdList.includes(ele.memberId.toString())
                }))
            }
        }

        scrollRef.current?.addEventListener('scroll', (e: any) => {
            const scrollElement = scrollRef.current;
            if (!scrollElement) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollElement;
            const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight * 0.96;
            if (isScrolledToBottom) {
                setLoading(true)
                setPage(prev => (prev + 1))
            }
        })
    }, [])

    useEffect(() => {
        if (isLast && intervalId != null) {
            clearInterval(intervalId)
            setIntervalId(null)
            setCheckedSelectableList(selectableList);
        }
    }, [isLast])

    useEffect(() => {
        if (intervalId == null) {
            if (isLast) {
                setLoadingModal({ isOpen: false })
            }
        } else {
            setLoadingModal({ isOpen: true })
        }
    }, [intervalId])

    useEffect(() => {
        if (selectedDeletedUserGroupId) {
            getDeletedGroupDetailasync();
        }
    }, [selectedDeletedUserGroupId])

    const [filter, setFilter] = useState(false)

    useEffect(() => {
        if (selectableList.length > 0 && filter == false) {
            setFilter(true)
        }
    }, [selectableList])

    useEffect(() => {
        var idList = selectedList.map((e) => { return e.id })
        setSelectableList(prev => ([...prev.filter(
            (element, idx) => {
                return !idList.includes((element as User).id.toString());
            }
        )
        ]));
    }, [filter])

    const getFilterList = () => {
        return selectedList.filter((ob: User | Station) => {
            if (selectedSearchOption == '구분') return true;
            if (locationName == 'station') {
                if (selectedSearchOption == 'id') {
                    return ob.id.toUpperCase().includes(selectedListSearch?.toUpperCase());
                } else if (selectedSearchOption == 'nm') {
                    return ob.nm.toUpperCase().includes(selectedListSearch?.toUpperCase());
                }
            } else {
                if (selectedSearchOption == 'cardNo') {
                    return (ob as User).cardNo?.toUpperCase().includes(selectedListSearch?.toUpperCase());
                } else if (selectedSearchOption == 'lgnId') {
                    return (ob as User).lgnId?.toUpperCase().includes(selectedListSearch?.toUpperCase());
                } else if (selectedSearchOption == 'email') {
                    return (ob as User).email?.toUpperCase().includes(selectedListSearch?.toUpperCase());
                }
            }
        })
    }

    return <>
        <div>매칭 {locationKor} 상세 보기</div>
        <MatchingDetailLayout>
            <div>
                <div>기존 회원 그룹에서 불러오기</div>
                <select onChange={(e) => {
                    if (e.target.value === '선택없음') {
                        setSelectedDeletedUserGroupId("")
                        return
                    }
                    setSelectedDeletedUserGroupId(e.target.value)
                }
                }
                >
                    {
                        <>
                            <option>선택없음</option>
                            {
                                deletedUserGroupList.map(
                                    (element: UserGroup, idx: number) => {
                                        return <option value={element.id} key={idx} >{element.name}</option>
                                    }
                                )
                            }
                        </>
                    }
                </select>
            </div>
            <TablesLayout>
                <TableLayout>
                    <div>{locationKor} 리스트</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <select style={{ width: "30%" }} onChange={(e) => {
                            setSelectableSearchOption(e.target.value)
                        }}>
                            {
                                locationName == 'station' ?
                                    <>
                                        <option value={""}>구분</option>
                                        <option value={'stationNm'}>충전소 명</option>
                                        <option value={'stationIdntfr'}>SID</option>
                                    </>
                                    :
                                    <>
                                        <option value={''}>구분</option>
                                        <option value={'cardNo'}>멤버십</option>
                                        <option value={'userLgnId'}>아이디</option>
                                        <option value={'email'}>이메일</option>
                                    </>

                            }
                        </select>
                        <div style={{ width: "69%" }}>
                            <EvInput
                                name={"name"}
                                placeHolder={`${locationKor} 검색하기`}
                                type={Type.TEXT}
                                onChanged={(e) => {
                                    setSelectableSearchOptionValue(e.target.value);

                                }}
                                onSubmit={() => {
                                    if (selectableSearchOptionValue == "") {
                                        setQueryParams({
                                        })
                                    } else {
                                        setQueryParams({
                                            [selectableSearchOption]: selectableSearchOptionValue
                                        })
                                    }
                                    setIsLast(false)
                                    setSelectableList([])
                                    setCheckedSelectableList([])
                                    setPage(1);
                                    setLoading(true)
                                }}
                            />
                        </div>
                    </div>
                    <Table ref={scrollRef}>
                        <TableHeader>
                            <div><input type="checkbox"
                                checked={
                                    selectableList.length > 0 &&
                                    selectableList.length == checkedSelectableList.length}
                                onChange={
                                    (e) => {
                                        if (e.target.checked) {
                                            if (intervalId == null && !isLast) {
                                                setIntervalId(setInterval(() => {
                                                    setLoading(true)
                                                    setPage(prev => (prev + 1))
                                                }, 50))
                                            }
                                            setCheckedSelectableList(selectableList);
                                        } else {
                                            setCheckedSelectableList([]);
                                        }
                                    }
                                }
                            ></input></div>
                            {
                                locationName == 'user' ? <>
                                    <div>멤버십</div>
                                    <div>아이디</div>
                                    <div>이메일</div>
                                </>
                                    :
                                    <>
                                        <div>충전소 명</div>
                                        <div>SID</div>
                                    </>
                            }

                        </TableHeader>
                        {
                            selectableList == null ?
                                null
                                :
                                selectableList.map((element: any, idx: number) => {
                                    return (
                                        <TableRow key={idx} >
                                            <div><input type="checkbox"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCheckedSelectableList(prev => ([...prev, element]))
                                                    } else {
                                                        setCheckedSelectableList(prev => (
                                                            prev.filter((ele, index) => ele != element)

                                                        ))
                                                    }
                                                }}
                                                checked={checkedSelectableList.includes(element)}
                                            ></input></div>
                                            {
                                                locationName == 'user' ?
                                                    <>
                                                        <div>{(element as User).cardNo}</div>
                                                        <div>{(element as User).lgnId}</div>
                                                        <div>{(element as User).email}</div>
                                                    </> :
                                                    <>
                                                        <div>{element.nm}</div>
                                                        <div>{element.stationIdntfr}</div>
                                                    </>
                                            }
                                        </TableRow>
                                    )
                                })
                        }
                    </Table>
                </TableLayout>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <NextSvg onClick={() => {
                        if (checkedSelectableList.length === 0) {
                            addAlert('선택된 항목이 없습니다.')
                            return
                        }
                        setSelectedList(prev => ([...checkedSelectableList, ...prev]));
                        setSelectableList(prev => (
                            prev.filter((ele, index) => !checkedSelectableList.includes(ele))
                        ));
                        setCheckedSelectableList([]);
                    }} />
                    <br />
                    <BackSvg onClick={() => {
                        if (checkedSelectedList.length === 0) {
                            addAlert('선택된 항목이 없습니다.')
                            return
                        }
                        setSelectableList(prev => ([...checkedSelectedList, ...prev]));
                        setSelectedList(prev => (
                            prev.filter((ele, index) => !checkedSelectedList.includes(ele))
                        ));
                        setCheckedSelectedList([]);
                    }} />
                </div>
                <TableLayout>
                    <div>선택한 {locationKor} 리스트</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <select style={{ width: "30%" }}
                            onChange={(e) => {
                                setSelectedSearchOption(e.target.value)
                            }}
                        >
                            {
                                locationName == 'station' ?
                                    <>
                                        <option value={'구분'}>구분</option>
                                        <option value={'nm'}>충전소 명</option>
                                        <option value={'id'}>SID</option>
                                    </>
                                    :
                                    <>
                                        <option value={'구분'}>구분</option>
                                        <option value={'cardNo'}>멤버십</option>
                                        <option value={'lgnId'}>아이디</option>
                                        <option value={'email'}>이메일</option>
                                    </>

                            }
                        </select>
                        <div style={{ width: "69%" }}>
                            <EvInput
                                name={"name"}
                                placeHolder={`${locationKor} 검색하기`}
                                type={Type.TEXT}
                                onChanged={(e) => {
                                    setSelectedListSearch(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <Table>
                        <TableHeader>
                            <div><input type="checkbox"
                                checked={
                                    getFilterList().length > 0 &&
                                    getFilterList().length == checkedSelectedList.length
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCheckedSelectedList(getFilterList());
                                    } else {
                                        setCheckedSelectedList([]);
                                    }
                                }}
                            ></input></div>
                            {
                                locationName == 'user' ? <>
                                    <div>멤버십</div>
                                    <div>아이디</div>
                                    <div>이메일</div>
                                </>
                                    :
                                    <>
                                        <div>충전소 명</div>
                                        <div>SID</div>
                                    </>
                            }
                        </TableHeader>
                        {
                            selectedList == null ?
                                null
                                :
                                getFilterList().map((element: any, idx: number) => {
                                    return (
                                        <TableRow key={idx} >
                                            <div style={otherGroupUserIdList.includes(element.id) ? { backgroundColor: Color.red, color: 'white' } : {}} >
                                                <input type="checkbox"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCheckedSelectedList(prev => ([...prev, element]))
                                                        } else {
                                                            setCheckedSelectedList(prev => (
                                                                prev.filter((ele, index) => ele != element)

                                                            ))
                                                        }
                                                    }}
                                                    checked={checkedSelectedList.includes(element)}
                                                ></input></div>
                                            {
                                                locationName == 'user' ?
                                                    <>
                                                        <div style={otherGroupUserIdList.includes(element.id.toString()) ? { backgroundColor: Color.red, color: 'white' } : {}} >{(element as User).cardNo}</div>
                                                        <div style={otherGroupUserIdList.includes(element.id.toString()) ? { backgroundColor: Color.red, color: 'white' } : {}} >{(element as User).lgnId}</div>
                                                        <div style={otherGroupUserIdList.includes(element.id.toString()) ? { backgroundColor: Color.red, color: 'white' } : {}} >{(element as User).email}</div>
                                                    </> :
                                                    <>
                                                        <div>{element.nm}</div>
                                                        <div>{element.stationIdntfr}</div>
                                                    </>
                                            }
                                        </TableRow>
                                    )
                                })
                        }
                    </Table>
                </TableLayout>
            </TablesLayout>
        </MatchingDetailLayout>
        <div style={{ display: 'flex', justifyContent: "end" }}>
            {
                <>
                    <EvButton
                        text={"취소"}
                        fontSize={TextSize.normal}
                        color={Color.white}
                        fontColor={Color.black}
                        width="100px"
                        isOutline={true}
                        onPressed={(e) => {

                            showModal({
                                body: "페이지를 벗어나면 작성하신\n모든 내용이 사라집니다.",
                                action: '나가기',
                                onActionClick: () => {
                                    navigate(`/userGroup/${groupId}`);
                                }
                            })
                        }}
                    />
                    <div style={{ marginLeft: '12px' }}></div>
                    <EvButton
                        text={"저장"}
                        fontSize={TextSize.normal}
                        color={Color.primary}
                        width="100px"
                        fontColor={Color.white}
                        isOutline={true}
                        onPressed={(e) => {
                            if (selectedList.length === 0) {
                                addAlert('선택된 항목이 없습니다.')
                                return
                            }
                            showModal({
                                // body: `${locationKor}목록을 저장합니다.`,
                                body: `작성하신 내용으로 저장하시겠습니까?`,
                                action: '저장',
                                onActionClick: async () => {
                                    if (locationName == 'station') {
                                        setCreateGroup(prev => (
                                            {
                                                ...prev,
                                                stationList: selectedList,
                                                detail: false,
                                            }
                                        ));
                                    } else {

                                        let sendData = {}
                                        sendData = {
                                            memberList: selectedList.map((e) => e.id)
                                        }
                                        if (!isCreate) {
                                            sendData = {
                                                ...sendData,
                                                groupId: groupId
                                            }
                                        }
                                        var res = await memberGroupDuplicateCheck(sendData)

                                        if (res.data.length > 0) {
                                            addAlert('다른 그룹에 소속된 회원이 존재합니다.');
                                            var dupList = res.data.map((e: any) => e.toString())
                                            setOtherGroupUserIdList(dupList)
                                            setCheckedSelectedList(selectedList.filter((ele: any) => {
                                                return dupList.includes(ele.memberId.toString())
                                            }))
                                            return;
                                        }
                                        setCreateGroup(prev => (
                                            {
                                                ...prev,
                                                userList: selectedList,
                                                detail: false,
                                            }
                                        ));

                                    }
                                    navigate(`/userGroup/${groupId}`);
                                }
                            })
                        }}
                    />
                </>
                // :
                // <Link to={`/userGroup/${userId}`}>
                //     <EvButton
                //         text={"확인"}
                //         fontSize={TextSize.normal}
                //         color={Color.white}
                //         width="100px"
                //         fontColor={Color.black}
                //         isOutline={true} />
                // </Link>
            }
        </div>
    </>
}
const ButtonHeight = '43.5px';
const MatchingDetailLayout = styled.div`
    height: calc(100% - ${ButtonHeight}); 
    width: 100%;
    margin: 12px 0;
    padding: 12px;
    background-color: ${Color.white};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    input, select {
        margin: 8px 0;
        padding: 8px 8px;
        width: 100%;
        box-sizing: border-box;
        border : 1px solid ${Color.borderColor}
    }
`

const Table = styled.div`
margin-top: 8px;
overflow: scroll;

`

const TablesLayout = styled.div`
    display: flex;
    height: calc(100% - ${ButtonHeight}); 
    justify-content: space-between;
    align-items: center;
`

const TableLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;
    height: calc(100% - ${ButtonHeight}); 

    /* background-color: grey; */
`

const TableHeader = styled.div`
    position: sticky; 
    top: 0px; 
    z-index: 2;
    display: flex;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: ${Color.primary};
    *{
        flex:4;
        text-align: center;
        color : ${Color.white};
        border-right: 2px solid white;
        padding: 4px 0;
    }
    div:last-child{
        border-right:none;
        flex:4;
    }
    div:first-child{
        flex:1;
    }


`

const TableRow = styled.div`
    display: flex;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    font-size: 10px;
    *{
        flex:4;
        text-align: center;
        color : ${Color.black};
        border-right: 2px solid ${Color.backGroundGrey};
        border-bottom: 2px solid ${Color.backGroundGrey};
        padding: 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div:first-child{

        border-right:none;
        flex: 1;
    }

    div:last-child{
        border-right:none;
        flex:4;
    }
`
