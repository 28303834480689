class ResponseList {
    items: any;
    total: number;
    current_page: number;
    last_page: number;
    per_page: number;

    constructor(
        items: any,
        total: number,
        current_page: number,
        last_page: number,
        per_page: number
    ) {
        this.items = items;
        this.total = total;
        this.current_page = current_page;
        this.last_page = last_page;
        this.per_page = per_page;
    }

    static fromJson(json: JSON, converter: Function | null): ResponseList {
        var tempJson = Object.entries(json);
        // init data
        var current_page: number = 1;
        var last_page: number = 1;
        var total: number = 1;
        var per_page: number = 1;
        var items: Array<any> = [];
        for (const [key, value] of tempJson) {
            switch (key) {
                case 'current_page':
                    current_page = value;
                    break;
                case 'page':
                    current_page = value;
                    break;
                case 'last_page':
                    last_page = value;
                    break;
                case 'total':
                    total = value;
                    break;
                case 'pagingData':
                    total = value.totalItem;
                    per_page = value.size;
                    last_page = value.totalPage;
                    current_page = value.page;
                    break;
                case 'per_page':
                    per_page = value;
                    break;
                case 'items':
                    (value).forEach((value: any) => {
                        items.push(converter == null ? value : converter(value));
                    });
                    break;
                case 'data':
                    (value).forEach((value: any) => {
                        items.push(converter == null ? value : converter(value));
                    });
                    break;
                default:
                    break;
            }

        }
        return new ResponseList(items, total, current_page, last_page, per_page);
    }
}

export default ResponseList;