import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CouponTile from "../../components/couponTile";
import EvButton from "../../components/evButton";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inCouponsTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import Coupon, { CouponStatus } from "../../models/coupon";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { buildParams, getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getCoupons, getCouponsExcel } from "../../repository/couponRepo";
import { CloseCircle } from "../../utils/svg";
import { getCompanyCode, getLocaleDateString, getLocaleDateStringWithTZ, getRole } from "../../utils/utils";

function CouponsUsedPage() {
  const tabElement = EvTab(inCouponsTabDatas);
  const navigate = useNavigate();
  let isUseEffectHandle: boolean = false;
  //useEffect data
  type CouponQuery = {
    // published_s:string,
    // published_e:string,
    company_code?: string,
    membership_card_number?: string,
    used_start_dt?: string,
    used_end_dt?: string,
    page: number
  }
  const [queryData, setQueryData] = useState<CouponQuery>({
    company_code: '',
    membership_card_number: '',
    used_start_dt: '',
    used_end_dt: '',
    page: 1
  });
  function getSendQuery() {
    let sendData = queryData;
    if (getRole() !== 'Admin') {
      sendData.company_code = getCompanyCode()!;
    } else {

      if (sendData.company_code === '') {
        delete sendData.company_code;
      }
    }
    if (sendData.membership_card_number === '') {
      delete sendData.membership_card_number;
    }
    if (sendData.used_start_dt === '') {
      delete sendData.used_start_dt;
    }
    if (sendData.used_end_dt === '') {
      delete sendData.used_end_dt;
    }
    return sendData;
  }
  //search contents state
  type SearchContents = {
    // published_s:string,
    // published_e:string,
    company_code: string,
    membership_card_number: string,
    used_start_dt: string,
    used_end_dt: string,
  };
  const [totalPage, setTotalPage] = useState<number | null>(null);
  const [searchData, setSearchData] = useState<SearchContents>({
    company_code: '',
    membership_card_number: '',
    used_start_dt: '',
    used_end_dt: '',
  });
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  //select company data
  const [companies, setCompanies] = useState<Object>({});
  const handleSelect = (event: any) => {
    if (event.target.value !== null) {
      setSearchData({
        ...searchData,
        company_code: event.target.value
      });
    } else {
      setSearchData({
        ...searchData,
        company_code: ''
      });
    }
  }
  const handleSearchInput = (e: { target: { value: string; } }) => {

    setSearchData({
      ...searchData,
      membership_card_number: e.target.value.replaceAll(/[^0-9]/g, '')
    });
  }
  async function handleComapanies() {
    const result = await getCompanies();
    if (result.result === RESULT.FAIL) {
      alert('조회 실패');
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const companiesResult: Array<Company> = result.data;
      let companySelect: any = {
        "제휴사 전체": ''
      };
      for (var i = 0; i < companiesResult.length; i++) {
        companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
      }
      setCompanies(companySelect);
    }
  }
  //coupon list data
  const [coupons, setCoupons] = useState<Array<Coupon> | null>(null);
  async function handleCoupons() {
    setCoupons(null);
    const sendData = getSendQuery();
    const result = await getCoupons(sendData);
    if (result.result === RESULT.FAIL) {
      alert('쿠폰 목록조회 실패');
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const resultPageData: ResponseList = result.data;
      const resultData: Array<Coupon> = resultPageData.items;


      setCoupons(resultData);
      setTotalPage(resultPageData.last_page);
    }
  }

  //excel
  async function downloadExcel() {
    setLoading(true);
    const sendData = getExcelData();
    const result = await getCouponsExcel(sendData);
    if (result.result === RESULT.FAIL) {
      alert('쿠폰 엑셀 다운로드에 실패하였습니다.');
    } else {
      const aTag = window.document.createElement('a');

      const url = window.URL.createObjectURL(new Blob([result.data]));
      aTag.href = url;


      aTag.download = `Coupons_${getLocaleDateString(new Date().toString())}.xlsx`;
      aTag.target = 'blank';
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      window.URL.revokeObjectURL(url);
      alert('엑셀 다운로드 성공.');

    }
    setLoading(false);
  }
  function getExcelData() {
    let data: any = {};

    if (getRole() !== 'Admin') {
      data.company_code = getCompanyCode()!;
    } else {

      if (queryData.company_code !== '') {

        data.company_code = queryData.company_code;
      }
    }
    if (queryData.membership_card_number && queryData.membership_card_number !== '') {
      data.membership_card_number = queryData.membership_card_number;
    }
    if (queryData.used_start_dt && queryData.used_start_dt !== '') {
      data.used_start_dt = queryData.used_start_dt;
    }
    if (queryData.used_end_dt && queryData.used_end_dt !== '') {
      data.used_end_dt = queryData.used_end_dt;
    }
    data.status = CouponStatus.USED;
    return data;
  }

  //page
  const handlePage = async (e: any) => {


    const sendData = getSendQuery();
    sendData.page = Number.parseInt(e.target.value);


    navigate(getQueryString(sendData)!);
  }

  async function callHandle() {
    if (getRole() === 'Admin') {
      await handleComapanies();
    }
    await handleCoupons();
  }
  useEffect(() => {

    //query
    const params = new URLSearchParams(window.location.search);

    if (window.location.search === '') {
      isUseEffectHandle = true;
      return navigate(getQueryString({ page: 1 })!, { replace: true });


    } else if (
      (params.get('page') !== queryData.page.toString()) ||
      (params.get('company_code') !== queryData.company_code) ||
      (params.get('membership_card_number') !== queryData.membership_card_number) ||
      (params.get('used_start_dt') !== queryData.used_start_dt) ||
      (params.get('used_end_dt') !== queryData.used_end_dt)
    ) {
      isUseEffectHandle = true;
      setSearchData({
        company_code: params.get('company_code') ?? '',
        membership_card_number: params.get('membership_card_number') ?? '',
        used_start_dt: params.get('used_start_dt') ?? '',
        used_end_dt: params.get('used_end_dt') ?? ''
      });
      setSelectedStartDate(params.get('used_start_dt') !== null ? new Date(params.get('used_start_dt')!) : null);
      setSelectedEndDate(params.get('used_end_dt') !== null ? new Date(params.get('used_end_dt')!.replaceAll('Z', ' ').replaceAll('T', ' ')) : null);
      return setQueryData({
        ...queryData,
        company_code: params.get('company_code') ?? '',
        membership_card_number: params.get('membership_card_number') ?? '',
        used_start_dt: params.get('used_start_dt') ?? '',
        used_end_dt: params.get('used_end_dt') ?? '',
        page: Number.parseInt(params.get('page') ?? '1')
      });
    }
    // eslint-disable-next-line
    isUseEffectHandle = false;

  }, [window.location.search]);
  useEffect(() => {
    if (isUseEffectHandle) return;
    callHandle();

    // eslint-disable-next-line
  }, [queryData, window.location.search]);
  //excel을 위한 로딩
  const [isLoading, setLoading] = useState<boolean>(false);

  return EvLayOut(
    <ContentLayout >
      <DefaultContentsPadding>

        {tabElement}
        {
          coupons === null || isLoading
            ? <ElementContents>
              <EvLoading />
            </ElementContents>
            : <ElementContents>
              {/* search contens */}
              <SearchContents>
                <div style={{ marginRight: '5px', fontSize: TextSize.small }}>
                  사용일
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginRight: '3px ' }}>
                  <div style={{ margin: '5px 3px 0px 0px' }}>
                    {EvCalendar((value: any) => {
                      setSearchData({
                        ...searchData,
                        used_start_dt: getLocaleDateStringWithTZ(value, true)
                      });
                      setSelectedStartDate(value);
                    }, selectedStartDate)}
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    {EvCalendar((value: any) => {
                      setSearchData({
                        ...searchData,
                        used_end_dt: getLocaleDateStringWithTZ(value, false)
                      });
                      setSelectedEndDate(value);
                    }, selectedEndDate)}
                  </div>
                </div>
                <CloseCircle
                  style={{ padding: '3px 0px 0px 0px', cursor: 'pointer' }}
                  onClick={() => {
                    setSearchData({
                      ...searchData,
                      used_start_dt: '',
                      used_end_dt: ''
                    });
                    setSelectedStartDate(null);
                    setSelectedEndDate(null);
                  }}
                />
              </SearchContents>
              <SearchContentsSecond>
                {
                  getRole() === 'Admin'
                    ? EvSelectBox(companies, handleSelect, 1, searchData.company_code)
                    : null
                }
                <EvInput
                  flex={3}
                  name={"search_text"}
                  placeHolder={"회원카드 번호 검색"}
                  type={Type.TEXT}
                  onChanged={handleSearchInput}
                  value={searchData.membership_card_number}
                  onSubmit={() => {
                    const paramsData = {
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      used_start_dt: searchData.used_start_dt,
                      used_end_dt: searchData.used_end_dt,
                      page: 1
                    }
                    navigate(getQueryString(buildParams(paramsData))!)
                  }

                  }
                />
                <EvButton
                  margin="0px 0px 0px 5px"
                  padding="7px 5px 5px 5px"
                  text={'검색'}
                  width={'4rem'}
                  fontSize={TextSize.small}
                  color={Color.black}
                  fontWeight={TextBold.bold}
                  onPressed={() => {
                    const paramsData = {
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      used_start_dt: searchData.used_start_dt,
                      used_end_dt: searchData.used_end_dt,
                      page: 1
                    }
                    navigate(getQueryString(buildParams(paramsData))!)
                  }

                  }
                // fontColor={Color.black}

                />
                <EvButton
                  margin="0px 0px 0px 5px"
                  padding="7px 5px 5px 5px"
                  text={'엑셀'}
                  width={'3rem'}
                  fontSize={TextSize.small}
                  color={Color.green}
                  fontWeight={TextBold.bold}
                  onPressed={
                    () => {
                      // if (coupons.length === 0) {
                      //     alert('다운로드할 쿠폰이 존재하지 않습니다.');

                      // } else {
                      downloadExcel();
                      // }
                    }
                  }

                // fontColor={Color.black}

                />
              </SearchContentsSecond>

              <ListContents
                style={coupons.length === 0
                  ? {
                    backgroundColor: Color.white,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    color: Color.textSecondary
                  }
                  : {}}
              >
                {
                  coupons.length === 0
                    ? '검색된 쿠폰이 존재하지 않습니다.'
                    :
                    coupons.map((value) => {
                      return CouponTile(value);
                    })
                }
              </ListContents>
              {/* page contents need */}
              {Paging(queryData!.page,
                totalPage!,

                (e: any) => {
                  e.stopPropagation();
                  handlePage(e);

                })}
            </ElementContents>
        }
      </DefaultContentsPadding>
    </ContentLayout>
  );
}
const SearchContents = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:center;
   
    @media all and (max-width:429px){
        height:${SearchContentsHeight + 25}px;
    }
`;
const SearchContentsSecond = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    padding:5px 0px;
    align-items:center;
    input {
        height: calc(100% - 10px);
        min-width:85px;
    }
    select {
        height: calc(100% - 5px);
    }
    button {
        height: calc(100% - 5px);
    }
    
    
`;
const ListContents = styled.div`
    overflow-y:auto;
    height:calc(100% - ${PagingHeight + SearchContentsHeight + 40}px);
    width:100%;
    min-width:300px;
    border-radius:5px;
    // background-color:${Color.primary}
    @media all and (max-width:429px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 65}px);
    }
    
    
`;
export default CouponsUsedPage;