import styled from "styled-components"
import { Color, TextSize } from "../../constants";
import EvInput, { Type } from "../../components/evInput";
import EvButton from "../../components/evButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "../../components/tooltip";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { createGroupState, loadingModalState, otherGroupUserIdListState } from "../../recoil/atoms";
import { getCompanyCode, useAddAlert, useModal } from "../../utils/utils";
import { getGroupDetail, postGroup, putGroup } from "../../repository/groupRepo";
import { RESULT } from "../../models/response";

export const UserGroupDetail = () => {
    const location = useLocation();
    // staiton or user
    var groupId = location.pathname.split('/').at(location.pathname.split('/').length - 1);
    var isCreate = groupId == 'create';
    const [showPointInfo, setShowPointInfo] = useState(false);
    var onMouseUpToolTip = () => setShowPointInfo(true)
    var onMouseLeaveToolTip = () => setShowPointInfo(false)

    const [createGroup, setCreateGroup] = useRecoilState(createGroupState);
    const [otherGroupUserIdList, setOtherGroupUserIdList] = useRecoilState(otherGroupUserIdListState);
    const { showModal } = useModal();
    const { addAlert } = useAddAlert();
    const navigate = useNavigate();
    const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState)

    const [curUserGroup, setCurUserGroup] = useState<any>(null);

    const getGroupDetailAsync = async () => {
        if (groupId) {
            if (createGroup.detail) {
                setLoadingModal({ isOpen: true });
                var res = await getGroupDetail(groupId);
                setCurUserGroup(res.data);
                setCreateGroup({
                    ...res.data,
                    userList: res.data.memberList,
                    detail: true,
                });
                setLoadingModal({ isOpen: false });
            }
        }
    }
    useEffect(() => {
        if (!isCreate) {
            getGroupDetailAsync()
        }
    }, [])

    return <>
        <div>회원 그룹 정보 상세보기</div>
        <UserGroupDetailLayout>
            <div>
                <div>회원 그룹 명<span style={{ color: Color.red }}>*</span></div>
                <EvInput
                    name={"name"}
                    placeHolder={"회원 그룹 명"}
                    type={Type.TEXT}
                    onChanged={(e) => {
                        setCreateGroup(prev => ({
                            ...prev,
                            name: e.target.value,
                            detail: false,
                        }))
                    }}
                    value={createGroup.name}
                />
            </div>
            <div>
                <div style={{ display: 'flex' }}>회원 그룹 요금<span style={{ color: Color.red }}>*</span>
                    <QToolTipDiv onMouseEnter={onMouseUpToolTip} onMouseLeave={onMouseLeaveToolTip}>
                        ?
                        <Tooltip show={showPointInfo} width="210px" body={"기입한 단일 금액으로 충전소 요금이 책정됩니다.\n1원 이상의 숫자만 입력 가능"} top={'-75px'} ></Tooltip>
                    </QToolTipDiv>
                </div>
                <EvInput
                    name={"name"}
                    placeHolder={null}
                    type={Type.NUMBER}
                    onChanged={(e) => {
                        if (e.target.value <= 0) {
                            e.target.value = 1
                        }
                        setCreateGroup(prev => ({
                            ...prev,
                            benefitValue: e.target.value,
                            detail: false,
                        }))
                    }}
                    value={createGroup.benefitValue || ""}
                    min={1}
                />
            </div>
            <div>
                <div>그룹 내 매칭 회원 / 충전소 내역</div>
                <CountLayout>
                    <div>
                        <span style={{ marginRight: '20px' }}>•</span>
                        매칭 회원 : {createGroup.userList ? `${createGroup.userList.length}명` : '0명'}
                        <Link to={`/userGroup/${groupId}/matching/user`} >
                            <EvButton
                                text={"상세보기"}
                                padding="7px 5px 5px 5px"
                                width={"5em"}
                                fontSize={TextSize.small}
                                color={Color.white}
                                fontColor={Color.black}
                                isOutline={true}
                                margin={"0px 40px"}
                            />
                        </Link>
                    </div>
                    <div style={{
                        margin: "16px"
                    }}></div>
                    <div><span style={{ marginRight: '20px' }}>•</span>
                        매칭 충전소 : {createGroup.stationList ? `${createGroup.stationList.length}개` : '0개'}
                        <Link to={`/userGroup/${groupId}/matching/station`} >
                            <EvButton
                                text={"상세보기"}
                                padding="7px 5px 5px 5px"
                                width={"5em"}
                                fontSize={TextSize.small}
                                color={Color.white}
                                fontColor={Color.black}
                                isOutline={true}
                                margin={"0px 40px"}
                            />
                        </Link>
                    </div>
                </CountLayout>
            </div>
        </UserGroupDetailLayout>
        <div style={{ display: 'flex', justifyContent: "end" }}>
            <EvButton
                text={"취소"}
                fontSize={TextSize.normal}
                color={Color.white}
                fontColor={Color.black}
                width="100px"
                isOutline={true}
                onPressed={(e) => {
                    showModal({
                        body: "페이지를 벗어나면 작성하신\n모든 내용이 사라집니다.",
                        action: '나가기',
                        onActionClick: () => {
                            navigate(`/userGroup`);
                        }
                    })
                }}
            />
            {
                curUserGroup != null && curUserGroup!.delDt != null
                    ? null
                    :
                    <>
                        <div style={{ marginLeft: '12px' }}></div>
                        <EvButton
                            text={"저장"}
                            fontSize={TextSize.normal}
                            color={Color.primary}
                            fontColor={Color.white}
                            width="100px"
                            isOutline={true}
                            onPressed={(e) => {
                                if (createGroup.name == null || createGroup.name.length == 0) {
                                    addAlert('회원 그룹 명을 입력해주세요.')
                                    return;
                                }
                                if (createGroup.benefitValue == null || !createGroup.benefitValue) {
                                    addAlert('회원 그룹 요금을 입력해주세요.')
                                    return;
                                }
                                if (isCreate) {
                                    showModal({
                                        // body: "회원그룹을 생성합니다.",
                                        body: "작성하신 내용으로 저장하시겠습니까?",
                                        action: '저장',
                                        onActionClick: async () => {
                                            setLoadingModal({ isOpen: true });
                                            var res = await postGroup(
                                                {
                                                    cloudCode: getCompanyCode(),
                                                    name: createGroup.name,
                                                    benefitType: "FIX",
                                                    benefitValue: Number(createGroup.benefitValue),
                                                    mileageTy: "NONE",
                                                    memberList: createGroup.userList,
                                                    stationList: createGroup.stationList,
                                                }
                                            )
                                            if (res.result == RESULT.OK) {
                                                navigate(`/userGroup`);
                                                setOtherGroupUserIdList([]);
                                            } else {
                                                if (res.msg == '다른 그룹에 소속된 회원이 존재합니다.') {
                                                    addAlert(res.msg);
                                                    setOtherGroupUserIdList(JSON.parse(res.data).map((e: any) => e.toString()))
                                                }
                                            }
                                            setLoadingModal({ isOpen: false });
                                        }
                                    })
                                } else {
                                    showModal({
                                        body: "작성하신 내용으로 저장하시겠습니까?",
                                        action: '저장',
                                        onActionClick: async () => {
                                            setLoadingModal({ isOpen: true });
                                            var res = await putGroup(
                                                {
                                                    id: groupId ?? "",
                                                    data: {
                                                        cloudCode: getCompanyCode(),
                                                        name: createGroup.name,
                                                        benefitType: "FIX",
                                                        benefitValue: Number(createGroup.benefitValue),
                                                        mileageTy: "NONE",
                                                        memberList: createGroup.userList,
                                                        stationList: createGroup.stationList,
                                                    }
                                                }
                                            )
                                            if (res.result == RESULT.OK) {
                                                navigate(`/userGroup`);
                                                setOtherGroupUserIdList([]);
                                            } else {
                                                if (res.msg == '다른 그룹에 소속된 회원이 존재합니다.') {
                                                    addAlert(res.msg);
                                                    setOtherGroupUserIdList(JSON.parse(res.data).map((e: any) => e.toString()))
                                                }
                                            }
                                            setLoadingModal({ isOpen: false });
                                        }
                                    })
                                }
                            }}
                        />
                    </>
            }
        </div>
    </>
}

const ButtonHeight = '43.5px';

const UserGroupDetailLayout = styled.div`
    height: calc(100% - ${ButtonHeight}); 
    width: 100%;
    margin: 12px 0;
    padding: 12px;
    background-color: ${Color.white};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
    input {
        margin: 8px 0;
        padding: 8px 8px;
        width: 100%;
        box-sizing: border-box;
    }
`
const CountLayout = styled.div`
    margin: 12px 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    input {
        margin: 8px 0;
        padding: 16px 8px;
        width: 100%;
        box-sizing: border-box;
    }
    
`

const QToolTipDiv = styled.div`
position: relative;
right: -4px;
top: 4px;
font-size: 8px;
border: 1px solid;
border-radius: 100%;
width: 10px;
height: 10px;
display: flex;
align-items: center;
justify-content: center;
`;