import Coupon from "../models/coupon";
import { API, getResponseList, httpGet } from "./axios";

async function getCouponGroupKeys(queryData: any) {
    return await httpGet(API.COUPON, '/export', queryData, null);
}

async function getCoupons(queryData: any) {
    queryData.status = 'USED';

    const converter = (json: JSON) => Coupon.fromJson(json);
    return await getResponseList(API.COUPON, '', queryData, converter);
}

async function getCouponsExcel(queryData: any) {
    return await httpGet(API.COUPON, '/export', queryData, null);
}

export { getCouponGroupKeys, getCoupons, getCouponsExcel };