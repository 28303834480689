import MembershipList from "../models/memberShip";
import Response from "../models/response";
import { getCompanyCode } from "../utils/utils";
import { API, getResponseList, httpDelete, httpGet, httpPost, httpPut } from "./axios";

async function membershipCreateRepo(sendData: Object) {
    return await httpPost(API.MEMBERSHIP_CREATE, '', null, sendData, null);
}

async function getMembershipList(queryParams: any) {
    const converter = (json: JSON) => MembershipList.fromJson(json);
    return await getResponseList(API.MEMBERSHIP_LIST, '', queryParams, converter);
}


async function deleteMemberShip(id: string) {
    return await httpDelete(API.MEMBERSHIP_DELETE, id, { cloudCode: getCompanyCode() }, null);
}

async function putMemberShip(id: string) {
    const converter = (json: JSON) => MembershipList.fromJson(json);
    return await httpPut(API.MEMBERSHIP_UPDATE, id, { cloudCode: getCompanyCode() }, null, converter);
}

async function applyMemberShip(id: string) {
    const converter = (json: JSON) => MembershipList.fromJson(json);
    return await httpPut(API.MEMBERSHIP_IMMD_APPLY, id, null, { cloudCode: getCompanyCode() }, converter);
}


async function getMembershipDetail(membershipId: any) {
    const converter = (json: JSON) => MembershipList.fromJson(json);
    return await httpGet(API.MEMBERSHIP_DETAIL, `${membershipId}`, { cloudCode: getCompanyCode() }, converter);
}

async function memberGroupDuplicateCheck(sendData: Object) {
    return await httpPost(API.MEMBERSHIP_GROUP_DUPLICATE_MEMBER_CHECK, '', null, sendData, null);
}


export { membershipCreateRepo, putMemberShip, getMembershipList, getMembershipDetail, deleteMemberShip, applyMemberShip, memberGroupDuplicateCheck }