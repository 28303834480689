import React from "react";
import styled from "styled-components";
import { AppBarHeight, Color, MobileBreakWidth, TextSize } from "../../constants";
import { Close } from "../../utils/svg";

type DialogContents = {
    title: string,
    contents: JSX.Element,
    onClose?: () => void,
    hasClose?: boolean,
    width?: string,
}

function EvDialog({ title, contents, onClose = () => { }, hasClose = true, width }: DialogContents) {
    return (
        <React.Fragment>
            <DialogLayout>

                <DialogContentLayout style={{ width: width }} >
                    <CloseDialogContentLayout >
                        {title}
                        {hasClose
                            ? <Close onClick={onClose} style={{
                                display: 'flex',
                                alignItems: 'center'
                            }} />
                            : <div></div>}
                    </CloseDialogContentLayout>
                    {contents}
                </DialogContentLayout>
            </DialogLayout>
        </React.Fragment>
    );
}

const DialogLayout = styled.div`
    background-color: ${Color.dialogBackgroundColor};
    align-items: center;
    display:flex;
    justify-content: center;
    z-index: 999;
    position: absolute;
    width:calc(100vw - 18vw);
    @media all and (max-width:1260px){
        width:calc(100vw - 220px);

    }
    @media all and (max-width:${MobileBreakWidth}px){
        width:100vw;
    }
    height:calc(100vh - ${AppBarHeight}px);
    flex-direction:column;
    
`;
const DialogContentLayout = styled.div`
    padding: 10px;
    min-width:275px;
    
    // min-height:275px;
    background-color: ${Color.white};
    // opacity:1;
    border-radius:2%;
    display:flex;
    flex-direction:column;
    align-items:center;
    flex:auth;
`;
const CloseDialogContentLayout = styled.div`
display:flex;
margin-bottom:5px;
padding:5px 0px;
font-size:${TextSize.button};
justify-content:space-between;
width:100%;
border-bottom: 0.5px solid ${Color.borderColor};
`;

export default EvDialog;