import React from "react";
import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import { AdminRole, AdminStatus, getAdminStatusText } from "../models/adminUser";
import Company, { CompanyStatus, getCompanyStatusText } from "../models/company";
import { Key, KeyOff, Phone, Zip } from "../utils/svg";
import EvBadge from "./evBadge";
import EvButton from "./evButton";
import TileLayout from "./styled/tileLayout";

const CompanyTile = (
    company: Company,
    isExpand: boolean,
    selectedTile: number | null,
    onClick: React.MouseEventHandler<HTMLDivElement>,
    onCheck: React.MouseEventHandler<HTMLDivElement>,
    onEdit: React.MouseEventHandler<HTMLButtonElement>,
    onAdminClick: React.MouseEventHandler<HTMLButtonElement>,
    handleDeleteAdmin: React.MouseEventHandler<HTMLButtonElement>
) => {

    const element: JSX.Element[] = [];
    let hasActiveAdmin = false;
    if (company.admin != null) {

        for (var i = 0; i < company.admin!.length; i++) {
            if (company.admin[i].status === AdminStatus.ACTIVE) {
                hasActiveAdmin = true;
            }
            element.push(
                <AdminTile key={company.admin![i].id} style={company.admin!.length - 1 > i ? { borderBottom: `0.5px solid ${Color.borderColor}` } : {}}>
                    <AdminName>
                        {/*  / [status(badge)] / phone / svgKey(has APIKEY) */}
                        <div>
                            <EvBadge
                                text={getAdminStatusText(company.admin![i].status)}
                                textColor={company.admin![i].status === AdminStatus.ACTIVE ? Color.secondary : Color.red}
                                borderColor={company.admin![i].status === AdminStatus.ACTIVE ? Color.secondary : Color.red}
                            />
                            <EvBadge
                                text={company.admin[i].authority}
                                textColor={company.admin[i].authority === AdminRole.ADMIN ? Color.primary : Color.secondary}
                                borderColor={company.admin[i].authority === AdminRole.ADMIN ? Color.primary : Color.secondary}
                                marginTop={true}
                            />
                        </div>
                        <div>
                            {company.admin![i].name}
                            <div>
                                {company.admin![i].email}
                            </div>
                        </div>
                    </AdminName>

                    <WithSvg style={{ minWidth: '200px' }}>
                        <Phone style={{ margin: '0px 20px' }} />
                        {company.admin![i].phone === '' ? '-' : company.admin![i].phone}
                    </WithSvg>
                    <TailLayout>
                        {company.admin![i].api_key !== null && company.admin![i].api_key !== ''
                            ? <Key fill={Color.primary} />
                            : <KeyOff />
                        }
                        {company.admin[i].status === AdminStatus.INACTIVE
                            ? null
                            :
                            <div>
                                <EvButton
                                    margin={"0px 5px"}
                                    value={company.admin[i].id}
                                    text={"삭제"}
                                    padding="7px 5px 5px 5px"
                                    width={'3em'}
                                    fontSize={TextSize.small}
                                    color={Color.red}
                                    fontColor={Color.white}
                                    isOutline={false}
                                    onPressed={handleDeleteAdmin}
                                />
                                <EvButton
                                    //object로 전달시 인식되지않는다.
                                    value={JSON.stringify(company.admin[i])}

                                    text={"수정"}
                                    padding="7px 5px 5px 5px"
                                    width={'3em'}
                                    fontSize={TextSize.small}
                                    color={Color.white}
                                    fontColor={Color.black}
                                    isOutline={true}
                                    onPressed={onAdminClick}
                                />
                            </div>}
                    </TailLayout>
                </AdminTile>
            );
        }

        if (company.admin!.length > 0 && !hasActiveAdmin && company.status !== CompanyStatus.DELETE) {
            element.push(
                <AdminTile
                    key={'none'}
                    style={{
                        borderTop: `1px solid ${Color.borderColor}`,
                        marginTop: '10px',
                        padding: '10px 0px 0px 0px',

                    }}
                >
                활성화된 관리자가 존재하지 않습니다.
                <EvButton
                    value={JSON.stringify({ newAdmin: company.company_code })}
                    text={"추가"}
                    padding="7px 5px 5px 5px"
                    width={'3em'}
                    fontSize={TextSize.small}
                    color={Color.white}
                    fontColor={Color.black}
                    isOutline={true}
                    onPressed={onAdminClick}
                />
                </AdminTile>
            );
        }

    }

    return (
        <React.Fragment key={company.company_code}>
            <TileLayout
                onClick={onClick}
                style={{ cursor: 'pointer' }}
            >

                <CompanyName>
                    <input type={"checkbox"} readOnly onChange={undefined} onClick={company.status === CompanyStatus.DELETE ? (event: any) => event.stopPropagation() : onCheck} checked={selectedTile === company.id} />
                    <EvBadge
                        text={getCompanyStatusText(company.status)}
                        textColor={company.status === CompanyStatus.ACCEPT ? Color.secondary : Color.red}
                        borderColor={company.status === CompanyStatus.ACCEPT ? Color.secondary : Color.red}
                    />
                    {company.name}
                    <div>[{company.company_code}]</div>
                </CompanyName>
                <CompanyAddress>
                    <div>
                        {company.address}
                    </div>
                    <div>
                        (<Zip style={{ fill: Color.primary, marginRight: 5 }} />{company.zip_code}) {company.address_detail === null || company.address_detail.length === 0 ? '-' : company.address_detail}
                    </div>

                </CompanyAddress>
                <TailLayout>
                    <WithSvg>
                        <Phone style={{ margin: '0px 20px' }} />
                        {company.phone}
                    </WithSvg>


                    <EvButton

                        text={"수정"}
                        padding="7px 5px 5px 5px"
                        width={'3em'}
                        fontSize={TextSize.small}
                        color={Color.white}
                        fontColor={Color.black}
                        isOutline={true}
                        disable={company.status === CompanyStatus.DELETE}
                        onPressed={onEdit}
                    />

                </TailLayout>




            </TileLayout>

            {isExpand && <AdminTileLayout>
                {
                    (company.admin === null || company.admin?.length === 0)
                        ?
                        <AdminTile>

                            등록된 관리자가 존재하지 않습니다.
                            <EvButton
                                value={JSON.stringify({ newAdmin: company.company_code })}
                                text={"추가"}
                                padding="7px 5px 5px 5px"
                                width={'3em'}
                                fontSize={TextSize.small}
                                color={Color.white}
                                fontColor={Color.black}
                                isOutline={true}
                                disable={company.status === CompanyStatus.DELETE}
                                onPressed={onAdminClick}
                            />
                        </AdminTile>

                        : element

                }
            </AdminTileLayout>}

        </React.Fragment>
    );
}

const AdminTileLayout = styled.div`
    margin-left:10px;
    display:flex;
    overflow:auto;
    width:calc(100% - 30px);
    flex-direction:column;
    background-color:${Color.subTile};
    border-bottom:0.5px solid ${Color.borderColor};
    padding:5px 10px;
`;
const AdminTile = styled.div`
    width:100%;
    flex-wrap:wrap;
    // flex-direction:row;
    display:flex;
    justify-content:space-between;
    text-align:center;
    align-items:center;
    // border-bottom:0.5px solid ${Color.borderColor};
    padding:5px 0px;
`;

const AdminName = styled.div`
    font-size:${TextSize.normal};
    font-weight:${TextBold.bold};
    color:${Color.textFirst};
    display:flex;
    flex-direction:row;
    align-items: center;
    
    min-width:300px;
    flex-wrap:nowrap;
    div {
        display:flex;
        flex-direction:column;
        text-align: start;
    }
`;



const CompanyName = styled.div`
    font-size:${TextSize.normal};
    font-weight:${TextBold.bold};
    color:${Color.textFirst};
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-wrap:nowrap;
    min-width:300px;
    
    div {
        margin-left:3px;
        color:${Color.primary};
        font-size:${TextSize.small};
        font-weight:${TextBold.bold};

    }
`;
const CompanyAddress = styled.div`
    margin-top:5px;
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    
    div {
        display:flex;
        min-width:270px;
        max-width:350px;
        width:50vw;
        font-size:${TextSize.normal};
        font-weight:${TextBold.normal};
        color:${Color.textSecondary};
        text-align:start;
        align-items:end;
    }
`;


const TailLayout = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    flex: auto;
    justify-content: space-between;
    min-width:200px;
    text-align:start;
    align-items: center;
`;

const WithSvg = styled.div`
display:flex;
align-items:center;
`;




export default CompanyTile;