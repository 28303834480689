import { useRecoilState } from "recoil";
import { defaultNavSvgSize } from "../constants";
import { getSession, SessionKey } from "../repository/authRepo";
import { Cooperations, Coupon, Membership, Point, UserCard, UserGroup } from "../utils/svg";
import { Buffer } from "buffer";
import { alertState, fileInputModalState, FileValidator, modalState } from "../recoil/atoms";

function getLocaleDateString(date: string | Date): string {
  const localeDate = new Date(date);

  return `${localeDate.getFullYear()}-${getCheckWithZero((localeDate.getMonth() + 1).toString())}-${getCheckWithZero(
    localeDate.getDate().toString()
  )} ${getCheckWithZero(localeDate.getHours().toString())}:${getCheckWithZero(localeDate.getMinutes().toString())}:${getCheckWithZero(
    localeDate.getSeconds().toString()
  )}`;
}
function getLocaleDateStringWithTZ(date: string, isZeroTime?: boolean): string {
  let localeDate = new Date(date);
  if (isZeroTime) {
    localeDate = new Date(localeDate.setDate(localeDate.getDate() - 1));
    return `${localeDate.getFullYear()}-${getCheckWithZero((localeDate.getMonth() + 1).toString())}-${getCheckWithZero(
      localeDate.getDate().toString()
    )}T15:00:00Z`;
  }

  if (!isZeroTime) {
    return `${localeDate.getFullYear()}-${getCheckWithZero((localeDate.getMonth() + 1).toString())}-${getCheckWithZero(
      localeDate.getDate().toString()
    )}T14:59:59Z`;
  }
  return `${localeDate.getFullYear()}-${getCheckWithZero((localeDate.getMonth() + 1).toString())}-${getCheckWithZero(
    localeDate.getDate().toString()
  )}T${getCheckWithZero(localeDate.getHours().toString())}:${getCheckWithZero(localeDate.getMinutes().toString())}:${getCheckWithZero(
    localeDate.getSeconds().toString()
  )}Z`;
}

function getCheckWithZero(checkData: string) {
  if (checkData.length > 1) {
    return checkData;
  } else {
    return `0${checkData}`;
  }
}

function getKey(dataLength: number): string | null {
  const key: string | undefined = process.env.REACT_APP_KEY;
  if (key === undefined) {
    return null;
  } else {
    if (key.length >= dataLength) {
      return key;
    } else {
      const repeatNo = Math.ceil(dataLength / key.length);
      let returnKey = "";
      for (var i = 0; i < repeatNo; i++) {
        returnKey = `${returnKey}${key}`;
      }
      return returnKey;
    }
  }
}

function getCardNumberWithDash(text: string): string {
  let result = "";
  if (text.includes("-")) {
    text = text.replaceAll("-", "");
  }
  for (var i = 0; i < text.length; i = i + 4) {
    if (i + 4 < text.length) {
      result = `${result}${text.substring(i, i + 4)}-`;
    } else {
      result = `${result}${text.substring(i, text.length)}`;
    }
  }
  return result;
}

function enc(data: string): number[] {
  const key = getKey(data.length);
  var returnData: number[] = [];
  for (var i = 0; i < data.length; i++) {
    returnData.push(data.charCodeAt(i) - (key == null ? i : key?.charCodeAt(i)));
  }
  return returnData;
}

function dec(data: number[]): string {
  const key = getKey(data.length);
  var returnData: string = "";
  for (var i = 0; i < data.length; i++) {
    data[i] = data[i] + (key == null ? i : key?.charCodeAt(i));
    returnData = `${returnData}${String.fromCharCode(data[i])}`;
  }

  return returnData;
}

function toIntToDec(data: string): string {
  const encDataString: string[] = data.split(",");
  const convertEncData: number[] = [];
  for (var i = 0; i < encDataString.length; i++) {
    convertEncData.push(parseInt(encDataString[i]));
  }
  return dec(convertEncData);
}

function getSvgNav(key: string) {
  switch (key) {
    case "cooperations":
      return <Cooperations width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    case "userCards":
      return <UserCard width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    case "points":
      return <Point width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    case "coupons":
      return <Coupon width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    case "membershipManage":
      return <Membership width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    case "userGroup":
      return <UserGroup width={defaultNavSvgSize} height={defaultNavSvgSize} />;
    default:
      return null;
  }
}

function getRole(): string | null {
  const access = getSession(SessionKey.Access);
  if (access == null || access.split(".").length !== 3) return null;

  const payload = JSON.parse(Buffer.from(access.split(".")[1], "base64").toString());

  return payload["role"];
}
function getCompanyCode(): string | null {
  const access = getSession(SessionKey.Access);
  if (access == null || access.split(".").length !== 3) return null;
  const payload = JSON.parse(Buffer.from(access.split(".")[1], "base64").toString());

  return getRole() === "Admin" ? payload["adminCompanyCode"] : payload["companyCode"];
}

function toJsonObject(data: Object): JSON {
  const jsonString: string = JSON.stringify(data);
  const jsonData = JSON.parse(jsonString);
  if (jsonData) {
    for (const [key, value] of Object.entries(jsonData)) {
      if (value === "" || value === null) {
        //temp code

        delete jsonData[key];
      }
    }
  }

  return jsonData;
}

function getPriceString(price: number) {
  return [price].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function useAddAlert() {
  // 사용법
  // const {addAlert} = useAddAlert(); 선언 후
  // 
  // 아래 함수 호출하면 alert 뜸
  // addAlert("message")
  const [alert, setAlert] = useRecoilState(alertState);
  function addAlert(message: string) {
    message = `${message}:::${Date.now()}`
    if (!alert.alerts.includes(message)) {
      setAlert(prev => ({
        ...prev,
        alerts: Array.from((new Set([message, ...alert.alerts])))
      }));
      setTimeout(() => {
        setAlert(prev => ({
          ...prev,
          alerts: prev.alerts.filter((ms, index) => ms !== message)
        }));
      }, 5000)
    }

  }
  return { addAlert };
}



function useModal() {
  // 사용법
  // const {showModal} = useModal(); 선언 후
  // 
  // 아래 함수 호출하면 Modal 뜸
  // showModal(
  //   {body:'message',
  //   action:'action',
  //   onActionClick:()=>{}}
  // )
  const [modal, setModal] = useRecoilState(modalState);
  function showModal({ body, action, onActionClick }: { body: string, action: string, onActionClick: any }) {
    setModal({
      body: body,
      action: action,
      onActionClick: onActionClick,
      isOpen: true
    });
  }
  return { showModal };
}

function useFileInputModal() {
  // 사용법
  // const {showModal} = useFileInputModal(); 선언 후
  // 
  // 아래 함수 호출하면 Modal 뜸
  // showModal(
  //   {body:'message',
  //   action:'action',
  //   onActionClick:()=>{}}
  // )
  const [modal, setModal] = useRecoilState(fileInputModalState);
  function showFileInputModal(
    { fileType, extension, maxSize, format, fileVaildator = () => true, onClickSave, fileFormatDownloadUrl = "" }:
      { fileType: "이미지" | "엑셀", extension: Array<string>, maxSize: number, format: string, fileVaildator?: FileValidator, onClickSave: any, fileFormatDownloadUrl?: string }) {
    setModal({
      fileType: fileType,
      extension: extension,
      maxSize: maxSize,
      format: format,
      onClickSave: onClickSave,
      fileVaildator: fileVaildator,
      fileFormatDownloadUrl: fileFormatDownloadUrl,
      isOpen: true,
    });
  }
  return { showFileInputModal };
}



export {
  dec,
  enc,
  toIntToDec,
  getSvgNav,
  getRole,
  toJsonObject,
  getCompanyCode,
  getLocaleDateString,
  getLocaleDateStringWithTZ,
  getCardNumberWithDash,
  getPriceString,
  useAddAlert,
  useModal,
  useFileInputModal,
};
