import React from "react";
import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import MemberCard, { getMemberCardStatusText, MemberCardStatus } from "../models/memberCard";
import { getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";

const MemberCardTile = (memberCard: MemberCard, onCheck?: Function, checkedMemberCard?: MemberCard | null) => {
  return (
    <React.Fragment key={memberCard.membership_card_id}>
      <TileLayout>
        {/* 카드 상태 / 회사코드 / 회원카드 번호 / 남은 포인트/  등록일시  */}
        <MemberCardInfo>
          <div style={{ flex: 3 }}>
            {onCheck && checkedMemberCard !== undefined ? (
              <div>
                <input
                  type={"checkbox"}
                  readOnly
                  onClick={() => onCheck(memberCard)}
                  checked={checkedMemberCard?.membership_card_id === memberCard.membership_card_id}
                />
              </div>
            ) : null}
            <EvBadge
              text={getMemberCardStatusText(memberCard.status)}
              textColor={memberCard.status === MemberCardStatus.ACCEPT ? Color.secondary : Color.red}
              borderColor={memberCard.status === MemberCardStatus.ACCEPT ? Color.secondary : Color.red}
            />
            <div>{memberCard.company_code}</div>

            {memberCard.membership_card_number.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: TextSize.sLarge, fontWeight: TextBold.bold, color: Color.primary }}>P</div>
            <div>{memberCard.total_point ? getPriceString(memberCard.total_point) : 0}</div>
          </div>
        </MemberCardInfo>
        <MemberCardInfo style={{ justifyContent: "end" }}>{getLocaleDateString(memberCard.created_at)}</MemberCardInfo>
      </TileLayout>
    </React.Fragment>
  );
};
const MemberCardInfo = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  div {
    margin-right: 10px;
    display: flex;
    text-align: start;
    align-items: center;
  }
`;

export default MemberCardTile;
