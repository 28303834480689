import PointReserveRate from "../models/pointReserveRate";
import { API, getResponseList, httpDelete, httpPost, httpPut } from "./axios";

async function getPointReserveRates(pointReserveRateData: any) {
  const converter = (json: JSON) => PointReserveRate.fromJson(json);
  return await getResponseList(API.POINTRESERVERATE, "", pointReserveRateData, converter);
}
async function deletePointReserveRates(path: string) {
  return await httpDelete(API.POINTRESERVERATE, path, null, null);
}

async function updatePointReserveRates(path: string, pointReserve: Object) {
  return await httpPut(API.POINTRESERVERATE, path, null, pointReserve, null);
}

async function storePointreserveRate(storeData: object) {
  return await httpPost(API.POINTRESERVERATE, "", null, storeData, null);
}
export { getPointReserveRates, deletePointReserveRates, storePointreserveRate, updatePointReserveRates };
