import styled from "styled-components";

export const DefaultPointContentsPadding = styled.div`
padding: 25px 25px 25px 25px;
height: calc(100% - 85px);
@media all and (max-width:1000px){
    height:calc(100% - ${85 + 39}px);
}
@media all and (max-width:707px){
    height:calc(100% - ${85 + 39 + 39}px);
}
@media all and (max-width:650px){
    height:calc(100% - ${85 + 39}px);
}
@media all and (max-width:620px){
    height:calc(100% - ${85 + 39 + 39}px);
}
@media all and (max-width:487px){
    height:calc(100% - ${85 + 39 + 39 + 39}px);
}
`;