import React from "react";
import styled from "styled-components";
import { Color, TextBold } from "../constants";

type TitleText = {
    title: string;
}

const EvNavTitleTile = ({ title }: TitleText) => {
    return (
        <React.Fragment>
            <Tile>
                {title}
            </Tile>
        </React.Fragment>
    );
}

const Tile = styled.div`
    padding: 20px 10px 5px 10px;
    color: ${Color.secondary};
    font-weight: ${TextBold.heavy};
`;

export default EvNavTitleTile;

