import React from "react";
import styled from "styled-components";
import { Color, MobileBreakWidth, NavMenu, NonAdminNavMenu } from "../constants";
import { getRole } from "../utils/utils";
import EvNavMenuTile from "./evNavMenu";
import EvNavTitleTile from "./evNavTitleTile";

type EvNavData = {
    isShow: boolean | null;
}

const EvNav = ({ isShow }: EvNavData) => {
    var nav: JSX.Element | null = null;
    const titles: Array<string> = Object.keys(getRole() === 'Admin' ? NavMenu : NonAdminNavMenu);
    var navContents: Array<JSX.Element> = [];
    for (var index in titles) {
        navContents.push(<EvNavTitleTile key={titles[index]} title={titles[index]} />);
        let sustitles: Array<string> = Object.keys(NavMenu[titles[index]]);

        for (var subIndex in sustitles) {
            let svgText = NavMenu[titles[index]][sustitles[subIndex]];
            navContents.push(<EvNavMenuTile key={sustitles[subIndex]} menuText={sustitles[subIndex]} svgText={svgText} />)
        }
    }
    if (isShow == null) {
        nav = <Nav>
            {navContents}
        </Nav>;
    } else {
        nav = <NavMobile>
            {navContents}
        </NavMobile>;
    }
    return (
        <React.Fragment>
            {nav}
        </React.Fragment >
    );

}


const Nav = styled.nav`
    background-color:${Color.white};
    display:block;
    min-width: 220px;
    width: 18vw;
    height: calc(100vh - 60px);
    @media all and (max-width:${MobileBreakWidth}px){
        display:none;
    }
`;
const NavMobile = styled.nav`
    background-color:${Color.white};
    display:block;
    min-width: 220px;
    width: 18vw;
    height: calc(100vh - 60px);

`;


export default EvNav;