import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvLabel from "../../components/evLabel";

import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import MemberCardTile from "../../components/membercardTile";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultPointContentsPadding } from "../../components/styled/defaultPointContentsPadding";

import EvDialog from "../../components/styled/dialogLayout";
import ElementContents from "../../components/styled/elementContents";
import { Color, inPointTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import MemberCard from "../../models/memberCard";
import { getPointTypeText, PointType } from "../../models/point";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getMemberCards } from "../../repository/memberCardRepo";
import { addPoint, subPoint } from "../../repository/pointRepo";
import { getCompanyCode, getLocaleDateStringWithTZ, getRole } from "../../utils/utils";

function PointsManagePage() {
    const tabElement = EvTab(inPointTabDatas);
    const navigate = useNavigate();
    let isUseEffectHandle: boolean = false;

    // search query data
    type PointManageQuery = {
        company_code?: string,
        membership_card_number?: string,
        page: number
    }
    const [queryData, setQueryData] = useState<PointManageQuery>({
        company_code: '',
        membership_card_number: '',
        page: 1
    });
    function getSendQuery() {
        let sendData = queryData;
        if (getRole() !== 'Admin') {
            sendData.company_code = getCompanyCode()!;
        } else {

            if (sendData.company_code === '') {
                delete sendData.company_code;
            }
        }
        return sendData;
    }
    //search contents state
    type SearchContents = {
        company_code: string,
        membership_card_number: string
    };
    const [totalPage, setTotalPage] = useState<number | null>(null);
    const [searchData, setSearchData] = useState<SearchContents>({
        company_code: '',

        membership_card_number: ''
    });

    //select company data
    const [companies, setCompanies] = useState<Object>({});
    const handleSelect = (event: any) => {
        if (event.target.value !== null) {
            setSearchData({
                ...searchData,
                company_code: event.target.value
            });
        } else {
            setSearchData({
                ...searchData,
                company_code: ''
            });
        }
    }

    // search input
    const handleSearchInput = (e: { target: { value: string; } }) => {
        setSearchData({
            ...searchData,
            membership_card_number: e.target.value.replaceAll(/[^0-9]/g, '')
        });

    }
    async function handleComapanies() {
        const result = await getCompanies();
        if (result.result === RESULT.FAIL) {
            alert('조회 실패');
            navigate(Pages[PageName.Main].key, { replace: true });
        } else {
            const companiesResult: Array<Company> = result.data;
            let companySelect: any = {
                "제휴사 전체": ''
            };
            for (var i = 0; i < companiesResult.length; i++) {
                companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
            }
            setCompanies(companySelect);
        }
    }

    //memberCard list Data
    const [memberCards, setMemberCards] = useState<Array<MemberCard> | null>(null);
    async function handleMemberCards() {
        // check init
        setSelectMemberCard(null);
        setMemberCards(null);
        const sendData = getSendQuery();

        const result = await getMemberCards(sendData);
        if (result.result === RESULT.FAIL) {
            alert('회원카드 목록조회 실패');
            navigate(Pages[PageName.Main].key, { replace: true });
        } else {
            // success
            const resultPageData: ResponseList = result.data;
            const resultData: Array<MemberCard> = resultPageData.items;

            setMemberCards(resultData);

            setTotalPage(resultPageData.last_page);
        }
    }
    // check membercard
    const [selectMemberCard, setSelectMemberCard] = useState<MemberCard | null>(null);
    const onCheckMemberCard = (memberCard: MemberCard) => {
        if (selectMemberCard?.membership_card_id === memberCard.membership_card_id) {
            setSelectMemberCard(null);
        } else {
            setSelectMemberCard(memberCard);
        }
    }
    // add point data
    type AddPointData = {
        point: number,
        point_type: PointType,
        start_dt: string,
    };
    const [addPointData, setAddPointData] = useState<AddPointData>({
        point: 0,
        point_type: PointType.ADMINPLUS,
        start_dt: ''
    });
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [isAddPointDialog, setIsAddPointDialog] = useState<boolean>(false);
    // handle add dialog
    function handleAddPointDialog() {
        //init data
        setAddPointData({
            point: 0,
            point_type: PointType.ADMINPLUS,
            start_dt: ''
        });
        setSelectedStartDate(null);
        setIsAddPointDialog(!isAddPointDialog);
    }
    //check 
    function checkAdd(): boolean {
        if (
            addPointData.point === 0 || addPointData.point.toString() === '' ||
            addPointData.start_dt === ''
        ) {
            return true;
        }

        return false;
    }
    //onchange addpoint data handle
    const onChangeAddPointData = (event: { target: { name: string; value: string; } }) => {

        setAddPointData({
            ...addPointData,
            [event.target.name]: event.target.name === 'point'
                ? event.target.value === '' ? event.target.value : Number.parseInt(event.target.value)
                : event.target.value
        });
    }
    //add point
    async function adminAddPoint(company_code: string, membership_card_number: string) {
        const result = await addPoint(addPointData, company_code, membership_card_number);
        if (result.result === RESULT.FAIL) {
            alert(result.msg ?? '포인트 추가 실패');

        } else {
            alert('추가 되었습니다.');
            handleAddPointDialog();
        }
        await callHandle();
    }
    // minus point data
    type SubPointData = {
        point: number,
        point_type: PointType,
    };
    const [subPointData, setSubPointData] = useState<SubPointData>({
        point: 0,
        point_type: PointType.ADMINMINUS
    });
    const [isSubPointDialog, setIsSubPointDialog] = useState<boolean>(false);
    // handle sub dialog
    function handleSubPointDialog() {
        //init data
        setSubPointData({
            point: 0,
            point_type: PointType.ADMINMINUS
        });
        setIsSubPointDialog(!isSubPointDialog);
    }
    // check 
    function checkSub(): boolean {

        if (
            subPointData.point === 0 || subPointData.point.toString() === ''
        ) {
            return true;
        }

        return false;
    }
    //onchange subpoint data handle
    const onChangeSubPointData = (event: { target: { name: string; value: string; } }) => {
        setSubPointData({
            ...subPointData,
            [event.target.name]: event.target.name === 'point'
                ? event.target.value === '' ? event.target.value : Number.parseInt(event.target.value)
                : event.target.value
        });
    }
    //sub Point
    async function adminSubPoint(company_code: string, membership_card_number: string) {
        const result = await subPoint(subPointData, company_code, membership_card_number);
        if (result.result === RESULT.FAIL) {
            alert(result.msg ?? '포인트 차감 실패');

        } else {
            alert('차감 되었습니다.');
            handleSubPointDialog();
        }
        await callHandle();
    }
    async function callHandle() {

        if (getRole() === 'Admin') {
            await handleComapanies();
        }

        if (queryData.membership_card_number !== '' || queryData.company_code !== '') {
            await handleMemberCards();
        }
    }
    //query 
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (
            params.get('page') !== queryData.page.toString() ||
            params.get('company_code') !== queryData.company_code ||
            params.get('membership_card_number') !== queryData.membership_card_number
        ) {
            isUseEffectHandle = true;
            setSearchData({
                ...searchData,
                company_code: params.get('company_code') ?? '',
                membership_card_number: params.get('membership_card_number') ?? ''
            });
            return setQueryData({
                ...queryData,
                page: Number.parseInt(params.get('page') ?? '1'),
                company_code: params.get('company_code') ?? '',
                membership_card_number: params.get('membership_card_number') ?? ''
            });
        }
        // eslint-disable-next-line
        isUseEffectHandle = false;
    }, [window.location.search]);
    useEffect(() => {
        if (isUseEffectHandle) return;

        callHandle();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryData, window.location.search]);

    // page
    const handlePage = async (e: any) => {


        const sendData = getSendQuery();
        sendData.page = Number.parseInt(e.target.value);


        navigate(getQueryString(sendData)!);
    }

    return EvLayOut(
        <ContentLayout >
            <DefaultPointContentsPadding>

                {tabElement}
                {
                    memberCards === null && (queryData.company_code !== '' && queryData.membership_card_number !== '')

                        ? <ElementContents>
                            <EvLoading />
                        </ElementContents>
                        : <ElementContents>
                            <SearchContents>
                                {
                                    getRole() === 'Admin'
                                        ? EvSelectBox(companies, handleSelect, 1, searchData.company_code)
                                        : null
                                }
                                <EvInput
                                    flex={3}
                                    name={"search_text"}
                                    placeHolder={"회원카드 번호 검색"}
                                    type={Type.TEXT}
                                    onChanged={handleSearchInput}
                                    value={searchData.membership_card_number}
                                    onSubmit={() => navigate(getQueryString({
                                        company_code: searchData.company_code,
                                        membership_card_number: searchData.membership_card_number,
                                        page: 1
                                    })!)}
                                />
                                <EvButton
                                    margin="0px 0px 0px 5px"
                                    padding="7px 5px 5px 5px"
                                    text={'검색'}
                                    width={'5rem'}
                                    fontSize={TextSize.small}
                                    color={Color.black}
                                    fontWeight={TextBold.bold}
                                    onPressed={() => navigate(getQueryString({
                                        company_code: searchData.company_code,
                                        membership_card_number: searchData.membership_card_number,
                                        page: 1
                                    })!)

                                    }

                                />
                            </SearchContents>
                            <SearchContents style={{ height: '30px', padding: '0px 0px 10px 0px ' }}>
                                <EvButton
                                    padding="7px 5px 5px 5px"
                                    text={'포인트 적립'}
                                    width={'5rem'}
                                    fontSize={TextSize.small}
                                    color={Color.secondary}
                                    fontWeight={TextBold.bold}
                                    onPressed={() => {
                                        if (selectMemberCard === null) {
                                            alert('적립을 진행할 멤버십 카드를 선택하세요.');

                                        } else {
                                            handleAddPointDialog();
                                        }
                                    }}
                                // onpress add dialog
                                />
                                <EvButton
                                    margin="0px 0px 0px 5px"
                                    padding="7px 5px 5px 5px"
                                    text={'포인트 차감'}
                                    width={'5rem'}
                                    fontSize={TextSize.small}
                                    color={Color.red}
                                    fontWeight={TextBold.bold}
                                    onPressed={() => {
                                        if (selectMemberCard === null) {
                                            alert('차감을 진행할 멤버십 카드를 선택하세요.');

                                        } else {
                                            handleSubPointDialog();
                                        }
                                    }}
                                // onpress minus dialog
                                />
                            </SearchContents>
                            <ListContents
                                style={memberCards === null || memberCards.length === 0
                                    ? {
                                        backgroundColor: Color.white,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: Color.textSecondary
                                    }
                                    : {}}
                            >
                                {
                                    memberCards === null
                                        ? '회원 카드를 검색해주세요.'
                                        : memberCards.length === 0
                                            ? '검색된 회원카드가 존재하지 않습니다.'
                                            : memberCards.map((value, index) => {
                                                return MemberCardTile(value, onCheckMemberCard, selectMemberCard)
                                            })
                                }
                            </ListContents>
                            {/* page contents need */}
                            {Paging(queryData!.page,
                                totalPage!,

                                (e: any) => {
                                    e.stopPropagation();
                                    handlePage(e);

                                })}
                        </ElementContents>
                }
            </DefaultPointContentsPadding>
            {/* add point */}
            {
                isAddPointDialog &&
                <EvDialog
                    title="포인트 추가"
                    contents={
                        <DetailDialogLayout>
                            {/* point / type / date */}

                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="적립 포인트"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={'point'}
                                    placeHolder={null}
                                    type={Type.NUMBER}
                                    onChanged={onChangeAddPointData}
                                    value={addPointData.point}
                                />
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="포인트 구분"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={'point'}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={null}
                                    readonly={true}
                                    value={getPointTypeText(addPointData.point_type)}
                                />
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="시작일"
                                    isNeccessary={true}
                                />
                                {EvCalendar(
                                    (value: any) => {
                                        // console.log(value);
                                        // if (value >= new Date()) {
                                        //     alert('시작일은 현재일자보다 이전의 날짜만 선택 가능합니다.');
                                        // } else {
                                        //     setAddPointData({
                                        //         ...addPointData,
                                        //         start_dt: getLocaleDateStringWithTZ(value, true)
                                        //     });
                                        //     setSelectedStartDate(value);
                                        // }
                                        setAddPointData({
                                            ...addPointData,
                                            start_dt: getLocaleDateStringWithTZ(value, true)
                                        });
                                        setSelectedStartDate(value);
                                    },
                                    selectedStartDate,

                                )}
                            </DetailDialogContentsDiv>
                            <EvButton
                                text={"포인트 적립"}
                                padding="7px 5px 5px 5px"
                                margin="15px 0px 0px 0px"
                                color={Color.primary
                                }
                                disable={checkAdd()}

                                width={"100%"}
                                onPressed={() => adminAddPoint(selectMemberCard?.company_code!, selectMemberCard?.membership_card_number!)}
                            />
                        </DetailDialogLayout>
                    }
                    onClose={handleAddPointDialog}
                />
            }
            {/* sub point */}
            {
                isSubPointDialog &&
                <EvDialog
                    title="포인트 차감"
                    contents={
                        <DetailDialogLayout>
                            {/* point / type / date */}

                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="차감 포인트"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={'point'}
                                    placeHolder={null}
                                    type={Type.NUMBER}
                                    onChanged={onChangeSubPointData}
                                    value={subPointData.point}
                                />
                            </DetailDialogContentsDiv>
                            <DetailDialogContentsDiv>
                                <EvLabel
                                    text="포인트 구분"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={'point'}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={null}
                                    readonly={true}
                                    value={getPointTypeText(subPointData.point_type)}
                                />
                            </DetailDialogContentsDiv>

                            <EvButton
                                text={"포인트 차감"}
                                padding="7px 5px 5px 5px"
                                margin="15px 0px 0px 0px"
                                color={Color.primary
                                }
                                disable={checkSub()}

                                width={"100%"}
                                onPressed={() => adminSubPoint(selectMemberCard?.company_code!, selectMemberCard?.membership_card_number!)}
                            />
                        </DetailDialogLayout>
                    }
                    onClose={handleSubPointDialog}
                />
            }
        </ContentLayout>
    );
}
const SearchContents = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:no-wrap;
    padding:10px 0px;
`;
const ListContents = styled.div`
    overflow-y:auto;
    height:calc(100% - ${PagingHeight + SearchContentsHeight + 40}px);
    width:100%;
    min-width:300px;
    border-radius:5px;
   
    @media all and (max-width:1000px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 40}px);
    }
    @media all and (max-width:707px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 40}px);
    }
    @media all and (max-width:650px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 40}px);
    }
    @media all and (max-width:620px){
        height:calc(100% - ${PagingHeight + SearchContentsHeight + 5}px);
    }
    
`;

const DetailDialogLayout = styled.div`
    display: flex;
    flex-direction: column; 
    min-width: 300px;
    width:100%;
`;
const DetailDialogContentsDiv = styled.div`
    padding:5px 0px;
    display: flex; 
    flex-direction: row;
    align-items:center;
    flex-wrap: nowrap;
    height:100%;
    margin-bottom:5px;
    
    input {
        height:25px;
        flex:3;
    }
    div{
        align-items:center;
    }
`;
export default PointsManagePage;