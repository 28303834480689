import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import Paging from "../../components/paging";
import PointUsedTile from "../../components/pointUsedTile";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultPointContentsPadding } from "../../components/styled/defaultPointContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inPointTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import { PointHistory, PointType } from "../../models/point";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { buildParams, getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getPointsExcel, getPointUsedHistory } from "../../repository/pointRepo";
import { CloseCircle } from "../../utils/svg";
import { getCompanyCode, getLocaleDateString, getLocaleDateStringWithTZ, getRole } from "../../utils/utils";

function PointsUsedPage() {

  const tabElement = EvTab(inPointTabDatas);
  const navigate = useNavigate();
  let isUseEffectHandle: boolean = false;

  // search query data
  type PointUsedQuery = {
    company_code?: string,
    membership_card_number?: string,
    paid_dts?: string,
    paid_dte?: string,
    page: number
  }
  const [queryData, setQueryData] = useState<PointUsedQuery>({
    company_code: '',
    membership_card_number: '',
    paid_dts: '',
    paid_dte: '',
    page: 1
  });
  function getSendQuery() {
    let sendData = queryData;
    if (getRole() !== 'Admin') {
      sendData.company_code = getCompanyCode()!;
    } else {

      if (sendData.company_code === '') {
        delete sendData.company_code;
      }
    }
    if (sendData.membership_card_number === '') {
      delete sendData.membership_card_number;
    }
    if (sendData.paid_dts === '') {
      delete sendData.paid_dts;
    }
    if (sendData.paid_dte === '') {
      delete sendData.paid_dte;
    }
    return sendData;
  }
  //search contents state
  type SearchContents = {
    company_code: string,
    membership_card_number: string,
    paid_dts: string,
    paid_dte: string
  };
  const [totalPage, setTotalPage] = useState<number | null>(null);
  const [searchData, setSearchData] = useState<SearchContents>({
    company_code: '',
    membership_card_number: '',
    paid_dts: '',
    paid_dte: ''
  });
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  //select company data
  const [companies, setCompanies] = useState<Object>({});
  const handleSelect = (event: any) => {
    if (event.target.value !== null) {
      setSearchData({
        ...searchData,
        company_code: event.target.value
      });
    } else {
      setSearchData({
        ...searchData,
        company_code: ''
      });
    }
  }
  async function handleComapanies() {
    const result = await getCompanies();
    if (result.result === RESULT.FAIL) {
      alert('조회 실패');
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const companiesResult: Array<Company> = result.data;
      let companySelect: any = {
        "제휴사 전체": ''
      };
      for (var i = 0; i < companiesResult.length; i++) {
        companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
      }
      setCompanies(companySelect);
    }
  }
  // search input
  const handleSearchInput = (e: { target: { value: string; } }) => {
    setSearchData({
      ...searchData,
      membership_card_number: e.target.value.replaceAll(/[^0-9]/g, '')
    });

  }
  // pointhistory data
  const [points, setPoints] = useState<Array<PointHistory> | null>(null);
  async function handlePointHistory() {
    setPoints(null);
    const sendData = getSendQuery();
    const result = await getPointUsedHistory(sendData);
    if (result.result === RESULT.FAIL) {
      alert(result.msg ?? '포인트 적립내역 조회 실패');
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const resultPageData: ResponseList = result.data;
      const resultData: Array<PointHistory> = resultPageData.items;
      setPoints(resultData);

      setTotalPage(resultPageData.last_page);
    }
  }
  async function callHandle() {

    if (getRole() === 'Admin') {
      await handleComapanies();
    }
    await handlePointHistory();

  }

  //query
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (window.location.search === '') {
      isUseEffectHandle = true;
      return navigate(getQueryString({ page: 1 })!, { replace: true });


    } else if (
      params.get('page') !== queryData.page.toString() ||
      params.get('company_code') !== queryData.company_code ||
      params.get('membership_card_number') !== queryData.membership_card_number ||
      (params.get('paid_dts') !== queryData.paid_dts) ||
      (params.get('paid_dte') !== queryData.paid_dte)
    ) {
      isUseEffectHandle = true;
      setSearchData({
        ...searchData,
        company_code: params.get('company_code') ?? '',
        membership_card_number: params.get('membership_card_number') ?? '',
        paid_dts: params.get('paid_dts') ?? '',
        paid_dte: params.get('paid_dte') ?? ''
      });
      setSelectedStartDate(params.get('paid_dts') !== null ? new Date(params.get('paid_dts')!) : null);
      setSelectedEndDate(params.get('paid_dte') !== null ? new Date(params.get('paid_dte')!.replaceAll('Z', ' ').replaceAll('T', ' ')) : null);
      return setQueryData({
        ...queryData,
        page: Number.parseInt(params.get('page') ?? '1'),
        company_code: params.get('company_code') ?? '',
        membership_card_number: params.get('membership_card_number') ?? '',
        paid_dts: params.get('paid_dts') ?? '',
        paid_dte: params.get('paid_dte') ?? ''
      });
    }
    // eslint-disable-next-line
    isUseEffectHandle = false;
  }, [window.location.search]);

  useEffect(() => {
    if (isUseEffectHandle) return;
    callHandle();

    // eslint-disable-next-line
  }, [queryData, window.location.search]);

  // page
  const handlePage = async (e: any) => {


    const sendData = getSendQuery();
    sendData.page = Number.parseInt(e.target.value);


    navigate(getQueryString(sendData)!);
  }
  //excel을 위한 로딩
  const [isLoading, setLoading] = useState<boolean>(false);
  function getExcelData() {
    let data: any = {};

    if (getRole() !== 'Admin') {
      data.company_code = getCompanyCode()!;
    } else {

      if (queryData.company_code !== '') {

        data.company_code = queryData.company_code;
      }
    }
    if (queryData.membership_card_number && queryData.membership_card_number !== '') {
      data.membership_card_number = queryData.membership_card_number;
    }
    if (queryData.paid_dts && queryData.paid_dts !== '') {
      data.paid_dts = queryData.paid_dts;
    }
    if (queryData.paid_dte && queryData.paid_dte !== '') {
      data.paid_dte = queryData.paid_dte;
    }
    data.point_type = `${PointType.USE}`;
    return data;
  }
  //excel
  async function downloadExcel() {
    setLoading(true);
    let sendData = getExcelData();


    const result = await getPointsExcel(sendData);
    if (result.result === RESULT.FAIL) {
      alert('포인트 적립내역 엑셀 다운로드에 실패하였습니다.');
    } else {
      const aTag = window.document.createElement('a');

      const url = window.URL.createObjectURL(new Blob([result.data]));
      aTag.href = url;


      aTag.download = `PointUsed_${getLocaleDateString(new Date().toString())}.xlsx`;
      aTag.target = 'blank';
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      window.URL.revokeObjectURL(url);
      alert('엑셀 다운로드 성공.');

    }
    setLoading(false);
  }
  return EvLayOut(
    <ContentLayout >
      <DefaultPointContentsPadding>

        {tabElement}
        {
          points === null || isLoading
            ? <ElementContents><EvLoading /></ElementContents>
            : <ElementContents>
              <SearchContents>
                <div style={{ marginRight: '5px ', fontSize: TextSize.small }}>
                  사용일
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginRight: '3px ' }}>
                  <div style={{ margin: '5px 3px 0px 0px' }}>
                    {EvCalendar((value: any) => {

                      setSearchData({
                        ...searchData,
                        paid_dts: getLocaleDateStringWithTZ(value, true)
                      });
                      setSelectedStartDate(value);
                    }, selectedStartDate)}
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    {EvCalendar((value: any) => {
                      setSearchData({
                        ...searchData,
                        paid_dte: getLocaleDateStringWithTZ(value, false)
                      });
                      setSelectedEndDate(value);
                    }, selectedEndDate)}
                  </div>

                </div>
                <CloseCircle
                  style={{ padding: '3px 0px 0px 0px', cursor: 'pointer' }}
                  onClick={() => {
                    setSearchData({
                      ...searchData,
                      paid_dts: '',
                      paid_dte: ''
                    });
                    setSelectedStartDate(null);
                    setSelectedEndDate(null);
                  }}
                />
              </SearchContents>
              <SearchContentsSecond>
                {
                  getRole() === 'Admin'
                    ? EvSelectBox(companies, handleSelect, 1, searchData.company_code)
                    : null
                }
                <EvInput
                  flex={3}
                  name={"search_text"}
                  placeHolder={"회원카드 번호 검색"}
                  type={Type.TEXT}
                  onChanged={handleSearchInput}
                  value={searchData.membership_card_number}
                  onSubmit={() => {
                    const paramsData = {
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      paid_dts: searchData.paid_dts,
                      paid_dte: searchData.paid_dte,
                      page: 1
                    }
                    navigate(getQueryString(buildParams(paramsData))!)
                  }
                  }
                />
                <EvButton
                  margin="0px 0px 0px 5px"
                  padding="7px 5px 5px 5px"
                  text={'검색'}
                  width={'4rem'}
                  fontSize={TextSize.small}
                  color={Color.black}
                  fontWeight={TextBold.bold}
                  onPressed={() => {
                    const paramsData = {
                      company_code: searchData.company_code,
                      membership_card_number: searchData.membership_card_number,
                      paid_dts: searchData.paid_dts,
                      paid_dte: searchData.paid_dte,
                      page: 1
                    }
                    navigate(getQueryString(buildParams(paramsData))!)
                  }
                  }
                // fontColor={Color.black}

                />
                <EvButton
                  margin="0px 0px 0px 5px"
                  padding="7px 5px 5px 5px"
                  text={'엑셀'}
                  width={'3rem'}
                  fontSize={TextSize.small}
                  color={Color.green}
                  fontWeight={TextBold.bold}
                  onPressed={
                    () => {
                      downloadExcel();

                    }
                  }

                // fontColor={Color.black}

                />
              </SearchContentsSecond>
              <ListContents
                style={points.length === 0
                  ? {
                    backgroundColor: Color.white,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    color: Color.textSecondary
                  }
                  : {}}
              >
                {
                  points.length === 0
                    ? '검색된 포인트 사용내역이 존재하지 않습니다.'
                    : points.map((value) => {
                      return PointUsedTile(
                        value

                      );
                    })
                }
              </ListContents>
              {/* page contents need */}
              {Paging(queryData!.page,
                totalPage!,

                (e: any) => {
                  e.stopPropagation();
                  handlePage(e);

                })}
            </ElementContents>
        }
      </DefaultPointContentsPadding>
    </ContentLayout>
  );
}
const SearchContents = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    padding:5px 0px;
    align-items:center;
    
`;
const SearchContentsSecond = styled.div`
    width:100%;
    height:${SearchContentsHeight}px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5px 0px;
    align-items:center;
    input {
        height: calc(100% - 10px);
        min-width:70px;
    }
    select {
        height: calc(100% - 5px);
    }
    button {
        height: calc(100% - 5px);
    }
`;
const ListContents = styled.div`
    overflow-y:auto;
    height:calc(100% - ${PagingHeight + (SearchContentsHeight * 2)}px);
    width:100%;
    min-width:300px;
    border-radius:5px;
    
    @media all and (max-width:1000px){
        height:calc(100% - ${PagingHeight + (SearchContentsHeight * 2)}px);
    }
    @media all and (max-width:620px){
        height:calc(100% - ${PagingHeight + (SearchContentsHeight * 2) - 39}px);
    }
   
   
`;
export default PointsUsedPage;