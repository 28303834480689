import React from "react";
import styled from "styled-components";
import { TextSize, TextBold, Color } from "../constants";
import PointPurchase from "../models/pointPurchase";
import { getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";

const PointPurchaseTile = (point: PointPurchase, checkList: Array<number>, onCheck: React.MouseEventHandler<HTMLDivElement>) => {
  return (
    <React.Fragment key={point.row_number}>
      <TileLayout>
        <PointPurchaseLayout style={{ flex: "3" }}>
          <div>
            <input type={"checkbox"} readOnly onClick={onCheck} checked={checkList.includes(point.row_number)} />
          </div>
          <EvBadge
            text={point.canceled_at ? "취소" : "승인"}
            textColor={point.canceled_at ? Color.red : Color.secondary}
            borderColor={point.canceled_at ? Color.red : Color.secondary}
          />
          <div>{point.company_code}</div>

          {point.membership_card_number}
        </PointPurchaseLayout>
        <PointPurchaseLayout>
          <div style={{ minWidth: "110px" }}>
            <div style={{ fontSize: TextSize.sLarge, fontWeight: TextBold.bold, color: Color.primary }}>P</div>
            <div>{getPriceString(point.purchase_price)}</div>
          </div>
          <div>
            <div style={{ fontSize: TextSize.sLarge, fontWeight: TextBold.bold, color: Color.secondary }}>R</div>

            <div>{point.reward_price ? getPriceString(point.reward_price) : 0}</div>
          </div>
        </PointPurchaseLayout>
        <PointPurchaseLayout style={{ flex: "5", justifyContent: "end" }}>
          <div style={{ color: Color.secondary, width: "10rem" }}>{getLocaleDateString(point.created_at)}</div>
          <div style={{ color: Color.primary, width: "10rem" }}>{getLocaleDateString(point.expire_dt)}</div>
          <div style={{ color: Color.red, width: "10rem" }}>{point.canceled_at ? getLocaleDateString(point.canceled_at) : ""}</div>
        </PointPurchaseLayout>
      </TileLayout>
    </React.Fragment>
  );
};
const PointPurchaseLayout = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 30px;
  div {
    margin-right: 10px;
    display: flex;
    text-align: start;
    align-items: center;
  }
`;
export default PointPurchaseTile;
