import CouponGroup from "../models/couponGroup";
import { API, getResponseList, httpGet, httpPost } from "./axios";

async function getCouponGroups(couponGroupData: any) {
    const converter = (json: JSON) => CouponGroup.fromJson(json);
    return await getResponseList(API.COUPONGROUP, '', couponGroupData, converter);
}


async function publishedCouponGroup(couponGroupId: number, code?: string) {
    return await httpPost(API.COUPONGROUP, `/${couponGroupId}/publish`, null, code ? { code: code } : null, null);
}

async function getExcelCouponGroups(sendData: Object) {
    return await httpGet(API.COUPONGROUP, '/export', sendData, null);
}

async function addCouponGroup(sendData: Object) {
    return await httpPost(API.COUPONGROUP, '', null, sendData, null);
}

export { getCouponGroups, publishedCouponGroup, getExcelCouponGroups, addCouponGroup }