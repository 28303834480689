import styled from "styled-components";
import { Color } from "../../constants";

const TileLayout = styled.div`
    overflow:auto;
    padding:10px 10px;
    background-color:${Color.white};
    // border-radius:5px;
    border-bottom:0.5px solid ${Color.borderColor};
    display:flex;
    flex-wrap:wrap;
    text-align:center;
    align-items:center;
`;
export default TileLayout;