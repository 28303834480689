
import { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CompanyTile from "../../components/companyTile";
import EvButton from "../../components/evButton";
import EvInput, { Type } from "../../components/evInput";
import EvLabel from "../../components/evLabel";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import { ContentLayout } from "../../components/styled/contentLayout";
import ContentsEndBox from "../../components/styled/contentsEndBox";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import EvDialog from "../../components/styled/dialogLayout";
import ElementContents from "../../components/styled/elementContents";
import { Color, EmailRegExp, inCooperationTabDatas, PageName, Pages, passwordRegExp, TextSize } from "../../constants";
import AdminUser, { AdminRole } from "../../models/adminUser";
import Company from "../../models/company";
import { RESULT } from "../../models/response";
import { changePassword, deleteAdmin, getAdmin, reIssueAdminApiKey, storeAdmin, updateAdmin } from "../../repository/adminUserRepo";
import { getAuthData, removeAuth } from "../../repository/authRepo";
import { deleteCompany, getCompanies, getExcelCompany, storeCompany, updateCompany } from "../../repository/companyRepo";
import { getLocaleDateString, getRole } from "../../utils/utils";


function CooperationsPage() {
    type AddCooperationForm = {
        company_code: string,
        name: string,
        phone: string,
        address: string,
        address_detail: string,
        zip_code: string
    };

    type AdminDataForm = {
        login_id: string,
        password: string,
        name: string,
        phone: string,
        email: string,
        authority: AdminRole,
        company_code: string,
        api_key: string
    };

    type PasswordChangeForm = {
        new: string,
        confirm: string
    };
    const roles = { '관리자': AdminRole.ADMIN, '파트너': AdminRole.PARTNER };
    const element = EvTab(inCooperationTabDatas);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Array<Company> | null>(null);
    const [isExpands, setExpands] = useState<Array<boolean>>([]);
    const [isDetailDialog, setDetailDialog] = useState<boolean>(false);
    const [editCompany, setEditCompany] = useState<Company | null>(null);
    const [addCooperationData, setAddCooperationData] = useState<AddCooperationForm>(
        {
            company_code: '',
            name: '',
            phone: '',
            address: '',
            address_detail: '',
            zip_code: ''
        }
    );
    const [editAdminUser, setEditAdminUser] = useState<AdminUser | null>(null);
    const [adminData, setAdminData] = useState<AdminDataForm>(
        {
            login_id: '',
            password: '',
            name: '',
            phone: '',
            email: '',
            authority: AdminRole.PARTNER,
            company_code: '',
            api_key: ''
        }
    );

    const [isDetailAdminDialog, setDetailAdminDialog] = useState<boolean>(false);
    const [newPassword, setNewpassword] = useState<PasswordChangeForm>(
        {
            new: '',
            confirm: ''
        }
    );
    // const [passwordValidateText, setValidateText] = useState<string>('');
    const [isChangePasswordDialog, setPasswordDailog] = useState<boolean>(false);
    const [isDeleteConfirmDialog, setDeleteConfirmDialog] = useState<boolean>(false);
    const [selectedTile, setSelectTile] = useState<number | null>(null);
    useEffect(() => {
        if (getRole() !== 'Admin') {

            navigate(Pages[PageName.Main].key, { replace: true });
        } else {

            handleCompanies();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function handleCompanies() {
        //check init
        setSelectTile(null);

        setCompanies(null);
        const result = await getCompanies();
        if (result.result === RESULT.FAIL) {
            alert(result.msg && result.msg !== '' ? result.msg : '제휴사 목록조회 실패');
            navigate(Pages[PageName.Main].key, { replace: true });
        } else {
            const companiesResult: Array<Company> = result.data;
            setExpands([]);
            let isGetAdmin: boolean = true;

            for (var i = 0; i < companiesResult.length; i++) {
                const adminResult = await getAdmin(companiesResult[i].company_code);

                if (adminResult.result === RESULT.FAIL) {
                    //실패일 경우
                    isGetAdmin = false;
                    break;
                } else {
                    companiesResult[i].admin = adminResult.data.items;

                }
                setExpands(isExpands => [...isExpands, false]);

            }
            if (!isGetAdmin) {
                //실패 check
                alert('제휴사 목록조회 실패');
                navigate(Pages[PageName.Main].key, { replace: true });
            }



            setCompanies(companiesResult);

        }
    }

    const handleAddCompany = () => {
        if (editCompany !== null) {
            setEditCompany(null);
        }
        //init
        setAddCooperationData({
            company_code: '',
            name: '',
            phone: '',
            address: '',
            address_detail: '',
            zip_code: ''
        });

        setDetailDialog(!isDetailDialog);

    }

    const onChangeAddCooperData = (e: { target: { name: string; value: string; } }) => {

        let value = '';
        if (e.target.name === 'phone') {
            const regex = /\D/ig;
            value = e.target.value.replaceAll(regex, '');
        } else {
            value = e.target.value;
        }
        setAddCooperationData({
            ...addCooperationData,
            [e.target.name]: value
        });

    }
    const open = useDaumPostcodePopup();
    const handleComplete = (data: any) => {

        onChangeAddCooperData({ target: { name: 'zip_code', value: data.zonecode }, });

        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setAddCooperationData((addCooperationData) => {
            addCooperationData.zip_code = data.zonecode;
            addCooperationData.address = fullAddress;
            return addCooperationData;
        });

    };
    const handleDaumPost = () => {
        open({ onComplete: handleComplete });
    }
    const handleEditCompany = (company: Company) => {
        setEditCompany(company);
        setAddCooperationData({
            company_code: company.company_code,
            name: company.name,
            phone: company.phone,
            address: company.address,
            address_detail: company.address_detail ?? '',
            zip_code: company.zip_code
        });
        setDetailDialog(!isDetailDialog);
    }

    function checkIsEditCompany(): boolean {

        let editCompanyValidation = false;
        if (editCompany !== null) {
            editCompanyValidation = (addCooperationData.name === editCompany.name &&
                addCooperationData.phone === editCompany.phone &&
                addCooperationData.address === editCompany.address &&
                addCooperationData.address_detail === (editCompany.address_detail ?? '') &&
                addCooperationData.zip_code === editCompany.zip_code);
        }
        let defaultCompanyValidation = false;
        for (const [key, value] of Object.entries(addCooperationData)) {
            if (value.length === 0 && key !== 'address_detail') {
                defaultCompanyValidation = true;
                break;

            }
        }
        return (editCompanyValidation || defaultCompanyValidation);

    }


    const handleCompanySubmit = async () => {
        setDetailDialog(false);
        if (editCompany === null) {
            const storeData = {
                company_code: addCooperationData.company_code,
                name: addCooperationData.name,
                phone: addCooperationData.phone,
                address: addCooperationData.address,
                address_detail: addCooperationData.address_detail === '' ? null : addCooperationData.address_detail,
                zip_code: addCooperationData.zip_code
            }

            const result = await storeCompany(storeData);
            if (result.result === RESULT.FAIL) {
                alert(result.msg && result.msg !== '' ? result.msg : '제휴사 등록에 실패하였습니다.');
            } else {
                alert('등록 되었습니다.');
                await handleCompanies();

            }
        } else {
            setEditCompany(null);
            const sendData: any = {
                name: addCooperationData.name,
                phone: addCooperationData.phone,
                address: addCooperationData.address,
                // address_detail: addCooperationData.address_detail === '' ? null : addCooperationData.address_detail,
                zip_code: addCooperationData.zip_code
            };
            if (addCooperationData.address_detail !== '') {
                sendData.address_detail = addCooperationData.address_detail;
            }
            const result = await updateCompany(editCompany.id, sendData);
            if (result.result === RESULT.FAIL) {
                alert(result.msg && result.msg !== '' ? result.msg : '제휴사 수정에 실패하였습니다.');
            } else {
                alert('수정 되었습니다.');
                await handleCompanies();

            }
        }


    }

    const handleDeleteCompany = () => {

        if (selectedTile !== null) {
            setDeleteConfirmDialog(!isDeleteConfirmDialog);
        } else {
            alert('삭제할 제휴사를 선택하세요.');
        }
    }

    const handleDeleteCompanySubmit = async () => {
        // asdf
        if (selectedTile === null) {
            //dialog 전 확인을 진행하나 방어 코드
            alert('선택된 제휴사가 존재하지 않습니다.');
            return;
        }
        const result = await deleteCompany(selectedTile!);
        if (result.result === RESULT.FAIL) {
            alert(result.msg && result.msg !== '' ? result.msg : '제휴사 삭제에 실패하였습니다.');
        } else {
            setDeleteConfirmDialog(false);
            setSelectTile(null);

            await handleCompanies();

        }
    }
    const handleExcel = async () => {
        setLoading(true);
        const result = await getExcelCompany();
        if (result.result === RESULT.FAIL) {
            alert('제휴사 엑셀 다운로드에 실패하였습니다.');
        } else {
            const aTag = window.document.createElement('a');

            const url = window.URL.createObjectURL(new Blob([result.data]));
            aTag.href = url;


            aTag.download = `cooperation_list_${getLocaleDateString(new Date().toString())}.xlsx`;
            aTag.target = 'blank';
            document.body.appendChild(aTag);
            aTag.click();
            document.body.removeChild(aTag);
            window.URL.revokeObjectURL(url);
            alert('엑셀 다운로드 성공.');

        }

        setLoading(false);
    }

    function handleDetailAdmin(adminUser?: string | undefined) {

        //newAdmin 경우 => 신규 추가
        //undefined => close
        if (adminUser !== undefined) {
            if (JSON.parse(adminUser)['newAdmin']) {

                setAdminData(
                    {
                        login_id: '',
                        password: '',
                        name: '',
                        phone: '',
                        email: '',
                        // authority: JSON.parse(adminUser)['newAdmin'] === 'TD' ? AdminRole.ADMIN : AdminRole.PARTNER,
                        authority: AdminRole.PARTNER,
                        // company_code: ''
                        company_code: JSON.parse(adminUser)['newAdmin'],
                        api_key: ''
                    }
                );
            } else {

                const admin = AdminUser.fromJson(JSON.parse(adminUser));
                setEditAdminUser(admin);

                setAdminData(
                    {
                        login_id: admin.login_id,
                        password: '',
                        name: admin.name,
                        phone: admin.phone,
                        email: admin.email,
                        authority: admin.authority,
                        company_code: admin.company_code,
                        api_key: admin.api_key!
                    }
                );
            }
        } else {
            //close part
            if (editAdminUser !== null) {
                setEditAdminUser(null);
            }
        }

        setDetailAdminDialog(!isDetailAdminDialog);
    }

    const onChangeAdminData = (e: { target: { name: string; value: string; } }) => {
        let value = '';
        if (e.target.name === 'phone') {

            const regex = /\D/ig;
            value = e.target.value.replaceAll(regex, '');
        } else {
            value = e.target.value;
        }
        setAdminData({
            ...adminData,
            [e.target.name]: value
        });



    }

    function checkIsEditAdmin(): boolean {

        let editAdminValidation = false;
        let defaultAdminValidation = false;
        if (editAdminUser !== null) {
            editAdminValidation = (adminData.name === editAdminUser.name &&
                adminData.phone === editAdminUser.phone &&
                adminData.email === editAdminUser.email &&
                adminData.authority === editAdminUser.authority);
        }
        const compareKeys = editAdminUser !== null ? ['name', 'phone', 'email'] : ['login_id', 'password', 'name', 'phone', 'email', 'authority'];
        for (const [key, value] of Object.entries(adminData)) {
            if (compareKeys.includes(key)) {
                if (value.length === 0) {
                    defaultAdminValidation = true;
                    break;
                } else if (key === 'email' && !EmailRegExp.test(value)) {
                    defaultAdminValidation = true;
                    break;
                }
            }

        }
        return (editAdminValidation || defaultAdminValidation);
    }
    const handleAdminSubmit = async () => {
        setDetailAdminDialog(false);
        if (editAdminUser === null) {
            const storeData = {
                login_id: adminData.login_id,
                password: adminData.password,
                name: adminData.name,
                phone: adminData.phone,
                email: adminData.email,
                authority: adminData.authority,
                company_code: adminData.company_code
            }
            const result = await storeAdmin(storeData);
            if (result.result === RESULT.FAIL) {
                alert(result.msg && result.msg !== '' ? result.msg : '관리자 등록에 실패하였습니다.');
            } else {
                alert('등록 되었습니다.');
                await handleCompanies();

            }
        } else {
            setEditAdminUser(null);
            const editData = {
                name: adminData.name,
                phone: adminData.phone,
                // authority: adminData.authority,
                email: adminData.email
            }
            const result = await updateAdmin(editData, editAdminUser.id);
            if (result.result === RESULT.FAIL) {
                alert(result.msg && result.msg !== '' ? result.msg : '관리자 수정에 실패하였습니다.');
            } else {
                alert('수정 되었습니다.');
                await handleCompanies();

            }
        }
    }

    const handleDeleteAdmin = async (value: any) => {

        const result = await deleteAdmin(value);
        if (result.result === RESULT.FAIL) {
            alert(result.msg && result.msg !== '' ? result.msg : '관리자 삭제에 실패하였습니다.');
        } else {
            alert('삭제 되었습니다.');
            await handleCompanies();

        }
    }

    const changeAdminPassword = async () => {
        if (!isChangePasswordDialog) {

            setNewpassword({
                new: '',
                confirm: ''
            });
        }
        setDetailAdminDialog(false);
        //setEditAdminUser(null);
        setPasswordDailog(!isChangePasswordDialog);
    }

    const onChangePasswordData = (e: { target: { name: string; value: string } }) => {

        setNewpassword({
            ...newPassword,
            [e.target.name]: e.target.value
        });


    }

    const checkChangePasswordSubmit = () => {

        let isSubmit = false;

        if (newPassword.new.length > 0 && newPassword.confirm.length > 0) {
            if (!passwordRegExp.test(newPassword.new) ||
                !passwordRegExp.test(newPassword.confirm)
            ) {
                isSubmit = true;
            } else if (newPassword.new !== newPassword.confirm) {
                isSubmit = true;
            } else {
                isSubmit = false;
            }
        } else {
            isSubmit = true;
        }
        // validate & validate text set

        return isSubmit;
    }
    async function handleChangePassword(password: string, id: number) {

        const result = await changePassword(password, id);
        if (result.result === RESULT.FAIL) {
            alert(result.msg && result.msg !== '' ? result.msg : '관리자 비밀번호 변경에 실패하였습니다.');
        } else {
            if (getAuthData().login_id === editAdminUser?.login_id) {
                alert('변경 되었습니다.\n로그인한 관리자의 비밀번호 변경으로 로그아웃 됩니다.');
                removeAuth();
                navigate('/');
            } else {
                alert('변경 되었습니다.');
                changeAdminPassword();
                await handleCompanies();
            }


        }
    }
    const handleAPIKeyReIssue = async () => {
        const result = await reIssueAdminApiKey(editAdminUser?.company_code!, editAdminUser!.id);
        if (result.result === RESULT.FAIL) {
            alert(result.msg && result.msg !== '' ? result.msg : 'API Key 재발급에 실패하였습니다.');
        } else {
            alert('재발급 되었습니다.');
            await handleCompanies();
            setEditAdminUser({
                ...editAdminUser!,
                api_key: result.data
            });

            setAdminData({
                ...adminData,
                api_key: result.data
            })

        }
    }



    return EvLayOut(
        <ContentLayout >
            <DefaultContentsPadding>

                {element}
                {
                    companies == null || isLoading
                        ? <ElementContents>
                            <EvLoading />
                        </ElementContents>
                        : <ElementContents>
                            <ContentsEndBox >

                                <EvButton
                                    margin="0px 5px 5px 0px"
                                    padding="7px 5px 5px 5px"
                                    text={'제휴사 추가'}
                                    width={'8rem'}
                                    fontSize={TextSize.small}
                                    color={Color.primary}
                                    fontColor={Color.white}
                                    // isOutline={true}
                                    onPressed={handleAddCompany}
                                />
                                <EvButton
                                    margin="0px 5px 5px 0px"
                                    padding="7px 5px 5px 5px"
                                    text={'제휴사 삭제'}
                                    width={'8rem'}
                                    fontSize={TextSize.small}
                                    color={Color.red}
                                    fontColor={Color.white}
                                    // isOutline={true}
                                    onPressed={handleDeleteCompany}
                                />
                                <EvButton
                                    margin="0px 5px 5px 0px"
                                    padding="7px 5px 5px 5px"
                                    text={'엑셀 다운로드'}
                                    width={'8rem'}
                                    fontSize={TextSize.small}
                                    color={Color.green}
                                    fontColor={Color.white}
                                    // isOutline={true}
                                    onPressed={handleExcel}
                                />
                            </ContentsEndBox>
                            <ListContents
                                style={companies.length === 0
                                    ? {
                                        backgroundColor: Color.white,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        color: Color.textSecondary
                                    }
                                    : {}}
                            >

                                {
                                    companies.length === 0
                                        ? '제휴사가 존재하지 않습니다.'
                                        : companies.map((value, index) => {
                                            return CompanyTile(
                                                value,
                                                isExpands[index],
                                                selectedTile,
                                                (event: any) => {
                                                    event.stopPropagation();
                                                    setExpands(isExpands => {
                                                        isExpands[index] = !isExpands[index];
                                                        return [...isExpands];

                                                    })
                                                },
                                                (event: any) => {
                                                    event.stopPropagation();
                                                    if (selectedTile !== null && value.id === selectedTile) {
                                                        setSelectTile(null)
                                                    } else {
                                                        setSelectTile(value.id)
                                                    }

                                                },
                                                (event: any) => {
                                                    event.stopPropagation();
                                                    handleEditCompany(value);

                                                }, (event: any) => {
                                                    event.stopPropagation();

                                                    handleDetailAdmin(event.target.value);

                                                },
                                                (event: any) => {
                                                    event.stopPropagation();

                                                    handleDeleteAdmin(event.target.value);

                                                },

                                            );
                                        })
                                }
                            </ListContents>

                        </ElementContents>
                }

            </DefaultContentsPadding>

            {/* 제휴사 추가 */}
            {isDetailDialog &&
                <EvDialog
                    title={editCompany === null ? "제휴사 추가" : "제휴사 수정"}
                    contents={
                        <DetailDialogLayout>

                            {/* 코드 / 이름 / 전화번호 / 주소  */}
                            <div>
                                <EvLabel
                                    text="제휴사 코드"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"company_code"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAddCooperData}
                                    readonly={editCompany !== null}
                                    value={addCooperationData.company_code}
                                />
                            </div>
                            <div >
                                <EvLabel
                                    text="제휴사 이름"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={"name"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAddCooperData}
                                    value={addCooperationData.name}
                                />
                            </div>
                            <div>
                                <EvLabel

                                    text="전화번호"
                                    isNeccessary={true}
                                />
                                <EvInput
                                    name={"phone"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAddCooperData}
                                    value={addCooperationData.phone}

                                />
                            </div>
                            <div >
                                <EvLabel
                                    text="주소"
                                    isNeccessary={true}
                                />
                                <EvButton
                                    text={'주소검색'}
                                    width={'100px'}
                                    padding="7px 5px 5px 5px"
                                    color={Color.borderFocusColor}
                                    fontSize={TextSize.normal}
                                    onPressed={handleDaumPost}

                                />
                            </div>
                            <div style={{
                                height: '43px'
                            }}>

                                <EvInput
                                    name={"address"}
                                    placeHolder={"주소검색을 진행하세요."}
                                    type={Type.TEXT}
                                    onChanged={null}
                                    readonly={true}
                                    value={addCooperationData.address}

                                />
                            </div>
                            <div >
                                <div style={{ flex: 2, height: '43px', marginRight: "10px", minWidth: "180px" }}>

                                    <EvInput

                                        name={"address_detail"}
                                        placeHolder={"상세주소."}
                                        type={Type.TEXT}
                                        onChanged={onChangeAddCooperData}
                                        value={addCooperationData.address_detail}
                                    />
                                </div>
                                <div style={{ flex: 1, height: '43px', minWidth: "90px" }}>

                                    <EvInput
                                        name={"zip_code"}
                                        placeHolder={"우편변호"}
                                        type={Type.TEXT}
                                        onChanged={null}
                                        readonly={true}
                                        value={addCooperationData.zip_code}
                                    />
                                </div>
                            </div>
                            <div>
                                <EvButton
                                    text={editCompany !== null ? "수정" : "추가"}
                                    padding="7px 5px 5px 5px"
                                    color={Color.primary
                                    }
                                    // disable={(editCompany !== null && checkIsEditCompany() && validateCompany())}
                                    disable={checkIsEditCompany()}

                                    width={"100%"}
                                    onPressed={handleCompanySubmit}
                                />
                            </div>

                        </DetailDialogLayout>

                    }
                    onClose={handleAddCompany}
                    width={"30vw"}
                />
            }
            {/* 제휴사 삭제 */}
            {
                isDeleteConfirmDialog &&
                <EvDialog
                    title="제휴사 삭제"
                    contents={
                        <DeleteCooperationLayout>

                            <div style={{ minHeight: '50px', marginBottom: '5px' }}>
                                제휴사를 삭제하시겠습니까?
                            </div>
                            <div style={{ justifyContent: "space-between" }}>
                                <EvButton
                                    text={'취소'}
                                    padding="7px 5px 5px 5px"
                                    color={Color.backGroundGrey}
                                    fontColor={Color.black}
                                    width={"130px"}
                                    onPressed={() => setDeleteConfirmDialog(!isDeleteConfirmDialog)}
                                />
                                <EvButton

                                    text={'삭제'}
                                    padding="7px 5px 5px 5px"
                                    color={Color.primary}
                                    width={"130px"}
                                    onPressed={handleDeleteCompanySubmit}
                                />
                            </div>
                        </DeleteCooperationLayout>

                    }
                    hasClose={false}

                />
            }
            {/* 제휴사 관리자 detail */}
            {
                isDetailAdminDialog &&
                <EvDialog
                    title={editAdminUser === null ? "관리자 추가" : "관리자 수정"}
                    contents={
                        <DetailDialogLayout>
                            {/* name / email / phone */}
                            <div>
                                <EvLabel
                                    text="권한"
                                    isNeccessary={true}
                                />
                                {
                                    editAdminUser !== null
                                        ? <EvInput
                                            name={"authority"}
                                            placeHolder={null}
                                            type={Type.TEXT}
                                            onChanged={null}
                                            readonly={true}
                                            value={adminData.authority}
                                        />
                                        : EvSelectBox(roles, (e: any) => {
                                            if (e.target.value === AdminRole.ADMIN) {
                                                alert('관리자 권한은 모든 서비스에 영향을 줄 수 있는 권한입니다.');
                                            }
                                            setAdminData({ ...adminData, authority: e.target.value });
                                        }, 3, adminData.authority, undefined, undefined, '37')}
                            </div>
                            <div>
                                <EvLabel
                                    text="아이디"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"login_id"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAdminData}
                                    readonly={editAdminUser !== null}
                                    value={adminData.login_id}
                                />
                            </div>

                            {editAdminUser !== null
                                ? <div>
                                    <EvLabel
                                        text="비밀번호"
                                        isNeccessary={true}

                                    />
                                    <EvButton
                                        text={"비밀번호 변경"}
                                        padding="9px 5px 7px 5px"
                                        color={Color.primary
                                        }
                                        fontSize={TextSize.normal}
                                        width={"100%"}
                                        onPressed={changeAdminPassword}
                                    />
                                </div>
                                : <div>
                                    <EvLabel
                                        text="비밀번호"
                                        isNeccessary={true}

                                    />
                                    <EvInput
                                        name={"password"}
                                        placeHolder={null}
                                        type={Type.TEXT}
                                        onChanged={onChangeAdminData}
                                        readonly={editAdminUser !== null}
                                        value={adminData.password}
                                    />
                                </div>
                            }


                            <div>
                                <EvLabel
                                    text="이름"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"name"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAdminData}

                                    value={adminData.name}
                                />
                            </div>

                            <div>
                                <EvLabel
                                    text="이메일"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"email"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAdminData}

                                    value={adminData.email}
                                />
                            </div>
                            <div>
                                <EvLabel
                                    text="전화번호"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"phone"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangeAdminData}

                                    value={adminData.phone}
                                />
                            </div>
                            {editAdminUser !== null
                                ? <div style={{ flexDirection: "column" }}>
                                    <div style={{ width: "100%" }}>
                                        <EvLabel
                                            text="APIKey"
                                            isNeccessary={true}

                                        />



                                        <EvButton

                                            text={"재발급"}
                                            padding="9px 5px 7px 5px"
                                            color={Color.primary
                                            }
                                            fontSize={TextSize.normal}
                                            width={"100%"}
                                            onPressed={handleAPIKeyReIssue}
                                        />

                                    </div>
                                    <div style={{ width: "100%", height: "49px" }}>
                                        <EvInput

                                            name={"apiKey"}
                                            placeHolder={null}
                                            type={Type.TEXT}
                                            onChanged={() => { }}
                                            readonly={editAdminUser !== null}
                                            value={adminData.api_key}
                                        />
                                    </div>
                                </div>


                                : null
                            }
                            <div>
                                <EvButton
                                    text={editAdminUser !== null ? "수정" : "추가"}
                                    padding="7px 5px 5px 5px"
                                    color={Color.primary
                                    }
                                    disable={checkIsEditAdmin()}

                                    width={"100%"}
                                    onPressed={handleAdminSubmit}
                                />
                            </div>
                        </DetailDialogLayout>

                    }
                    onClose={() => handleDetailAdmin()}
                    width={"30vw"}
                />
            }

            {/* 관리자 password */}
            {
                isChangePasswordDialog &&
                <EvDialog
                    title={"비밀번호 변경"}
                    contents={
                        <DetailDialogLayout>
                            {/* name / email / phone */}
                            <div style={{ color: Color.primary, fontSize: TextSize.small }}>
                                * 비밀번호는 특수문자를 포함한 영문, 숫자 조합의 8~20자리여야 합니다.
                            </div>
                            <div>
                                <EvLabel
                                    flex={2}
                                    text="비밀번호"
                                    isNeccessary={true}

                                />
                                <EvInput
                                    name={"new"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangePasswordData}
                                    value={newPassword.new}
                                />
                            </div>
                            <div style={{ fontSize: TextSize.small }}>
                                <EvLabel
                                    flex={2}
                                    text="비밀번호 확인"
                                    isNeccessary={true}

                                />
                                <EvInput

                                    name={"confirm"}
                                    placeHolder={null}
                                    type={Type.TEXT}
                                    onChanged={onChangePasswordData}

                                    value={newPassword.confirm}
                                />
                            </div>


                            <div>
                                <EvButton
                                    text={"확인"}
                                    padding="7px 5px 5px 5px"
                                    color={Color.primary
                                    }
                                    disable={checkChangePasswordSubmit()}

                                    width={"100%"}
                                    onPressed={() => handleChangePassword(newPassword.new, editAdminUser?.id!)}
                                />
                            </div>
                        </DetailDialogLayout>

                    }
                    onClose={changeAdminPassword}
                    width={"25vw"}
                />
            }

        </ContentLayout>
    );
}

const ListContents = styled.div`
    overflow-y:auto;
    height:calc(100% - 35px);
    width:100%;
    min-width:300px;
    border-radius:5px;
`;

const DetailDialogLayout = styled.div`
    display: flex;
    flex-direction: column; 
    min-width: 275px;
    width:100%;
    div {
        padding:5px 0px;
        display: flex; 
        
        flex-direction: row;
        
        align-items:center;
        flex-wrap: nowrap;
        
        input {
            height:calc(100% - 10px);
            flex:3;
        }
        button{
            padding:0px;
            // height:calc(100% - 10px);
            flex:3;
        }
    }
`;

const DeleteCooperationLayout = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: start; 
    width: 100%;
    div {
        display: flex; 
        flex-direction: row;
        width: 100%;
        align-items:center;
    }
`;





export default CooperationsPage;