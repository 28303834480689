import React, { EventHandler } from "react";
import { Color } from "../constants";

export enum Type {
  TEXT = "text",
  PASSWORD = "password",
  NUMBER = "number",
  File = "file"
}

type InputData = {
  name: string | null;
  placeHolder: string | null;
  type: Type | null;
  onChanged: EventHandler<any> | null;
  readonly?: boolean;
  value?: string | number;
  isFull?: boolean;
  pattern?: string;
  flex?: number;
  onSubmit?: Function;
  min?: number | undefined
};

const EvInput = ({ name, placeHolder, type, onChanged, readonly = false, value, isFull = true, pattern = undefined, flex, onSubmit, min = undefined }: InputData) => {
  let style: any = {};
  if (isFull) {
    style.width = "100%";
  }
  if (flex) {
    style.flex = flex;
  }
  if (readonly) {
    style["backgroundColor"] = Color.dialogBackgroundColor;
    style["border"] = `1px solid ${Color.borderColor}`;
  }
  const evInput = (
    <React.Fragment>
      <input
        onKeyUp={
          onSubmit
            ? (event) => {
              if (event.key === "Enter") {
                onSubmit();
              }
            }
            : undefined
        }
        readOnly={readonly}
        name={name != null ? name : undefined}
        type={type != null ? type : "text"}
        placeholder={placeHolder != null ? placeHolder : undefined}
        onChange={onChanged != null ? onChanged : undefined}
        value={value}
        style={style}
        pattern={pattern}
        min={
          type == Type.NUMBER ? min : undefined
        }
      />
    </React.Fragment>
  );
  return evInput;
};
export default EvInput;
