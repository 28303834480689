
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { Pages, PageName } from "./constants";
import CooperationsPage from "./screens/cooperations";
import CouponsPage from "./screens/coupons";
import CouponsUsedPage from "./screens/coupons/used";
import MainPage from "./screens/home";
import LoginPage from "./screens/login";
import NotFound from "./screens/notFound";
import PointsPurchasePage from "./screens/points";
import PointsManagePage from "./screens/points/manage";
import PointsManageHistory from "./screens/points/manageHistory";
import PointsReserveRatePage from "./screens/points/reserveRate";
import PointsUsedPage from "./screens/points/used";
import UserCardsPage from "./screens/userCards";

import GlobalStyle from "./styles/GlobalStyle";
import UserGroupManagePage from "./screens/userGroupManage";
import MembershipManagePage from "./screens/membershipManage";
import EvModal from "./components/evModal";
import { useRecoilState } from "recoil";
import { fileInputModalState, loadingModalState, modalState } from "./recoil/atoms";
import EvAlert from "./components/evAlert";
import EvFileInputModal from "./components/evFileInputModal";
import EvLoadingModal from "./components/evLoadingModal";



function App() {
  const [modal, setModal] = useRecoilState(modalState);
  const [fileModal, setFileModal] = useRecoilState(fileInputModalState);
  const [loadingModal, setLoadingModal] = useRecoilState(loadingModalState);
  return (
    <BrowserRouter >
      {loadingModal.isOpen ? <EvLoadingModal></EvLoadingModal> : null}
      <EvAlert></EvAlert>
      {
        fileModal.isOpen ? <EvFileInputModal /> : null
      }
      {
        modal.isOpen ? <EvModal /> : null
      }
      <GlobalStyle />
      <Routes>
        <Route path={Pages[PageName.Login].key} element={<LoginPage />} />

        <Route path={Pages[PageName.Main].key} element={<MainPage />} />
        <Route path={Pages[PageName.Cooperations].key} element={
          <CooperationsPage />
        } />

        <Route path={Pages[PageName.Coupons].key} element={<CouponsPage />} />
        <Route path={Pages[PageName.CouponsUsed].key} element={<CouponsUsedPage />} />

        <Route path={Pages[PageName.Points].key} element={<Navigate to={Pages[PageName.PointsPurchase].key} />} />
        <Route path={Pages[PageName.PointsPurchase].key} element={<PointsPurchasePage />} />
        <Route path={Pages[PageName.PointsUsed].key} element={<PointsUsedPage />} />
        <Route path={Pages[PageName.PointReserve].key} element={<PointsManageHistory />} />
        <Route path={Pages[PageName.PointReserveRate].key} element={<PointsReserveRatePage />} />
        <Route path={Pages[PageName.PointManage].key} element={<PointsManagePage />} />

        <Route path={Pages[PageName.UserCards].key} element={<UserCardsPage />} />

        <Route path={Pages[PageName.MembershipManage].key} element={<MembershipManagePage />} />
        <Route path={Pages[PageName.UserGroupManage].key} element={<UserGroupManagePage />} />

        <Route path={Pages[PageName.NotFound].key} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
