import React from "react";
import styled from "styled-components";
import { Color, TextSize } from "../constants";


type BadgeData = {
    text: string,
    borderColor?: string | null,
    textColor?: string | null,
    marginTop?: boolean | null
};

const EvBadge = ({ text, borderColor = null, textColor = null, marginTop = false }: BadgeData) => {
    const style = {
        border: `1px solid ${borderColor != null ? borderColor : Color.borderColor}`,

        color: textColor != null ? textColor : Color.textFirst,
        marginTop: marginTop ? '5px' : '0px'
    };

    return (
        <React.Fragment>
            <BadgeLayout style={style}>
                {text}
            </BadgeLayout>
        </React.Fragment>
    );
}

const BadgeLayout = styled.div`
    margin-right:10px;
    padding:2px 5px 3px 5px;
    min-width:1rem;
    font-size:${TextSize.ssmall};
    border-radius:2px;
    text-align:center;
    display:flex;
    flex-wrap:nowrap;
    align-items:center;
    justify-content:center;
`;

export default EvBadge;