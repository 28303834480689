import styled from "styled-components";
import { inTabData, MobileBreakWidth } from "../constants";
import EvTabTile from "./evTabTile";



const EvTab = (inTabDatas: Array<inTabData>) => {
    const tabTiles: Array<JSX.Element> = [];
    inTabDatas.forEach((e) => {
        tabTiles.push(
            <EvTabTile key={e.tabText} tabText={e.tabText} pathText={e.pathText} />
        );
    });
    return (<RowLine >
        {tabTiles}
    </RowLine>);
}


const RowLine = styled.div`
    margin-bottom:10px;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    
    @media all and (max-width:${MobileBreakWidth}px){
        justify-content:center;
    }
`;

export default EvTab;