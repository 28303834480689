import AdminUser from "./adminUser";


enum CompanyStatus {
    ACCEPT = 'ACCEPT',
    DELETE = 'DELETE',
    REJECT = 'REJECT'
}
function getCompanyStatusText(status: CompanyStatus) {
    switch (status) {
        case CompanyStatus.ACCEPT:
            return '활성';
        case CompanyStatus.DELETE:
            return '삭제';
        case CompanyStatus.REJECT:
            return '반려';
        default:
            return '';
    }
}
class Company {
    id: number;
    name: string;
    address: string;
    phone: string;
    status: CompanyStatus;
    company_code: string;
    zip_code: string;
    address_detail: string | null;
    created_at: string;
    updated_at: string;
    admin: Array<AdminUser> | null;
    constructor(
        id: number,
        name: string,
        address: string,
        phone: string,
        status: CompanyStatus,
        company_code: string,
        zip_code: string,
        address_detail: string | null,
        created_at: string,
        updated_at: string,
        admin: Array<AdminUser> | null
    ) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.phone = phone;
        this.status = status;
        this.company_code = company_code;
        this.zip_code = zip_code;
        this.address_detail = address_detail;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.admin = admin;
    }
    static fromJson(json: Object): Company {

        var companyData: any = {};
        for (const [key, value] of Object.entries(json)) {
            companyData[key] = value;
        }
        return new Company(
            companyData['id'],
            companyData['name'],
            companyData['address'],
            companyData['phone'],
            companyData['status'],
            companyData['company_code'],
            companyData['zip_code'],
            companyData['address_detail'],
            companyData['created_at'],
            companyData['updated_at'],
            companyData['admin']
        );
    }
}
export { getCompanyStatusText, CompanyStatus };
export default Company;