import React from "react";
import styled from "styled-components";
import { Color } from "../constants";

const EvLoading = () => {
    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', textAlign: 'center', backgroundColor: Color.backGroundGrey, opacity: 0.4 }}>
                <SpinnerBox>

                    <Spinner />
                </SpinnerBox>
            </div>
        </React.Fragment>
    );
}
const SpinnerBox = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
const Spinner = styled.div`
    @keyframes spinner {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
  
    width: 30px;
    height: 30px;
    border: 10px solid ${Color.borderColor}; 
    border-top: 10px solid ${Color.primary}; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  
`;


export default EvLoading;