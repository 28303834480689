import { createGlobalStyle } from "styled-components";
import { Color, TextSize } from "../constants";

import NotoSansKrRegular from "../assets/fonts/NotoSansKR/NotoSansKR-Regular.otf";
import NotoSansKrMedium from "../assets/fonts/NotoSansKR/NotoSansKR-Medium.otf";
import NotoSansKrBold from "../assets/fonts/NotoSansKR/NotoSansKR-Bold.otf";

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    caption, tbody, tfoot, thead,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        // font-size : ${TextSize.normal};
        font: inherit;
        vertical-align: baseline;
    }
    @font-face {
        font-family : "Noto Sans KR";
        src : 
        
        url(${NotoSansKrRegular}) format('truetype'),
        url(${NotoSansKrMedium}) format('truetype'),
        url(${NotoSansKrBold}) format('truetype');
       
    }
    body {
      font-family: 'Noto Sans KR';
      color : ${Color.textFirst};
      font-size : ${TextSize.normal};
    }

    
    body {
      margin: 0;
      background: #FAFAFA;
      min-width: 375px;
    }
    html, body, #root {
      margin: 0;
      height: 100%;
    }
    input[type=checkbox] {
      -ms-transform: scale(1.4); /* IE */
      -moz-transform: scale(1.4); /* FF */
      -webkit-transform: scale(1.4); /* Safari and Chrome */
      -o-transform: scale(1.4); /* Opera */
      transform: scale(1.4);
      cursor: pointer;
      
    }

    .calendar {
      height:25px;
      text-align:left;
      width:96%;
    }
    
    
`;

export default GlobalStyle;
