import React from "react";
import styled from "styled-components";



const EvSelectBox = (selectData: Object, onSelect: React.ChangeEventHandler<HTMLSelectElement>, flex?: number, defaultValue?: any, name?: string, maginTop?: string, height?: string) => {
    let element: Array<JSX.Element> = [];
    for (const [key, value] of Object.entries(selectData)) {
        element.push(
            <option key={value} value={value}>
                {key}
            </option>
        );
    }
    return (
        <React.Fragment>
            <EvSelect style={flex ? { flex: flex, marginTop: maginTop, height: height ? `${height}px` : height } : { marginTop: maginTop, height: height ? `${height}px` : height }} name={name} value={defaultValue ?? ''} onChange={onSelect}>
                {element}
            </EvSelect>
        </React.Fragment>
    );
}

const EvSelect = styled.select`
padding: 5px;
border-radius: 5px;
width: 100px;
margin-right: 5px;

`;

export default EvSelectBox;