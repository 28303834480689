import styled from "styled-components";
import { Color } from "../constants";



const EvRadio = ({ classification, fixString, onChanged }: { classification: any, fixString?: string, onChanged: any }) => {

    return (
        <RadioContainer >
            {
                classification.map((value: any, idx: number) => {
                    return (
                        <div className="form_radio_btn" key={idx}>
                            <input id={`radio-${idx + 1}`} type="radio" name="criteria" value={value}
                                checked=
                                {fixString == undefined ? fixString : fixString == value}

                                onChange={onChanged != null ? onChanged : undefined}
                            />
                            <label htmlFor={`radio-${idx + 1}`}>{value}</label>
                        </div>
                    )
                })
            }
        </RadioContainer>
    )
}

export const RadioContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 10px;
    .form_radio_btn {
        width: calc(50% - 10px);
        height : 45px;
        border: 1px solid #EAE7E7;
        border-radius: 10px;
    }
    .form_radio_btn input[type=radio] {
        display: none;
    }
    .form_radio_btn label {
        display: block;
        border-radius: 10px;
        margin: 0 auto;
        text-align: center;
        height: -webkit-fill-available;
        line-height: 45px;
    }
        
    /* Checked */
    .form_radio_btn input[type=radio]:checked + label {
        background: ${Color.primary};
        color: #fff;
    }
        
    /* Hover */
    .form_radio_btn label:hover {
        color: #666;
    }
        
    /* Disabled */
    .form_radio_btn input[type=radio] + label {
        background: #F9FAFC;
        color: #666;
    }
`

export default EvRadio;