
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Color, TextBold } from "../constants";
import { getSvgNav } from "../utils/utils";



type MenuData = {
    menuText: string;
    svgText: string;
}

const EvNavMenuTile = ({ menuText, svgText }: MenuData) => {
    const SvgNav = getSvgNav(svgText);
    const [isHover, setHover] = useState(false);
    const location = useLocation();

    const handleEnter = () => {
        setHover(true);
    }
    const handleLeave = () => {
        setHover(false);
    }


    const isSelect: boolean = location.pathname.includes(svgText);
    // svgText === location.pathname.replaceAll('/', '');
    const hoverColor: string = isSelect ? Color.selectedMenu : Color.white;
    return (
        <React.Fragment>
            <Link to={`/${svgText}`} style={{ textDecorationLine: 'none' }} onMouseEnter={handleEnter} onMouseLeave={handleLeave} >
                <MenuTile
                    style={{
                        backgroundColor: isHover ? Color.hoverMenu : hoverColor,
                        fontWeight: isSelect ? TextBold.heavy : TextBold.normal,
                        color: isSelect ? Color.black : Color.textFirst
                    }}
                >
                    {SvgNav != null && SvgNav}
                    <MenuTextDiv>
                        {menuText}
                    </MenuTextDiv>
                </MenuTile>
            </Link>
        </React.Fragment>
    );
}

const MenuTile = styled.div`
margin:0px 5px ;
padding: 10px 0px 10px 10px;
display: flex;
flex-direction: row;
align-items: center;
color:${Color.textFirst};
border-radius:3px;

`;
const MenuTextDiv = styled.div`
    padding: 0px 10px;
    
`;

export default EvNavMenuTile;