enum CouponGroupStatus {
    CREATED = 'CREATED',
    PUBLISHED = 'PUBLISHED',
    DISABLED = 'DISABLED',
    EXPIRED = 'EXPIRED'
}
function getCouponGroupStatusText(status: CouponGroupStatus) {
    switch (status) {
        case CouponGroupStatus.CREATED:

            return '생성';
        case CouponGroupStatus.PUBLISHED:

            return '발행';
        case CouponGroupStatus.DISABLED:

            return '중지';
        case CouponGroupStatus.EXPIRED:

            return '만료';
        default:
            return '';
    }
}

class CouponGroup {
    id: number;
    company_code: string;
    code: string;
    name: string;
    description: string;
    type: number;
    cg_status: CouponGroupStatus;
    amount: number;
    max_count: number;
    current_count: number;
    free: boolean;
    price: number;
    valid_period_day: string;
    valid_from_dt: string;
    valid_until_dt: string;
    publisehd_at: string;
    disabled_at: string;
    created_at: string;
    updated_at: string;
    code_equal_yn: string;
    constructor(
        id: number,
        company_code: string,
        code: string,
        name: string,
        description: string,
        type: number,
        cg_status: CouponGroupStatus,
        amount: number,
        max_count: number,
        current_count: number,
        free: boolean,
        price: number,
        valid_period_day: string,
        valid_from_dt: string,
        valid_until_dt: string,
        publisehd_at: string,
        disabled_at: string,
        created_at: string,
        updated_at: string,
        code_equal_yn: string
    ) {
        this.id = id;
        this.company_code = company_code;
        this.code = code;
        this.name = name;
        this.description = description;
        this.type = type;
        this.cg_status = cg_status;
        this.amount = amount;
        this.max_count = max_count;
        this.current_count = current_count;
        this.free = free;
        this.price = price;
        this.valid_period_day = valid_period_day;
        this.valid_from_dt = valid_from_dt;
        this.valid_until_dt = valid_until_dt;
        this.publisehd_at = publisehd_at;
        this.disabled_at = disabled_at;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.code_equal_yn = code_equal_yn;
    }

    static fromJson(json: Object): CouponGroup {
        var couponGroupData: any = {};
        for (const [key, value] of Object.entries(json)) {
            couponGroupData[key] = value;
        }
        return new CouponGroup(
            couponGroupData['id'],
            couponGroupData['company_code'],
            couponGroupData['code'],
            couponGroupData['name'],
            couponGroupData['description'],
            couponGroupData['type'],
            couponGroupData['cg_status'],
            couponGroupData['amount'],
            couponGroupData['max_count'],
            couponGroupData['current_count'],
            couponGroupData['free'],
            couponGroupData['price'],
            couponGroupData['valid_period_day'],
            couponGroupData['valid_from_dt'],
            couponGroupData['valid_until_dt'],
            couponGroupData['publisehd_at'],
            couponGroupData['disabled_at'],
            couponGroupData['created_at'],
            couponGroupData['updated_at'],
            couponGroupData['code_equal_yn']
        );
    }
}

export default CouponGroup;
export { CouponGroupStatus, getCouponGroupStatusText }