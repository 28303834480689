

const Color = {
    primary: "#FF6900",
    secondary: "#3596FF",
    white: "#ffffff",
    black: "#000000",
    green: "#03450f",
    red: "#f61c3f",
    textFirst: "#111111",
    textSecondary: "#333333",
    hintText: "#E0E0E0",
    borderColor: "#B5B5B5",
    borderFocusColor: "#FF6900",
    buttonBlack: "#343a40",
    shadowColor: "#E0E0E0",
    backGroundGrey: "#F0F0F0",
    hamburgMenu: "#111111",
    hoverMenu: "#e6f0ff",
    selectedMenu: "#ffebcc",
    loading: "#FA6900",
    dialogBackgroundColor: "rgba(240,240,240,0.7)",
    subTile: "#fff5e6",
};
enum PageName {
    Login,
    Main,
    Cooperations,
    Coupons,
    CouponsUsed,
    Points,
    PointsPurchase,
    PointsUsed,
    PointReserve,
    PointReserveRate,
    PointManage,
    UserCards,
    MembershipManage,
    UserGroupManage,
    UserGroupDetail,
    UserGroupList,
    MatchingDetail,
    NotFound
}

type PageData = {
    key: string,


};

const Pages: Record<PageName, PageData> = {
    [PageName.Login]: {
        key: '/',
    },
    [PageName.Main]: {
        key: '/main',
    },
    [PageName.Cooperations]: {
        key: '/cooperations',
    },
    [PageName.Coupons]: {
        key: '/coupons',
    },
    [PageName.CouponsUsed]: {
        key: '/couponsUsed',
    },
    [PageName.Points]: {
        key: '/points',
    },
    [PageName.PointsPurchase]: {
        key: '/pointsPurchase',
    },
    [PageName.PointsUsed]: {
        key: '/pointsUsed',
    },
    [PageName.PointReserve]: {
        key: '/pointsReserve',
    },
    [PageName.PointReserveRate]: {
        key: '/pointsReserveRate',
    },
    [PageName.PointManage]: {
        key: '/pointsManage',
    },
    [PageName.UserCards]: {
        key: '/userCards',
    },
    [PageName.MembershipManage]: {
        key: '/membershipManage/*',
    },
    [PageName.UserGroupManage]: {
        key: '/userGroup/*',
    },
    [PageName.UserGroupList]: {
        key: '',
    },
    [PageName.UserGroupDetail]: {
        key: ':id',
    },
    [PageName.MatchingDetail]: {
        key: ':id/matching/*',
    },
    [PageName.NotFound]: {
        key: '*',
    }
}




const TextBold = {
    normal: "400",
    bold: "500",
    heavy: "700"
};

const TextSize = {
    normal: "1rem",
    input: "1.2rem",
    muneTitle: "0.8rem",
    button: "1.2rem",
    large: "1.5rem",
    sLarge: "1.2rem",
    small: "0.8rem",
    ssmall: "0.6rem",
};

const AppBarHeight = 60;
const MobileBreakWidth = 650;
const PagingHeight = 50;
const SearchContentsHeight = 40;


type MenuMap = Record<string, any>;

const NavMenu: MenuMap = {
    '관리자 메뉴': {
        '제휴사 관리': 'cooperations'
    },
    '서비스 메뉴': {
        '회원 카드 관리': 'userCards',
        '포인트 관리': 'points',
        '쿠폰 관리': 'coupons',
        '멤버십 등급 관리': 'membershipManage',
        '회원 그룹 관리': 'userGroup',
    }
};
const NonAdminNavMenu: MenuMap = {
    '서비스 메뉴': {
        '회원 카드 관리': 'userCards',
        '포인트 관리': 'points',
        '쿠폰 관리': 'coupons',
        '멤버십 등급 관리': 'membershipManage',
        '회원 그룹 관리': 'userGroup',
    }
};
const defaultNavSvgSize = 24;

export type inTabData = {
    tabText: string,
    pathText: string,
};

const inCooperationTabDatas: Array<inTabData> = [
    {
        tabText: '제휴사 관리',
        pathText: Pages[PageName.Cooperations].key
    }
];

const inUserCardsTabDatas: Array<inTabData> = [
    {
        tabText: '회원 카드 관리',
        pathText: Pages[PageName.UserCards].key
    }
];

const inCouponsTabDatas: Array<inTabData> = [
    {
        tabText: '쿠폰 발행 관리',
        pathText: Pages[PageName.Coupons].key
    },
    {
        tabText: '쿠폰 사용 이력',
        pathText: Pages[PageName.CouponsUsed].key
    },
];

const inPointTabDatas: Array<inTabData> = [
    {
        tabText: '포인트 구매내역',
        pathText: Pages[PageName.PointsPurchase].key
    },
    {
        tabText: '포인트 사용내역',
        pathText: Pages[PageName.PointsUsed].key
    },
    {
        tabText: '포인트 적립내역',
        pathText: Pages[PageName.PointReserve].key
    },
    {
        tabText: '포인트 적립률 관리',
        pathText: Pages[PageName.PointReserveRate].key
    },
    {
        tabText: '포인트 적립 관리',
        pathText: Pages[PageName.PointManage].key
    },
];

const inMembershipManage: Array<inTabData> = [
    {
        tabText: '멤버십 등급 관리',
        pathText: "/membershipManage"
    }
];


const inUserGroupManage: Array<inTabData> = [
    {
        tabText: '회원 그룹 관리',
        pathText: '/userGroup'
    }
];


const EmailRegExp = new RegExp('^([w._-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+[.])+[a-zA-Z0-9]{2,8}$');
const passwordRegExp = new RegExp('^(?=.*[*.!@#$%^&(){}:;₩<>,.?/~_+=|])[A-Za-z0-9*.!@#$%^&(){}:;₩<>,.?/~_+=|]{8,20}$');

export {
    Color, TextBold, TextSize, AppBarHeight, MobileBreakWidth, NavMenu, NonAdminNavMenu, defaultNavSvgSize,
    inPointTabDatas, inCooperationTabDatas, inUserCardsTabDatas, inCouponsTabDatas, Pages, PageName, EmailRegExp, passwordRegExp,
    PagingHeight, SearchContentsHeight, inUserGroupManage, inMembershipManage
};