class GradeInfo {
    id: string;
    gradeId: string;
    calculateMinValue: number;
    calculateMaxValue: number;
    mileageValue: number;
    nm: string;
    gradePriority: number;
    imgNm: string;

    constructor(
        id: string,
        gradeId: string,
        calculateMinValue: number,
        calculateMaxValue: number,
        mileageValue: number,
        nm: string,
        gradePriority: number,
        imgNm: string
    ) {
        this.id = id;
        this.gradeId = gradeId;
        this.calculateMinValue = calculateMinValue;
        this.calculateMaxValue = calculateMaxValue;
        this.mileageValue = mileageValue;
        this.nm = nm;
        this.gradePriority = gradePriority;
        this.imgNm = imgNm;
    }

    static fromJson(json: any): GradeInfo {
        return new GradeInfo(
            json.id,
            json.gradeId,
            json.calculateMinValue,
            json.calculateMaxValue,
            json.mileageValue,
            json.nm,
            json.gradePriority,
            json.imgNm
        );
    }
}

export default GradeInfo;
