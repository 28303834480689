import React from "react";
import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import MemberCard, { getMemberCardStatusText, MemberCardStatus } from "../models/memberCard";
import { getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";
import EvButton from "./evButton";
import { Link, useNavigate } from "react-router-dom";
import UserGroup from "../models/userGroup";
import { useRecoilState } from "recoil";
import { userGroupTileCheckState } from "../recoil/atoms";

const UserGroupTile = (index: number, userGroup: UserGroup, userGroupCheckList: any, setUserGroupCheckList: any, setCreateGroup: any, setOtherGroupUserIdList: any) => {
  // const navigate = useNavigate();

  return (
    <React.Fragment key={index}>
      <TileLayout>
        <MemberCardInfo>
          <div>
            <input type={"checkbox"} readOnly
              checked={userGroupCheckList.includes(userGroup)}
              onChange={(e) => {
                if (e.target.checked) {
                  setUserGroupCheckList((prev: any) => ([...prev, userGroup]))
                } else {
                  setUserGroupCheckList((prev: any[]) => (
                    prev.filter((element) => element != userGroup)
                  ))
                }
              }}
            />
          </div>
          <div>
            {
              userGroup.delDt == null ?
                <EvBadge
                  text={'진행'}
                  textColor={Color.secondary}
                  borderColor={Color.secondary}
                />
                :
                <EvBadge
                  text={'삭제'}
                  textColor={Color.red}
                  borderColor={Color.red}
                />
            }
          </div>
          <div style={{ flex: 1 }}>
            <div>{userGroup.name}</div>
          </div>
          <div style={{ flex: 1 }}>
            <div>{`그룹 요금 : ${userGroup.benefitValue}원`}</div>
          </div>
          <div style={{ flex: 1, flexDirection: "column", display: 'flex' }}>
            <div>{`매칭 회원 수 : ${userGroup.memberCount}명`}</div>
            <div>{`매칭 충전소 수 : ${userGroup.stationCount}개`}</div>
          </div>
          <Link to={`/userGroup/${userGroup.id}`}>
            <EvButton
              text={"상세보기"}
              padding="7px 5px 5px 5px"
              width={"5em"}
              fontSize={TextSize.small}
              color={Color.white}
              fontColor={Color.black}
              isOutline={true}
              margin={"5px 5px 0px 0px"}
              onPressed={() => {
                setOtherGroupUserIdList([])
                setCreateGroup({
                  name: "",
                  benefitValue: 0,
                  stationList: [] as any,
                  userList: [] as any,
                  detail: true,
                })
              }}
            />
          </Link>
        </MemberCardInfo>
      </TileLayout>
    </React.Fragment>
  );
};
const MemberCardInfo = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  div {
    margin-right: 10px;
    display: flex;
    text-align: start;
    align-items: center;
  }
`;

export default UserGroupTile;
