import AdminUser from "../models/adminUser";
import { API, getResponseList, httpDelete, httpPost, httpPut } from "./axios";

async function getAdmin(company_code: string) {
    const converter = (json: JSON) => AdminUser.fromJson(json);
    const result = await getResponseList(API.ADMIN, '', { 'company_code': company_code }, converter);
    return result;
}
async function storeAdmin(adminData: Object) {
    return await httpPost(API.ADMIN, '', null, adminData, null);
}
async function updateAdmin(adminData: Object, id: number) {
    return await httpPut(API.ADMIN, `/${id}`, null, adminData, null);
}

async function deleteAdmin(id: number) {
    return await httpDelete(API.ADMIN, `/${id}`, null, null);
}


async function updateAdminPassword(password: string, id: number) {
    return await httpPut(API.ADMIN, `/${id}/password`, null, { password: password }, null);
}
async function reIssueAdminApiKey(company_code: string, id: number) {
    return await httpPut(API.ADMIN, '', null, { company_code: company_code, id: id }, null);
}

async function changePassword(password: string, id: number) {
    return await httpPut(API.ADMIN, `/${id}/password`, null, { password: password }, null);
}


export { getAdmin, storeAdmin, updateAdmin, deleteAdmin, updateAdminPassword, reIssueAdminApiKey, changePassword };