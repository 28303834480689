import { Cookies } from "react-cookie";


const cookies = new Cookies();

export const setCookie = (key: string, value: string) => {
    let expires = new Date();
    // 한시간 유지로 임시 설정
    expires.setDate(Date.now() + (1000 * 60 * 60));

    const option = {
        path: '/',
        expires,
        // httpOnly: true,
        secure: true
    };



    return cookies.set(key, value, option);
}

export const getCookie = (key: string) => {
    return cookies.get(key);
}

export const removeCookie = (key: string) => {
    return cookies.remove(key);
}