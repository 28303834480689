
import styled from "styled-components";

import EvLayOut from "../../components/evLayout";
import { ContentLayout } from "../../components/styled/contentLayout";

import { Color, MobileBreakWidth, TextBold, TextSize } from "../../constants";
import { SessionKey, getSession } from "../../repository/authRepo";

function MainPage() {

    return EvLayOut(
        <ContentLayout style={{ justifyContent: 'center', alignItems: 'center' }} >
            <MainTitle>EvCoupon - Point</MainTitle>
            <MainSubTitle>Point-Coupon Management System</MainSubTitle>
        </ContentLayout>
    );
}


const MainTitle = styled.div`
padding: 0px 0px 5px 0px;
    font-size:${TextSize.large};
    font-weight: ${TextBold.heavy};
    color: ${Color.primary};
    @media all and (max-width:${MobileBreakWidth}px){
        font-size:${TextSize.sLarge};
    }
`;
const MainSubTitle = styled.div`
    
    font-weight: ${TextBold.bold};
    color: ${Color.primary};
    
    @media all and (max-width:${MobileBreakWidth}px){
        font-size:${TextSize.small};
    }
`;


export default MainPage;