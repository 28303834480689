import { PointHistory, PointType } from "../models/point";
import PointPurchase from "../models/pointPurchase";
import { API, getResponseList, httpGet, httpPost } from "./axios";

async function addPoint(sendData: any, company_code: string, membership_card_number: string) {
    const path = `/${company_code}/${membership_card_number}/purchase`;
    return await httpPost(API.POINT, path, null, sendData, null);
}
async function subPoint(sendData: any, company_code: string, membership_card_number: string) {
    const path = `/${company_code}/${membership_card_number}/approve`;
    // 로밍 여부 부분은 실제 충전 관련한 데이터로 admin으로 차감시는 default N로 보내기로 지정.
    sendData.roaming_yn = 'N';
    return await httpPost(API.POINT, path, null, sendData, null);
}

// Api는 하나이나 다른 기능으로 사용되고 있으며 parameter 고정이 되는 부분이 포함. 추후 다른 API 분리시 수정을 적게
// 진행하기 위해 분리하여 call
async function getPointHistoryAdmin(sendQuery: any) {
    sendQuery.point_type = `${PointType.ADMINPLUS},${PointType.ADMINMINUS}`;
    const converter = (json: JSON) => PointHistory.fromJson(json);
    return await getResponseList(API.POINT, '', sendQuery, converter);
}

async function getPointUsedHistory(sendQuery: any) {
    sendQuery.point_type = `${PointType.USE}`;
    const converter = (json: JSON) => PointHistory.fromJson(json);
    return await getResponseList(API.POINT, '', sendQuery, converter);
}

async function getPointPurchaseHistory(sendQuery: any) {
    sendQuery.per_page = 20;
    const converter = (json: JSON) => PointPurchase.fromJson(json);
    return await getResponseList(API.POINT, '/purchase', sendQuery, converter);
}
async function getPointPurchaseExcel(excelQuery: any) {
    return await httpGet(API.POINT, '/export_p', excelQuery, null);
}
async function getPointsExcel(excelQuery: any) {
    return await httpGet(API.POINT, '/export', excelQuery, null);
}
export { addPoint, subPoint, getPointHistoryAdmin, getPointUsedHistory, getPointPurchaseHistory, getPointsExcel, getPointPurchaseExcel };