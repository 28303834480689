import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import EvLayOut from "../../components/evLayout";
import EvTab from "../../components/evTab";
import { inMembershipManage } from "../../constants";
import MembershipListPage from "./MembershipListPage";
import MembershipDetailPage from "./MembershipDetailPage";
import MembershipCreatePage from "./MembershipCreatePage";

function MembershipManagePage() {
  const element = EvTab(inMembershipManage);
  const [loading, setLoading] = useState(false);
  return EvLayOut(
    <>
      <Routes>
        <Route path="/" element={<MembershipListPage />}></Route>
        <Route path="/detail/:id" element={<MembershipDetailPage />}></Route>
        <Route path="/create" element={<MembershipCreatePage />}></Route>
      </Routes>
    </>
  );
}

export default MembershipManagePage;
