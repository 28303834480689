class PointPurchase {
    row_number: number;
    company_code: string;
    membership_card_number: string;
    purchase_price: number;
    reward_price: number | null;
    expire_dt: string;
    created_at: string;
    canceled_at: string | null;
    constructor(
        row_number: number,
        company_code: string,
        membership_card_number: string,
        purchase_price: number,
        reward_price: number | null,
        expire_dt: string,
        created_at: string,
        canceled_at: string | null,
    ) {
        this.row_number = row_number;
        this.company_code = company_code;
        this.membership_card_number = membership_card_number;
        this.purchase_price = purchase_price;
        this.reward_price = reward_price;
        this.expire_dt = expire_dt;
        this.created_at = created_at;
        this.canceled_at = canceled_at;
    }

    static fromJson(json: JSON): PointPurchase {
        var pointPurchaseData: any = {};
        for (const [key, value] of Object.entries(json)) {
            pointPurchaseData[key] = value;
        }
        return new PointPurchase(
            pointPurchaseData['row_number'],
            pointPurchaseData['company_code'],
            pointPurchaseData['membership_card_number'],
            pointPurchaseData['purchase_price'],
            pointPurchaseData['reward_price'],
            pointPurchaseData['expire_dt'],
            pointPurchaseData['created_at'],
            pointPurchaseData['canceled_at'],

        );
    }
}

export default PointPurchase;