enum AdminRole {
    ADMIN = 'Admin',
    PARTNER = 'Partner'
}
enum AdminStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

function getAdminStatusText(status: AdminStatus) {
    switch (status) {
        case AdminStatus.ACTIVE:
            return '활성';
        case AdminStatus.INACTIVE:
            return '탈퇴';
        default:
            return '';
    }
}

class AdminUser {
    id: number;
    name: string;
    phone: string;
    email: string;
    authority: AdminRole;//role
    status: AdminStatus;
    login_id: string;
    company_code: string;
    created_at: string;
    updated_at: string;
    api_key: string | null;
    constructor(
        id: number,
        name: string,
        phone: string,
        email: string,
        authority: AdminRole,//role
        status: AdminStatus,
        login_id: string,
        company_code: string,
        created_at: string,
        updated_at: string,
        api_key: string | null,
    ) {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.authority = authority;
        this.status = status;
        this.login_id = login_id;
        this.company_code = company_code;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.api_key = api_key;
    }
    static fromJson(json: Object): AdminUser {
        var adminUserData: any = {};
        for (const [key, value] of Object.entries(json)) {
            adminUserData[key] = value;
        }
        return new AdminUser(
            adminUserData['id'],
            adminUserData['name'],
            adminUserData['phone'],
            adminUserData['email'],
            adminUserData['authority'],
            adminUserData['status'],
            adminUserData['login_id'],
            adminUserData['company_code'],
            adminUserData['created_at'],
            adminUserData['updated_at'],
            adminUserData['api_key'],
        );
    }
}

export default AdminUser;
export { getAdminStatusText, AdminStatus, AdminRole };