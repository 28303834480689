import React from "react";
import styled from "styled-components";
import { TextSize, Color, TextBold } from "../constants";
import PointReserveRate, { getPointReserveRateStatusText, PointReserveRateStatus } from "../models/pointReserveRate";
import { getLocaleDateString, getRole } from "../utils/utils";
import EvBadge from "./evBadge";
import EvButton from "./evButton";
import TileLayout from "./styled/tileLayout";

const PointReserveRateTile = (pointReserveRate: PointReserveRate, deletePointReserveRates: Function, updatePointReserveRates: Function) => {
  let badgeColor = "";
  switch (pointReserveRate.status) {
    case PointReserveRateStatus.DELETE:
      badgeColor = Color.red;
      break;
    case PointReserveRateStatus.SCHEDULE:
      badgeColor = Color.green;
      break;
    case PointReserveRateStatus.END:
      badgeColor = Color.textFirst;
      break;
    case PointReserveRateStatus.APPLY:
    default:
      badgeColor = Color.primary;
      break;
  }

  return (
    <React.Fragment key={pointReserveRate.id}>
      <TileLayout style={{ justifyContent: "space-between", alignItems: "center", minHeight: "35px" }}>
        {/* company code /  */}
        <RateContents>
          <div style={getRole() === "Admin" ? { marginRight: "5px" } : { marginRight: "20px" }}>
            <EvBadge text={getPointReserveRateStatusText(pointReserveRate.status)} borderColor={badgeColor} textColor={badgeColor} />
          </div>
          {getRole() === "Admin" ? (
            <div style={getRole() === "Admin" ? { marginRight: "20px", minWidth: "100px" } : { minWidth: "100px" }}>
              {pointReserveRate.company_code}
            </div>
          ) : null}

          <div>
            {getLocaleDateString(pointReserveRate.percent_start_dt)} ~ {getLocaleDateString(pointReserveRate.percent_end_dt)}
          </div>
        </RateContents>
        <Percent>{(pointReserveRate.percent * 100).toFixed()}%</Percent>

        <Row>
          {pointReserveRate.status !== PointReserveRateStatus.DELETE && pointReserveRate.status !== PointReserveRateStatus.END ? (
            <EvButton
              margin="0px 0px 0px 5px"
              padding="7px 5px 5px 5px"
              text={"수정"}
              width={"5rem"}
              fontSize={TextSize.small}
              color={Color.green}
              fontColor={Color.white}
              fontWeight={TextBold.bold}
              onPressed={() => updatePointReserveRates(`/${pointReserveRate.company_code}/${pointReserveRate.id}`, pointReserveRate)}
            />
          ) : (
            <div style={{ width: "5rem" }}></div>
          )}

          {pointReserveRate.status === PointReserveRateStatus.APPLY || pointReserveRate.status === PointReserveRateStatus.SCHEDULE ? (
            <EvButton
              margin="0px 0px 0px 5px"
              padding="7px 5px 5px 5px"
              text={"삭제"}
              width={"5rem"}
              fontSize={TextSize.small}
              color={Color.red}
              fontColor={Color.white}
              fontWeight={TextBold.bold}
              onPressed={() => deletePointReserveRates(`/${pointReserveRate.company_code}/${pointReserveRate.id}`)}
            />
          ) : (
            <div style={{ width: "5rem" }}></div>
          )}
        </Row>
      </TileLayout>
    </React.Fragment>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const RateContents = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Percent = styled.div`
  min-width: 100px;
  color: ${Color.secondary};
  font-weight: ${TextBold.bold};
`;

export default PointReserveRateTile;
