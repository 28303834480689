import Company from "../models/company";
import { toJsonObject } from "../utils/utils";
import { API, getList, httpDelete, httpGet, httpPost, httpPut } from "./axios";

async function getCompanies() {
    const converter = (json: JSON) => Company.fromJson(json);
    return await getList(API.COMPANY, '', null, converter);
}

async function storeCompany(companyData: Object) {
    const storeData = toJsonObject(companyData);

    return await httpPost(API.COMPANY, '', '', storeData, null);
}

async function deleteCompany(companyId: number) {
    return await httpDelete(API.COMPANY, `/${companyId}`, null, null);
}

async function updateCompany(companyId: number, company: Object) {
    return await httpPut(API.COMPANY, `/${companyId}`, null, company, null);
}



async function getExcelCompany() {
    return await httpGet(API.COMPANY, '/export', null, null);
}

export { getCompanies, storeCompany, deleteCompany, getExcelCompany, updateCompany };