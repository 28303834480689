import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvInput, { Type } from "../../components/evInput";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import MemberCardTile from "../../components/membercardTile";
import Paging from "../../components/paging";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultContentsPadding } from "../../components/styled/defaultContentsPadding";
import ElementContents from "../../components/styled/elementContents";
import { Color, inUserGroupManage, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import MemberCard from "../../models/memberCard";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { getMemberCards } from "../../repository/memberCardRepo";
import { getCompanyCode, getRole } from "../../utils/utils";
import UserGroupList from "./userGroupList";
import { UserGroupDetail } from "./userGroupDetail";
import { MatchingDetail } from "./matchingDatail";

function UserGroupManagePage() {

  return EvLayOut(
    <ContentLayout>
      <DefaultContentsPadding>

        <Routes>
          <Route path={Pages[PageName.UserGroupDetail].key} element={<UserGroupDetail />} />
          <Route path={Pages[PageName.MatchingDetail].key} element={<MatchingDetail />} />
          <Route path={Pages[PageName.UserGroupList].key} element={<UserGroupList />} />
        </Routes>
      </DefaultContentsPadding>
    </ContentLayout>
  );
}
export default UserGroupManagePage;
