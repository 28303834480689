import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const EvCalendar = (onSelect: Function, selectedDate?: Date | undefined | null, disabled?: boolean, filterDate?: ((date: Date) => boolean) | undefined, filterTime?: ((date: Date) => boolean) | undefined) => {

  return (
    <React.Fragment>
      <DatePicker
        disabled={disabled}
        className="calendar"
        selected={selectedDate}
        onChange={(date) => {
          if (date !== null) {
            onSelect(date);
          }
        }}
        showTimeSelect
        dateFormat={"yyyy / MM / dd HH:mm:SS"}

        filterDate={filterDate}

        filterTime={filterTime}

      />
    </React.Fragment>
  );
};

export default EvCalendar;
