import React from "react";

function NotFound() {
    return (
        <React.Fragment>
            <div>temp 404 Not Found.</div>
        </React.Fragment>
    );
}

export default NotFound;