
class Auth {
    login_id: string | null;
    password: string | null;
    access_token: string | null;
    refresh_token: string | null;
    constructor(
        login_id: string | null,
        password: string | null,
        access_token: string | null,
        refresh_token: string | null

    ) {
        this.login_id = login_id;
        this.password = password;
        this.access_token = access_token;
        this.refresh_token = refresh_token;
    }
    static fromJson(json: Object): Auth {

        var jsonAccesseToken: string | null = null;
        var jsonRefreshToken: string | null = null;

        for (const [key, value] of Object.entries(json)) {
            switch (key) {

                case 'access_token':
                    jsonAccesseToken = value;
                    break;
                case 'refresh_token':
                    jsonRefreshToken = value;
                    break;
                case 'accessToken':
                    jsonAccesseToken = value;
                    break;
                case 'refreshToken':
                    jsonRefreshToken = value;
                    break;
                default:
                    break;
            }
        }

        return new Auth(null, null, jsonAccesseToken, jsonRefreshToken);


    }

}

export default Auth;
