class Station {
    id: string;
    businessId: number;
    businessCode: string;
    businessCoNm: string;
    nm: string;
    adres: string;
    rdnmadr: string;
    adresDetail: string;
    stationIdntfr: string;
    stationName: string;
    roamingYn: boolean;
    regDt: Date;
    delDt: Date | null;

    constructor(
        id: string,
        businessId: number,
        businessCode: string,
        businessCoNm: string,
        nm: string,
        adres: string,
        rdnmadr: string,
        adresDetail: string,
        stationIdntfr: string,
        stationName: string,
        roamingYn: boolean,
        regDt: Date,
        delDt: Date | null
    ) {
        this.id = id;
        this.businessId = businessId;
        this.businessCode = businessCode;
        this.businessCoNm = businessCoNm;
        this.nm = nm;
        this.adres = adres;
        this.rdnmadr = rdnmadr;
        this.adresDetail = adresDetail;
        this.stationIdntfr = stationIdntfr;
        this.stationName = stationName;
        this.roamingYn = roamingYn;
        this.regDt = regDt;
        this.delDt = delDt;

        if (!this.id) {
            this.id = stationIdntfr
        }
        if (!this.stationIdntfr) {
            this.stationIdntfr = id
        }


        if (!this.nm) {
            this.nm = stationName
        }
        if (!this.stationName) {
            this.stationName = nm
        }
    }

    static fromJson(json: any): Station {
        return new Station(
            json.stationIdntfr,
            json.businessId,
            json.businessCode,
            json.businessCoNm,
            json.nm,
            json.adres,
            json.rdnmadr,
            json.adresDetail,
            json.stationIdntfr,
            json.stationName,
            json.roamingYn,
            new Date(json.regDt),
            json.delDt ? new Date(json.delDt) : null
        );
    }
}

export default Station;
