import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EvButton from "../../components/evButton";
import EvCalendar from "../../components/evCalendar";
import EvInput, { Type } from "../../components/evInput";
import EvLabel from "../../components/evLabel";
import EvLayOut from "../../components/evLayout";
import EvLoading from "../../components/evLoading";
import EvSelectBox from "../../components/evSelectBox";
import EvTab from "../../components/evTab";
import Paging from "../../components/paging";
import PointReserveRateTile from "../../components/pointReserveRateTile";
import { ContentLayout } from "../../components/styled/contentLayout";
import { DefaultPointContentsPadding } from "../../components/styled/defaultPointContentsPadding";
import EvDialog from "../../components/styled/dialogLayout";
import ElementContents from "../../components/styled/elementContents";
import { Color, inPointTabDatas, PageName, Pages, PagingHeight, SearchContentsHeight, TextBold, TextSize } from "../../constants";
import Company from "../../models/company";
import PointReserveRate, { PointReserveRateStatus } from "../../models/pointReserveRate";
import { RESULT } from "../../models/response";
import ResponseList from "../../models/responseList";
import { getQueryString } from "../../repository/axios";
import { getCompanies } from "../../repository/companyRepo";
import { deletePointReserveRates, getPointReserveRates, storePointreserveRate, updatePointReserveRates } from "../../repository/pointReserveRateRepo";
import { getCompanyCode, getLocaleDateStringWithTZ, getRole } from "../../utils/utils";

function PointsReserveRatePage() {
  const tabElement = EvTab(inPointTabDatas);
  const navigate = useNavigate();
  let isUseEffectHandle: boolean = false;
  //useEffect data
  type PointReserveRateQuery = {
    company_code?: string;
    page: number;
  };
  const [queryData, setQueryData] = useState<PointReserveRateQuery>({
    company_code: "",
    page: 1,
  });
  function getSendQuery() {
    let sendData = queryData;
    if (getRole() !== "Admin") {
      sendData.company_code = getCompanyCode()!;
    } else {
      if (sendData.company_code === "") {
        delete sendData.company_code;
      }
    }
    return sendData;
  }
  //search contents state
  type SearchContents = {
    company_code: string;
  };
  const [totalPage, setTotalPage] = useState<number | null>(null);
  const [searchData, setSearchData] = useState<SearchContents>({
    company_code: "",
  });
  //select company data
  const [companies, setCompanies] = useState<Object>({});
  const handleSelect = (event: any) => {
    if (event.target.value !== null) {
      setSearchData({
        ...searchData,
        company_code: event.target.value,
      });
    } else {
      setSearchData({
        ...searchData,
        company_code: "",
      });
    }
  };
  async function handleComapanies() {
    const result = await getCompanies();
    if (result.result === RESULT.FAIL) {
      alert("조회 실패");
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const companiesResult: Array<Company> = result.data;
      let companySelect: any = {
        "제휴사 전체": "",
      };
      for (var i = 0; i < companiesResult.length; i++) {
        companySelect[`${companiesResult[i].name}(${companiesResult[i].company_code})`] = companiesResult[i].company_code;
      }
      setCompanies(companySelect);
    }
  }
  //PointReserveRate list data
  const [pointReserveRates, setPointReserveRates] = useState<Array<PointReserveRate> | null>(null);
  async function handlePointReserveRates() {
    setPointReserveRates(null);
    const sendData = getSendQuery();
    const result = await getPointReserveRates(sendData);
    if (result.result === RESULT.FAIL) {
      alert("포인트 적립률 목록조회 실패");
      navigate(Pages[PageName.Main].key, { replace: true });
    } else {
      const resultPageData: ResponseList = result.data;
      const resultData: Array<PointReserveRate> = resultPageData.items;

      setPointReserveRates(resultData);
      setTotalPage(resultPageData.last_page);
    }
  }
  async function callHandle() {
    if (getRole() === "Admin") {
      await handleComapanies();
    }
    await handlePointReserveRates();
  }
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (window.location.search === "") {
      isUseEffectHandle = true;
      return navigate(getQueryString({ page: 1 })!, { replace: true });
    } else if (params.get("page") !== queryData.page.toString() || params.get("company_code") !== queryData.company_code) {
      isUseEffectHandle = true;
      setSearchData({
        company_code: params.get("company_code") ?? "",
      });

      return setQueryData({
        ...queryData,
        company_code: params.get("company_code") ?? "",

        page: Number.parseInt(params.get("page") ?? "1"),
      });
    }
    // eslint-disable-next-line
    isUseEffectHandle = false;
  }, [window.location.search]);
  useEffect(() => {
    if (isUseEffectHandle) return;
    callHandle();

    // eslint-disable-next-line
  }, [queryData, window.location.search]);

  // page
  const handlePage = async (e: any) => {
    const sendData = getSendQuery();
    sendData.page = Number.parseInt(e.target.value);

    navigate(getQueryString(sendData)!);
  };

  // add rate dialog
  const [isAddPointReserveRateDialog, setAddPointReserveRateDialog] = useState<boolean>(false);
  function handleAddPointReserveRateDialog() {
    //init data
    setPointReserveRateData({
      company_code: getRole() === "Admin" ? "" : getCompanyCode()!,
      status: PointReserveRateStatus.SCHEDULE,
      percent: 0,
      percent_start_dt: "",
      percent_end_dt: "",
    });
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setAddPointReserveRateDialog(!isAddPointReserveRateDialog);
  }

  // add rate data
  type AddPointReserveRateData = {
    company_code: string;
    status: PointReserveRateStatus.SCHEDULE;
    percent: number;
    percent_start_dt: string;
    percent_end_dt: string;
  };
  const [addPointReserveRateData, setPointReserveRateData] = useState<AddPointReserveRateData>({
    company_code: getRole() === "Admin" ? "" : getCompanyCode()!,
    status: PointReserveRateStatus.SCHEDULE,
    percent: 0,
    percent_start_dt: "",
    percent_end_dt: "",
  });

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const onChangeAddPointReserveRateData = (event: { target: { name: string; value: string } }) => {
    setPointReserveRateData({
      ...addPointReserveRateData,
      [event.target.name]: event.target.value,
    });
  };

  //check store
  function checkAddRate(): boolean {
    if (
      addPointReserveRateData.company_code === "" ||
      (addPointReserveRateData.percent && addPointReserveRateData.percent === 0) ||
      addPointReserveRateData.percent_start_dt === "" ||
      addPointReserveRateData.percent_end_dt === ""
    ) {
      return true;
    }
    return false;
  }
  // store
  async function storePointReserveRate() {
    //percent는 입력은 정수, api 호출시는 백분율로 전환하여 보내야 한다.
    let storeData = addPointReserveRateData;
    storeData.percent = storeData.percent / 100;
    const result = await storePointreserveRate(storeData);
    if (result.result === RESULT.FAIL) {
      alert(result.msg ?? "적립률 등록 실패");
      storeData.percent = storeData.percent * 100;
    } else {
      alert("등록 되었습니다.");
      setAddPointReserveRateDialog(false);
    }
    await callHandle();
  }

  // delete rate
  async function deleteRate(path: string) {
    const result = await deletePointReserveRates(path);
    if (result.result === RESULT.FAIL) {
      alert(result.msg && result.msg !== "" ? result.msg : "포인트 적립률 삭제 실패");
    } else {
      alert("삭제 되었습니다.");
    }
    await callHandle();
  }

  const [updatePointReserveRateData, setUpdatePointReserveRateData] = useState<any>();
  const [isUpdatePointReserveRateDialog, setUpdatePointReserveRateDialog] = useState<boolean>(false);
  const [updatePath, setUpdatePath] = useState<string>("");
  //update rate
  function updateRate(path: string, object: PointReserveRate) {
    //init data
    setUpdatePointReserveRateData({
      company_code: object.company_code,
      status: object.status,
      percent: (object.percent * 100).toFixed(),
      percent_start_dt: object.percent_start_dt,
      percent_end_dt: object.percent_end_dt,
    });
    setSelectedStartDate(new Date(object.percent_start_dt));
    setSelectedEndDate(new Date(object.percent_end_dt));
    setUpdatePointReserveRateDialog(!isUpdatePointReserveRateDialog);
    setUpdatePath(path);
  }
  function handleUpdatePointReserveRateDialog() {
    setUpdatePointReserveRateData(null);
    setUpdatePointReserveRateDialog(!isUpdatePointReserveRateDialog);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setUpdatePath("");
  }
  const onChangeUpdatePointReserveRateData = (event: { target: { name: string; value: string } }) => {
    setUpdatePointReserveRateData({
      ...updatePointReserveRateData,
      [event.target.name]: event.target.value,
    });
  };
  function checkUpdateRate(): boolean {
    if (
      updatePointReserveRateData.company_code === "" ||
      (updatePointReserveRateData.percent && updatePointReserveRateData.percent === 0) ||
      updatePointReserveRateData.percent_start_dt === "" ||
      updatePointReserveRateData.percent_end_dt === ""
    ) {
      return true;
    }
    return false;
  }

  async function updatePointReserveRate() {
    let object = {
      status: updatePointReserveRateData.status,
      percent: Number(updatePointReserveRateData.percent) / 100,
      percent_start_dt: updatePointReserveRateData.percent_start_dt,
      percent_end_dt: updatePointReserveRateData.percent_end_dt,
    };
    const result = await updatePointReserveRates(updatePath, object);
    if (result.result === RESULT.FAIL) {
      alert(result.msg && result.msg !== "" ? result.msg : "포인트 적립률 업데이트 실패");
    } else {
      alert("업데이트 되었습니다.");
    }
    setUpdatePointReserveRateDialog(!isUpdatePointReserveRateDialog);
    await callHandle();
  }

  return EvLayOut(
    <ContentLayout>
      <DefaultPointContentsPadding>
        {tabElement}
        {pointReserveRates === null ? (
          <ElementContents>
            <EvLoading />
          </ElementContents>
        ) : (
          <ElementContents>
            {/* search contens */}

            <SearchContents style={{ justifyContent: getRole() === "Admin" ? "space-between" : "end" }}>
              {getRole() === "Admin" ? (
                <div>
                  {EvSelectBox(companies, handleSelect, undefined, searchData.company_code)}
                  <EvButton
                    margin="0px 0px 0px 5px"
                    padding="7px 5px 5px 5px"
                    text={"검색"}
                    width={"5rem"}
                    fontSize={TextSize.small}
                    color={Color.black}
                    fontWeight={TextBold.bold}
                    onPressed={() =>
                      navigate(
                        getQueryString({
                          company_code: searchData.company_code,
                          page: 1,
                        })!
                      )
                    }
                  />
                </div>
              ) : null}
              <EvButton
                margin="0px 5px 5px 0px"
                padding="7px 5px 5px 5px"
                text={"적립률 추가"}
                width={"7rem"}
                fontSize={TextSize.small}
                color={Color.primary}
                fontColor={Color.white}
                // isOutline={true}
                onPressed={handleAddPointReserveRateDialog}
              />
            </SearchContents>
            <ListContents
              style={
                pointReserveRates.length === 0
                  ? {
                      backgroundColor: Color.white,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      color: Color.textSecondary,
                    }
                  : {}
              }
            >
              {pointReserveRates.length === 0
                ? "검색된 적립률이 존재하지 않습니다."
                : pointReserveRates.map((value) => {
                    return PointReserveRateTile(value, deleteRate, updateRate);
                  })}
            </ListContents>
            {/* page contents need */}
            {Paging(
              queryData!.page,
              totalPage!,

              (e: any) => {
                e.stopPropagation();
                handlePage(e);
              }
            )}
          </ElementContents>
        )}
      </DefaultPointContentsPadding>
      {isUpdatePointReserveRateDialog && (
        <EvDialog
          title="적립률 수정"
          contents={
            <DetailDialogLayout>
              {/* 코드 / 적립률 / 기간 시작/ 종료 */}
              <DetailDialogContentsDiv>
                <EvLabel text="제휴사" isNeccessary={true} />
                {getRole() === "Admin" ? (
                  EvSelectBox(
                    companies,
                    (e: any) => setUpdatePointReserveRateData({ ...updatePointReserveRateData!, company_code: e.target.value }),
                    3,
                    updatePointReserveRateData!.company_code
                  )
                ) : (
                  <EvInput
                    name={"company_code"}
                    placeHolder={null}
                    type={Type.TEXT}
                    onChanged={null}
                    readonly={true}
                    value={updatePointReserveRateData.company_code}
                  />
                )}
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="적립률(%)" isNeccessary={true} />
                <EvInput
                  readonly={true}
                  name={"percent"}
                  placeHolder={null}
                  type={Type.NUMBER}
                  onChanged={onChangeUpdatePointReserveRateData}
                  value={updatePointReserveRateData.percent}
                />
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="시작일" isNeccessary={true} />
                {EvCalendar((value: any) => {
                  if (updatePointReserveRateData.percent_end_dt !== "" && value >= new Date(updatePointReserveRateData.percent_end_dt)) {
                    alert("시작일과 종료일을 확인해주세요.");
                  }
                  if (value < new Date()) {
                    alert("시작일을 확인해주세요");
                  } else {
                    setPointReserveRateData({
                      ...updatePointReserveRateData,
                      percent_start_dt: value, //getLocaleDateStringWithTZ(value, true),
                    });
                    setSelectedStartDate(value);
                  }
                }, selectedStartDate)}
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="종료일" isNeccessary={true} />

                {EvCalendar((value: any) => {
                  if (updatePointReserveRateData.percent_start_dt !== "" && value <= new Date(updatePointReserveRateData.percent_start_dt)) {
                    alert("종료일은 시작일보다 뒤의 날짜만 선택 가능합니다.");
                  } else {
                    setUpdatePointReserveRateData({
                      ...updatePointReserveRateData,
                      percent_end_dt: value, //getLocaleDateStringWithTZ(value, false),
                    });
                    setSelectedEndDate(value);
                  }
                }, selectedEndDate)}
              </DetailDialogContentsDiv>
              <EvButton
                text={"수정"}
                padding="7px 5px 5px 5px"
                margin="15px 0px 0px 0px"
                color={Color.primary}
                disable={checkUpdateRate()}
                width={"100%"}
                onPressed={() => updatePointReserveRate()}
              />
            </DetailDialogLayout>
          }
          onClose={handleUpdatePointReserveRateDialog}
          // width={"30vw"}
        />
      )}
      {isAddPointReserveRateDialog && (
        <EvDialog
          title="적립률 추가"
          contents={
            <DetailDialogLayout>
              {/* 코드 / 적립률 / 기간 시작/ 종료 */}
              <DetailDialogContentsDiv>
                <EvLabel text="제휴사" isNeccessary={true} />
                {getRole() === "Admin" ? (
                  EvSelectBox(
                    companies,
                    (e: any) => setPointReserveRateData({ ...addPointReserveRateData, company_code: e.target.value }),
                    3,
                    addPointReserveRateData.company_code
                  )
                ) : (
                  <EvInput
                    name={"company_code"}
                    placeHolder={null}
                    type={Type.TEXT}
                    onChanged={null}
                    readonly={true}
                    value={addPointReserveRateData.company_code}
                  />
                )}
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="적립률(%)" isNeccessary={true} />
                <EvInput
                  name={"percent"}
                  placeHolder={null}
                  type={Type.NUMBER}
                  onChanged={onChangeAddPointReserveRateData}
                  value={addPointReserveRateData.percent}
                />
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="시작일" isNeccessary={true} />
                {EvCalendar((value: any) => {
                  // if (value <= new Date()) {
                  //     alert('시작일은 현재일자보다 뒤의 날짜만 선택 가능합니다.');
                  // } else
                  if (addPointReserveRateData.percent_end_dt !== "" && value >= new Date(addPointReserveRateData.percent_end_dt)) {
                    alert("시작일과 종료일을 확인해주세요.");
                  } else {
                    setPointReserveRateData({
                      ...addPointReserveRateData,
                      percent_start_dt: value, //getLocaleDateStringWithTZ(value, true),
                    });
                    setSelectedStartDate(value);
                  }
                }, selectedStartDate)}
              </DetailDialogContentsDiv>
              <DetailDialogContentsDiv>
                <EvLabel text="종료일" isNeccessary={true} />

                {EvCalendar((value: any) => {
                  // if (value <= new Date()) {
                  //     alert('현재일자보다 뒤의 날짜만 선택 가능합니다.');
                  // } else
                  if (addPointReserveRateData.percent_start_dt !== "" && value <= new Date(addPointReserveRateData.percent_start_dt)) {
                    alert("종료일은 시작일보다 뒤의 날짜만 선택 가능합니다.");
                  } else {
                    setPointReserveRateData({
                      ...addPointReserveRateData,
                      percent_end_dt: value, //getLocaleDateStringWithTZ(value, false),
                    });
                    setSelectedEndDate(value);
                  }
                }, selectedEndDate)}
              </DetailDialogContentsDiv>
              <EvButton
                text={"등록"}
                padding="7px 5px 5px 5px"
                margin="15px 0px 0px 0px"
                color={Color.primary}
                disable={checkAddRate()}
                width={"100%"}
                onPressed={() => storePointReserveRate()}
              />
            </DetailDialogLayout>
          }
          onClose={handleAddPointReserveRateDialog}
          // width={"30vw"}
        />
      )}
    </ContentLayout>
  );
}

const SearchContents = styled.div`
  width: 100%;
  height: ${SearchContentsHeight}px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0px;
  align-items: center;
`;
const ListContents = styled.div`
  overflow-y: auto;
  height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  width: 100%;
  min-width: 300px;
  border-radius: 5px;

  @media all and (max-width: 1000px) {
    height: calc(100% - ${PagingHeight + SearchContentsHeight}px);
  }

  @media all and (max-width: 620px) {
    height: calc(100% - ${PagingHeight}px);
  }
`;

const DetailDialogLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
`;
const DetailDialogContentsDiv = styled.div`
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  margin-bottom: 5px;
  select {
    height: 100%;
  }
  input {
    height: 25px;
    flex: 3;
  }

  div {
    align-items: center;
  }
`;
export default PointsReserveRatePage;
