import { atom } from 'recoil';
import Membership from '../models/memberShip';
import UserGroup from '../models/userGroup';

export const loadingModalState = atom({
  key: 'loadingModalState',
  default: {
    isOpen: false,
  },
});

export const modalState = atom({
  key: 'modalState',
  default: {
    body: "",
    action: "",
    onActionClick: () => { },
    isOpen: false,
  },
});

export type FileValidator = (file: File) => boolean;
export const fileInputModalState = atom({
  key: 'fileInputModalState',
  default: {
    fileType: "이미지",
    extension: ['.jpg', ".png"],
    maxSize: 0,
    format: "",
    fileFormatDownloadUrl: "",
    isOpen: false,
    onClickSave: (file: any) => { },
    fileVaildator: ((file: File) => true) as FileValidator,
  },
});

export const alertState = atom({
  key: 'alertState',
  default: {
    alerts: [""]
  },
});


export const memberShipTileCheckState = atom({
  key: 'memberShipTileCheckState',
  default: [] as Array<Membership>,
});

export const userGroupTileCheckState = atom({
  key: 'userGroupTileCheckState',
  default: [] as Array<UserGroup>,
});



export const memberShipCopyState = atom({
  key: 'memberShipCopyState',
  default: {} as any
});

export const createGroupState = atom({
  key: 'createGroupState',
  default: {
    name: "",
    benefitValue: undefined as undefined | number,
    stationList: [] as any,
    userList: [] as any,
    detail: true
  }
});

export const otherGroupUserIdListState = atom({
  key: 'otherGroupUserIdListState',
  default: [] as any
})