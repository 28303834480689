enum PointReserveRateStatus {
    SCHEDULE = 'SCHEDULE',
    APPLY = 'APPLY',
    DELETE = 'DELETE',
    END = 'END'
}

function getPointReserveRateStatusText(status: PointReserveRateStatus) {
    switch (status) {
        case PointReserveRateStatus.SCHEDULE:
            return '예정';
        case PointReserveRateStatus.APPLY:
            return '적용';
        case PointReserveRateStatus.END:
            return '종료';
        case PointReserveRateStatus.DELETE:
            return '삭제';
        default:
            return '';
    }
}

class PointReserveRate {
    id: number;
    company_code: string;
    percent: number;
    status: PointReserveRateStatus;
    percent_start_dt: string;
    percent_end_dt: string;
    created_at: string;
    updated_at: string;
    constructor(
        id: number,
        company_code: string,
        percent: number,
        status: PointReserveRateStatus,
        percent_start_dt: string,
        percent_end_dt: string,
        created_at: string,
        updated_at: string
    ) {
        this.id = id;
        this.company_code = company_code;
        this.percent = percent;
        this.status = status;
        this.percent_start_dt = percent_start_dt;
        this.percent_end_dt = percent_end_dt;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
    static fromJson(json: Object): PointReserveRate {
        var pointReserveRateData: any = {};
        for (const [key, value] of Object.entries(json)) {
            pointReserveRateData[key] = value;
        }
        return new PointReserveRate(
            pointReserveRateData['id'],
            pointReserveRateData['company_code'],
            pointReserveRateData['percent'],
            pointReserveRateData['status'],
            pointReserveRateData['percent_start_dt'],
            pointReserveRateData['percent_end_dt'],
            pointReserveRateData['created_at'],
            pointReserveRateData['updated_at'],
        );
    }
}
export default PointReserveRate;
export { PointReserveRateStatus, getPointReserveRateStatusText };