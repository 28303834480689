import styled from "styled-components";
import { Color } from "../constants";
import { useFileInputModal } from "../utils/utils";

const EvFileInput = ({ value, onChanged, idx, onClickSave, fileVaildator, disable = false }: { value: any, onChanged: any, idx: number, onClickSave: any, fileVaildator?: any, disable?: boolean }) => {
    const { showFileInputModal } = useFileInputModal()
    return (
        <StyledEvFileInput input={value}>
            <label htmlFor={`file_${idx}`} onClick={(e) => {
                e.preventDefault()
                if (!disable) {
                    showFileInputModal(
                        {
                            fileType: '이미지',
                            extension: ['.jpg', '.png'],
                            format: '정사각형 (최소 300x300)',
                            maxSize: 10,
                            onClickSave: onClickSave,
                            fileVaildator: fileVaildator
                        }
                    )
                }

            }}>
                <div className="btn-upload">Img</div>
            </label>
        </StyledEvFileInput >
    )
}

interface FileInputProps {
    input?: boolean;
}

const StyledEvFileInput = styled.div<FileInputProps>`
    width: 30px;
    height: 30px;
    label .btn-upload{
        font-size: 10px;
        box-sizing: border-box;
        display: block;
        background-color: ${(props) => props.input ? Color.primary : '#efefef'};
        border: 1px  solid rgb(118, 118, 118);
        width: 100%;
        height: 100%;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
    }
    input{
        display: none;
    }    
`

export default EvFileInput;