import React from "react";
import styled from "styled-components";
import { TextSize, TextBold, Color } from "../constants";
import { PointHistory } from "../models/point";
import { getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";

const PointUsedTile = (pointUsed: PointHistory) => {
  return (
    <React.Fragment key={pointUsed.id}>
      <TileLayout>
        <PointUsedLayout>
          <EvBadge
            text="로밍"
            textColor={pointUsed.roaming_yn === "Y" ? Color.primary : Color.textSecondary}
            borderColor={pointUsed.roaming_yn === "Y" ? Color.primary : Color.textSecondary}
          />
          <div>{pointUsed.company_code}</div>

          {pointUsed.membership_card_number}
        </PointUsedLayout>
        <PointUsedLayout style={{ justifyContent: "space-between" }}>
          <div>
            <div style={{ fontSize: TextSize.sLarge, fontWeight: TextBold.bold, color: Color.primary }}>P</div>
            <div>{getPriceString(pointUsed.point)}</div>
          </div>
          <div>{getLocaleDateString(pointUsed.created_at)}</div>
        </PointUsedLayout>
      </TileLayout>
    </React.Fragment>
  );
};
const PointUsedLayout = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 35px;
  div {
    margin-right: 10px;
    display: flex;
    text-align: start;
    align-items: center;
  }
`;
export default PointUsedTile;
