import React from "react";
import styled from "styled-components";
import TileLayout from "../../components/styled/tileLayout";
import EvBadge from "../../components/evBadge";
import EvButton from "../../components/evButton";
import { Color, TextBold, TextSize } from "../../constants";
import { useNavigate } from "react-router-dom";
import Membership from "../../models/memberShip";
import { getLocaleDateString } from "../../utils/utils";
import { useRecoilState } from "recoil";
import { memberShipCopyState, memberShipTileCheckState } from "../../recoil/atoms";

const MembershipTile = ({ memberShip }: { memberShip: Membership }) => {
    const navigate = useNavigate();
    const [copyData, setCopyData] = useRecoilState(memberShipCopyState)
    const state = memberShip.applyTy == "DELETE" ? "삭제" : memberShip.applyTy == "APPLY" ? "진행" : "예정"
    const color = state == '삭제' ? Color.red : state == '진행' ? Color.secondary : Color.borderColor
    const [memberShipTileCheck, setMemberShipTileCheck] = useRecoilState(memberShipTileCheckState);
    return (
        <React.Fragment>
            <TileLayout>
                <MembershipGroupLayout>
                    <div>
                        <input type={"checkbox"}
                            checked={memberShipTileCheck.includes(memberShip)}
                            onChange={(e) => {

                                if (e.target.checked) {
                                    setMemberShipTileCheck(prev => ([...prev, memberShip]))
                                } else {
                                    setMemberShipTileCheck(prev => (
                                        prev.filter((element) => element != memberShip)
                                    ))
                                }
                            }}
                        />
                    </div>
                    <div>
                        <EvBadge text={state} textColor={color} borderColor={color} />
                    </div>
                    <div>{memberShip.nm}</div>
                </MembershipGroupLayout>
                <MembershipGroupLayout style={{ flex: 0.2, justifyContent: "space-around" }}>
                    <div>
                        <DateStyle>생성 일자 : {`${getLocaleDateString(memberShip.regDt ?? "")}`}</DateStyle>
                        <DateStyle>시작 일자 : {`${getLocaleDateString(memberShip.applyAt ?? "")}`}</DateStyle>
                        {
                            memberShip.delDt !== null &&
                            <DateStyle>삭제 일자 : {`${getLocaleDateString(memberShip.delDt ?? "")}`}</DateStyle>
                        }
                    </div>
                </MembershipGroupLayout>
                <EvButton
                    text={"상세보기"}
                    padding="7px 5px 5px 5px"
                    width={"5em"}
                    fontSize={TextSize.small}
                    color={Color.white}
                    fontColor={Color.black}
                    isOutline={true}
                    onPressed={() => {
                        setCopyData({})
                        navigate(`/membershipManage/detail/${memberShip.id}`);
                    }}
                />
            </TileLayout>
        </React.Fragment>
    );
};
const MembershipGroupLayout = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
  }
`;

const DateStyle = styled.div`
  color: ${Color.textSecondary};
  font-size: ${TextSize.small};
`;
export default MembershipTile;
