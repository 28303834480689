import React from "react";
import styled from "styled-components";
import { TextSize, TextBold, Color } from "../constants";
import { getPointTypeText, PointHistory, PointType } from "../models/point";
import { getLocaleDateString, getPriceString } from "../utils/utils";
import EvBadge from "./evBadge";
import TileLayout from "./styled/tileLayout";

const PointAdminTile = (point: PointHistory, checkList: Array<number>, onCheck: React.MouseEventHandler<HTMLDivElement>) => {
  return (
    <React.Fragment key={point.id}>
      <TileLayout>
        {/* company code / member number / 구분 / 포인트 / 적용일시 */}
        <PointAdminLayout>
          <div>
            <input type={"checkbox"} readOnly onClick={onCheck} checked={checkList.includes(point.id)} />
          </div>
          <EvBadge
            text={getPointTypeText(point.point_type)}
            textColor={point.point_type === PointType.ADMINPLUS ? Color.secondary : Color.red}
            borderColor={point.point_type === PointType.ADMINPLUS ? Color.secondary : Color.red}
          />
          <div>{point.company_code}</div>

          {point.membership_card_number.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}
        </PointAdminLayout>
        <PointAdminLayout style={{ justifyContent: "space-between" }}>
          <div>
            <div style={{ fontSize: TextSize.sLarge, fontWeight: TextBold.bold, color: Color.primary }}>P</div>
            <div>{getPriceString(point.point)}</div>
          </div>
          <div>{getLocaleDateString(point.created_at)}</div>
        </PointAdminLayout>
      </TileLayout>
    </React.Fragment>
  );
};
const PointAdminLayout = styled.div`
  font-size: ${TextSize.normal};
  font-weight: ${TextBold.bold};
  color: ${Color.textFirst};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 35px;
  div {
    margin-right: 10px;
    display: flex;
    text-align: start;
    align-items: center;
  }
`;
export default PointAdminTile;
