

import styled from "styled-components";
import { Color, TextBold, TextSize } from "../constants";
import EvButton from "./evButton";
import { useRecoilState } from "recoil";
import { modalState } from "../recoil/atoms";

function EvModal({ }) {
  const [modal, setModal] = useRecoilState(modalState);
  return (
    <ModalBackgroundLayout>
      <ModalLayout>
        <Modal>
          <div style={{ textAlign: 'center', margin: '40px 0', whiteSpace: 'pre-wrap' }} >{modal.body ?? ""}</div>
          <div>
            <EvButton
              text={"취소"}
              padding="10px 5px"
              width={'10rem'}
              fontSize={TextSize.small}
              color={Color.white}
              fontColor={Color.black}
              isOutline={true}
              onPressed={() => {
                setModal({
                  body: "",
                  action: "",
                  onActionClick: () => { },
                  isOpen: false,
                });
              }}
              margin="0 12px 0 0"
            />
            <EvButton
              text={modal.action ?? "확인"}
              padding="10px 5px"
              width={'10rem'}
              fontSize={TextSize.small}
              color={Color.primary}
              fontColor={Color.white}
              isOutline={true}
              onPressed={async () => {
                await modal.onActionClick()
                setModal({
                  body: "",
                  action: "",
                  onActionClick: () => { },
                  isOpen: false,
                });
              }}
            />
          </div>
        </Modal>
      </ModalLayout>
    </ModalBackgroundLayout>
  );
}
const ModalBackgroundLayout = styled.div`
  width:100vw;
  height:100vh;
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  z-index: 9999;
`;
const ModalLayout = styled.div`
  display:flex;
  height:100%;
  justify-content:center;
  align-items:center;
`

const Modal = styled.div`
  justify-content:center;
  align-items:center;
  background-color: white;
  padding: 0 16px 16px 16px;
  border-radius: 12px;

`

export default EvModal;

