
import Auth from "../models/auth";
import Response from "../models/response";
import { httpPost, API } from "./axios";
import { setCookie, getCookie, removeCookie } from "../utils/cookie"
import { enc, toIntToDec } from "../utils/utils";


async function login(lgnId: string, lgnPassword: string): Promise<Response> {
    var data = {
        login_id: lgnId,
        password: lgnPassword
    };
    const converter = (json: JSON) => Auth.fromJson(json);
    return await httpPost(API.AUTH, '/login', null, data, converter);
}

async function setAccessTokens(): Promise<Response> {
    const converter = (json: JSON) => Auth.fromJson(json);
    var res = await httpPost(API.CSMS_ACCESS_TOKEN, '', null, {"apiKey":process.env.REACT_APP_CSMS_API_KEY}, converter);
    setSession(SessionKey.CSMSAccess, enc(res.data.access_token!));
    setRefresh(CookieKey.CSMSRefresh, enc(res.data.refresh_token!));
    return res;
}

// async function refresh(): Promise<Response> {
//     const converter = (json: JSON) => Auth.fromJson(json);
//     return await httpGet(API.AUTH, '/refresh-token', null, converter);
// }

export enum SessionKey {
    Access = 'Access',
    //'_en_ac',
    EnInfoId = 'EnInfoId',
    // '_en_inf_i',
    EnInfoPw = 'EnInfoPw',
    // // '_en_inf_p',
    CSMSAccess = 'CSMSAccess',
    // '_en_csms_ac'
};
function getSessionKeyText(key: string) {
    switch (key) {
        case 'Access':
            return '_en_ac';
        case 'EnInfoId':
            return '_en_inf_i';
        case 'EnInfoPw':
            return '_en_inf_p';
        case 'CSMSAccess':
            return '_en_csms_ac';
        default:
            return '';
    }
}
function getSessionEnum(key: string): SessionKey | null {
    switch (key) {
        case 'Access':
            return SessionKey.Access;
        case 'EnInfoId':
            return SessionKey.EnInfoId;
        case 'EnInfoPw':
            return SessionKey.EnInfoPw;
        case 'CSMSAccess':
            return SessionKey.CSMSAccess;
        default:
            return null;
    }
}
export enum CookieKey {
    Refresh = 'Refresh',
    //'_en_ref'
    CSMSRefresh = 'CSMSRefresh',
    //'_csms_ref'
};
function getCookieKeyText(key: string) {
    switch (key) {
        case 'Refresh':
            return '_en_ref';
        case 'CSMSRefresh':
            return '_en_csms_ref';
        default:
            return '';
    }
}
function getCookieEnum(key: string): CookieKey | null {
    switch (key) {
        case 'Refresh':
            return CookieKey.Refresh;
        case 'CSMSRefresh':
            return CookieKey.CSMSRefresh;
        default:
            return null;
    }
}

async function setAuthData(authData: Auth) {
    // access to session
    // login data to session with enc to named checkData
    setSession(SessionKey.Access, enc(authData.access_token!));
    setSession(SessionKey.EnInfoId, enc(authData.login_id!));
    setSession(SessionKey.EnInfoPw, enc(authData.password!));
    // refresh to cookie
    setRefresh(CookieKey.Refresh, enc(authData.refresh_token!));
}
function getAuthData(): Auth {
    return new Auth(getSession(SessionKey.EnInfoId), getSession(SessionKey.EnInfoPw), getSession(SessionKey.Access), getRefresh(CookieKey.Refresh));
}

function setSession(key: SessionKey, data: number[]) {
    const inputData = data.join(',');
    window.sessionStorage.setItem(getSessionKeyText(key), inputData);
}
function getSession(key: SessionKey): string | null {
    const sessionData: string | null = window.sessionStorage.getItem(getSessionKeyText(key));
    if (sessionData == null) {
        return null;
    } else {
        return toIntToDec(sessionData);
    }
}

function setRefresh(key: CookieKey, data: number[]) {

    const inputData: string = data.join(',');


    setCookie(getCookieKeyText(key), inputData);


}
function getRefresh(key: CookieKey): string | null {

    const refCookie: string | null = getCookie(getCookieKeyText(key));
    if (refCookie == null) {
        return null;
    } else {
        return toIntToDec(refCookie);

    }
}
// logout시 auth 정보 삭제
function removeAuth() {
    for (var elementSession in Object.keys(SessionKey)) {
        const key: string = Object.keys(SessionKey)[elementSession];

        if (getSession(getSessionEnum(key)!) != null) {
            const removeKey = getSessionKeyText(getSessionEnum(key)!);
            window.sessionStorage.removeItem(removeKey);
        }
    }
    for (var elementCookie in Object.keys(CookieKey)) {
        const key: string = Object.keys(CookieKey)[elementCookie];

        if (getRefresh(getCookieEnum(key)!) != null) {
            const removeKey = getCookieKeyText(getCookieEnum(key)!);
            removeCookie(removeKey);
        }
    }
}
// login 상태 여부 확인
function hasAuth(): boolean {
    for (var sessionElement in Object.keys(SessionKey)) {
        const key: string = Object.keys(SessionKey)[sessionElement];
        if (getSession(getSessionEnum(key)!) == null) {
            removeAuth();
            return false;
        }
    }
    for (var cookieElement in Object.keys(CookieKey)) {
        const key: string = Object.keys(CookieKey)[cookieElement];
        if (getRefresh(getCookieEnum(key)!) == null) {
            removeAuth();
            return false;
        }
    }
    return true;
}

export { login, hasAuth, setAuthData, removeAuth, getSession, getAuthData, getRefresh,setAccessTokens};


